/**
 *
 * Slider
 *
 */

import React from "react";
import SlickSlider from "react-slick";
import "./styles.scss";

export class Slider extends React.Component<any, any> {
  render() {
    const { cards } = this.props;

    const settings = this.props.settings || {
      dots: false,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: cards?.length > 4,
      speed: 500,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: cards?.length > 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: cards?.length > 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: cards?.length > 1,
          },
        },
      ],
      className: "ProductsMainSlider",
      prevArrow: (
        <button type="button" className="slick-arrow">
          <em className="moon-baixa" style={{ transform: "rotate(90deg)" }} />
        </button>
      ),
      nextArrow: (
        <button type="button" className="slick-arrow">
          <em className="moon-baixa" style={{ transform: "rotate(-90deg)" }} />
        </button>
      ),
    };

    return <SlickSlider {...settings}>{cards}</SlickSlider>;
  }
}

export default Slider;
