// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Pagination{display:flex;flex-direction:row;justify-content:flex-end;margin:10px 20px 30px 20px}.Pagination .PageNumber{margin:2px;padding:8px 15px;border:1px solid #7d878c;border-radius:10px;cursor:pointer}.Pagination .PageNumber.__active{background-color:#7d878c}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
