/*
 *
 * Notice
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Icon } from "components";
import { DateTime } from "luxon";
import { toast, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";

export class Notice extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      news: {},
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle(Strings.news.title));

    this.getData();
  }

  getData = async () => {
    const { match, dispatch } = this.props;

    dispatch(setLoader(true));

    const response = await API.get({
      url: Endpoints.uriNews(match.params.id),
    });

    if (response.ok) {
      this.setState({
        news: response.data.results,
      });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  render() {
    const { news } = this.state;
    const { dispatch } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.news.title}</title>
          <meta name="description" content="Description of Notice" />
        </Helmet>
        <div className="PageHeader">
          <div
            className="PageHeaderTitle"
            style={{ fontSize: 19, cursor: "pointer" }}
            onClick={() => dispatch(push("/news"))}
          >
            <Icon name="back" style={{ fontWeight: "normal" }} />
            <div>{translate(news?.name)}</div>
          </div>
        </div>
        <div className="NoticeContent MobileContainer">
          {/* <div className="NoticeTitle">{translate(news?.name)}</div> */}
          <div className="ModifiedDate">
            {news?.publishScheduleDate
              ? DateTime.fromISO(news?.publishScheduleDate).toFormat("dd/MM/yyyy")
              : DateTime.fromISO(news?._created).toFormat("dd/MM/yyyy")}
          </div>
          <div className="NoticeBody">
            <td
              dangerouslySetInnerHTML={{
                __html: translate(news?.description),
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(Notice);
