/*
 *
 * Login
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { Row, Col } from "antd";
import { push } from "connected-react-router";
import { toast } from "utils/utils";
import { setUser, setToken } from "store/actions";
import { API, Endpoints } from "utils/api";
import "./styles.scss";
import { RotateSpinner } from "react-spinners-kit";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";

const google_img = require("assets/images/logos/google_logo.png");
const facebook_img = require("assets/images/logos/facebook_logo.png");
const apple_img = require("assets/images/logos/apple_logo.png");

export class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: "",
      password: "",

      signingIn: false,
      isMobile: window.innerWidth <= 768,
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    this.handleResize();

    if (user) {
      dispatch(push("/"));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  async login() {
    const { dispatch } = this.props;
    const { email, password } = this.state;
    const body = {
      email,
      password,
    };

    this.setState({ signingIn: true });

    const response = await API.post({
      url: Endpoints.uriLogin(),
      data: body,
    });

    if (response.ok) {
      const { user, token } = response.data.results;
      dispatch(setUser({ ...user }));
      dispatch(setToken(token));
      dispatch(push("/"));
    } else {
      const { code } = response.data;
      if (code === "USER_NOT_CONFIRMED") {
        toast.error(Strings.serverErrors.accountNotConfirmed);
      } else if (code === "INVALID_CREDENTIALS") {
        toast.error(Strings.serverErrors.invalidCredentials);
      }

      this.setState({ signingIn: false });
    }
  }

  async loginFacebook(response: any) {
    const { dispatch } = this.props;
    this.setState({ loading: false });

    if (response.error) return;

    try {
      const data = {
        email: response.email,
        name: response.name,
        facebookToken: response.accessToken,
        photo: response.picture.data.url,
        device: "web",
      };
      const result = await API.post({ url: Endpoints.uriLogin(), data });
      if (result.ok) {
        const { token, user } = result.data.results;
        dispatch(setUser({ ...user }));
        dispatch(setToken(token));
        dispatch(push("/"));
      } else {
        toast.error(result.data.message);
        dispatch(push("/"));
      }
    } catch (error) {
      // console.log(error)
      toast.error(String(error));
      dispatch(push("/"));
    }
  }

  loginGoogle = async (response: any) => {
    const { dispatch } = this.props;
    this.setState({ loading: false });

    if (response.error) return;

    try {
      const data = {
        email: response.profileObj.email,
        name: response.profileObj.name,
        googleToken: response.tokenId,
        photo: response.profileObj.imageUrl,
        device: "web",
      };
      const result = await API.post({ url: Endpoints.uriLogin(), data });
      if (result.ok) {
        const { token, user } = result.data.results;
        dispatch(setUser({ ...user }));
        dispatch(setToken(token));
        dispatch(push("/"));
      } else {
        toast.error(result.data.message);
        dispatch(push("/"));
      }
    } catch (error) {
      toast.error(String(error));
      dispatch(push("/"));
    }
  };

  loginApple = async (response: any) => {
    const { dispatch } = this.props;
    console.log('response', response);
    if (response.error) return;
    try {
      const data = {
        email: response.user?.email,
        name: response.user ? `${response.user?.name.firstName} ${response.user?.name.lastName}` : undefined,
        appleToken: response.authorization.code,
        identityTokenApple: response.authorization.id_token,
        device: "web",
      };
      const result = await API.post({ url: Endpoints.uriLogin(), data });
      if (result.ok) {
        const { token, user } = result.data.results;
        dispatch(setUser({ ...user }));
        dispatch(setToken(token));
        dispatch(push("/"));
      } else {
        toast.error(result.data.message);
        dispatch(push("/"));
      }
    } catch (error) {
      // console.log(error)
      toast.error(String(error));
      dispatch(push("/"));
    }
  }

  renderSocialSection() {
    const googleClientId =
      "761191622777-5av257sbiuop8oe5safoat3phnfss59b.apps.googleusercontent.com";
    const facebookAppId = "2901476396807482";
    const appleClientId = "com.adp.adpexceedfertilizers";

    return (
      <div className="SocialLoginsContainer">
        <AppleLogin
          clientId={appleClientId}
          redirectURI={window.location.origin}
          responseType={"code"}
          responseMode={"query"}
          usePopup
          scope={"name email"}
          designProp={{
            height: 30,
            width: 140,
            color: "black",
            border: false,
            type: "sign-in",
            border_radius: 15,
            scale: 1,
          }}
          callback={this.loginApple}
          render={(renderProps: any) => (
            <img
              alt="apple login"
              className="SocialLogin apple"
              src={apple_img}
              onClick={renderProps.onClick}
            />
          )}
        />
        <FacebookLogin
          appId={facebookAppId}
          fields="name,email, picture"
          cssClass="SocialIconContainer"
          callback={(response: any) => this.loginFacebook(response)}
          icon={
            <img
              alt="facebook login"
              className="SocialLogin facebook"
              src={facebook_img}
            />
          }
        ></FacebookLogin>
        <GoogleLogin
          className="SocialIconContainer"
          tag="div"
          redirectUri={window.location.origin}
          clientId={googleClientId}
          buttonText={"Sign in with google"}
          onSuccess={this.loginGoogle}
          onFailure={this.loginGoogle}
        >
          <img alt="google login" className="SocialLogin" src={google_img} />
        </GoogleLogin>
      </div>
    );
  }

  render() {
    const { signingIn, email, password } = this.state;
    const { dispatch } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.authentication.login}</title>
          <meta name="description" content="Description of Login" />
        </Helmet>
        <div className="LoginContent MobileContainer">
          <div className="LoginContentWrapper">
            <Row>
              <Col
                xs={24}
                sm={{ span: 20, offset: 2 }}
                lg={{ span: 18, offset: 3 }}
              >
                {/* <h2 style={{ fontWeight: "bold" }}>
									{Strings.authentication.signIn}
								</h2> */}
                <div className="LoginBox">
                  <Row>
                    <Col xs={24} lg={12}>
                      <div className="LoginWrapper">
                        {/* <h2>{Strings.authentication.welcome}</h2> */}
                        <form
                          onSubmit={(e: any) => {
                            e.preventDefault();

                            if (!signingIn) {
                              this.login();
                            }
                          }}
                        >
                          <h2 style={{ fontWeight: "normal" }}>
                            {Strings.authentication.login}
                          </h2>
                          <input
                            placeholder={Strings.authentication.email}
                            className="LoginInputClass"
                            value={email}
                            required
                            onChange={(e: any) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                          <input
                            placeholder={Strings.authentication.password}
                            className="LoginInputClass"
                            value={password}
                            required
                            type="password"
                            onChange={(e: any) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                          <div className="LoginOptions">
                            {/* <a
															href="/register"
															onClick={(e: any) => {
																e.preventDefault();
																dispatch(push("/register"));
															}}
															className="LoginOptionLink"
														>
															<small>
																{Strings.authentication.loginFooter}
															</small>
														</a> */}
                            <a
                              href="/login"
                              onClick={(e: any) => {
                                e.preventDefault();
                                dispatch(push("/recover-password"));
                              }}
                              className="LoginOptionLink"
                            >
                              <small>
                                {Strings.authentication.forgotPassword}
                              </small>
                            </a>
                          </div>
                          <button type="submit" className="LoginButtonClass">
                            {signingIn ? (
                              <div className="LoadingSpinner">
                                <RotateSpinner size={24} color="#FFFFFF" />
                              </div>
                            ) : (
                              Strings.authentication.login
                            )}
                          </button>
                          <div className="NoAccountOption">
                            <a
                              href="/register"
                              onClick={(e: any) => {
                                e.preventDefault();
                                dispatch(push("/register"));
                              }}
                              className="Option"
                            >
                              <small>
                                {Strings.authentication.loginFooter}
                              </small>
                            </a>
                          </div>
                          {this.renderSocialSection()}
                        </form>
                      </div>
                    </Col>
                    <Col xs={0} lg={12}>
                      <div className="LoginWelcome">
                        {/* <div className="LoginAuthenticationWrapper">
													<Result status="403" />
												</div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Login);
