import React from "react";
import { connect } from "react-redux";
import { Icon } from "components";
import "./styles.scss";
import { DateTime } from "luxon";
import { Row, Col } from "antd";
import Strings from "utils/strings";
import Slider from "react-slick";

//Weather Icons
import brokenClouds from "../../assets/images/weather/brokenClouds.png";
import clearSkyDay from "../../assets/images/weather/clearSkyDay.png";
import clearSkyNight from "../../assets/images/weather/clearSkyNight.png";
import fewCloudsDay from "../../assets/images/weather/fewCloudsDay.png";
import fewCloudsNight from "../../assets/images/weather/fewCloudsNight.png";
import mist from "../../assets/images/weather/mist.png";
import rainDay from "../../assets/images/weather/rainDay.png";
import rainNight from "../../assets/images/weather/rainNight.png";
import scatteredClouds from "../../assets/images/weather/scatteredClouds.png";
import showerRain from "../../assets/images/weather/showerRain.png";
import snow from "../../assets/images/weather/snow.png";
import thunderstorm from "../../assets/images/weather/thunderstorm.png";

export class Weather extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      displayDays: [0, 1, 2, 3, 4, 5, 6, 7],
      isMobile: window.innerWidth <= 600,
      isIPhone5: window.innerWidth <= 320,
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const displayDays = [];
    let lim = 8;

    if (window.innerWidth < 700) lim = 0;
    else if (window.innerWidth < 800) lim = 1;
    else if (window.innerWidth < 950) lim = 2;
    else if (window.innerWidth < 1150) lim = 3;
    else if (window.innerWidth < 1300) lim = 4;
    else if (window.innerWidth < 1500) lim = 6;
    else if (window.innerWidth < 1600) lim = 7;

    for (var i = 0; i < lim; i++) {
      displayDays.push(i);
    }

    let mainCardSizeAux =
      window.innerWidth < 1524 ? 470 : (window.innerWidth - 100) * 0.33;
    const cardsDivSizeAux = window.innerWidth - mainCardSizeAux - 100;

    const mainCardSize = !lim ? "100%" : `${mainCardSizeAux}px`;
    const cardsDivSize = !lim ? "0%" : `${cardsDivSizeAux}px`;

    this.setState({
      displayDays,
      mainCardSize,
      cardsDivSize,
      isMobile: window.innerWidth <= 600,
      isIPhone5: window.innerWidth <= 320,
    });
  };

  handleResizeByHour() {
    let lim = 10;

    if (window.innerWidth < 350) lim = 2;
    else if (window.innerWidth < 500) lim = 3;
    else if (window.innerWidth < 700) lim = 4;
    else if (window.innerWidth < 800) lim = 5;
    else if (window.innerWidth < 950) lim = 6;
    else if (window.innerWidth < 1150) lim = 7;
    else if (window.innerWidth < 1300) lim = 8;
    else if (window.innerWidth < 1500) lim = 9;
    else if (window.innerWidth < 1600) lim = 10;

    return lim;
  }

  getWindDirection() {
    const { data } = this.props;
    let windDirection;
    let windDeg = data?.current?.wind_deg;

    if (windDeg < 22.5 || windDeg >= 337.5) {
      windDirection = "N";
    } else if (windDeg < 67.5) {
      windDirection = "NE";
    } else if (windDeg < 112.5) {
      windDirection = "E";
    } else if (windDeg < 157.5) {
      windDirection = "SE";
    } else if (windDeg < 202.5) {
      windDirection = "S";
    } else if (windDeg < 247.5) {
      windDirection = "SW";
    } else if (windDeg < 292.5) {
      windDirection = "W";
    } else {
      windDirection = "NW";
    }
    return windDirection;
  }

  weatherIconSwitch(weatherIcon: string) {
    switch (weatherIcon) {
      case "01d":
        return clearSkyDay;
      case "01n":
        return clearSkyNight;
      case "02d":
        return fewCloudsDay;
      case "02n":
        return fewCloudsNight;
      case "03d":
      case "03n":
        return scatteredClouds;
      case "04d":
      case "04n":
        return brokenClouds;
      case "09d":
      case "09n":
        return showerRain;
      case "10d":
        return rainDay;
      case "10n":
        return rainNight;
      case "11d":
      case "11n":
        return thunderstorm;
      case "13d":
      case "13n":
        return snow;
      case "50d":
      case "50n":
        return mist;
      default:
        return null;
    }
  }

  weekDaySwitch(weekday: number) {
    switch (weekday) {
      case 1:
        return Strings.weekDays[0];
      case 2:
        return Strings.weekDays[1];
      case 3:
        return Strings.weekDays[2];
      case 4:
        return Strings.weekDays[3];
      case 5:
        return Strings.weekDays[4];
      case 6:
        return Strings.weekDays[5];
      case 7:
        return Strings.weekDays[6];
      default:
        return null;
    }
  }

  monthSwitch(month: number) {
    switch (month) {
      case 1:
        return Strings.monthNames[1];
      case 2:
        return Strings.monthNames[2];
      case 3:
        return Strings.monthNames[3];
      case 4:
        return Strings.monthNames[4];
      case 5:
        return Strings.monthNames[5];
      case 6:
        return Strings.monthNames[6];
      case 7:
        return Strings.monthNames[7];
      case 8:
        return Strings.monthNames[8];
      case 9:
        return Strings.monthNames[9];
      case 10:
        return Strings.monthNames[10];
      case 11:
        return Strings.monthNames[11];
      case 12:
        return Strings.monthNames[12];
      default:
        return null;
    }
  }

  getWindDirectionByNumber(number: any) {
    let windDirection;
    let windDeg = number;

    if (windDeg < 22.5 || windDeg >= 337.5) {
      windDirection = "N";
    } else if (windDeg < 67.5) {
      windDirection = "NE";
    } else if (windDeg < 112.5) {
      windDirection = "E";
    } else if (windDeg < 157.5) {
      windDirection = "SE";
    } else if (windDeg < 202.5) {
      windDirection = "S";
    } else if (windDeg < 247.5) {
      windDirection = "SW";
    } else if (windDeg < 292.5) {
      windDirection = "W";
    } else {
      windDirection = "NW";
    }
    return windDirection;
  }

  renderWeatherCard(elem: any) {
    const { displayDays } = this.state;
    const { data } = this.props;

    const day = DateTime.local().plus({ days: elem });

    const imgSource =
      this.weatherIconSwitch(data?.daily?.[elem]?.weather?.[0]?.icon) || "";

    return (
      <div
        key={`weather_car_${elem}`}
        className="Card CardPadding"
        style={{
          width: `${100 / displayDays.length - 1}%`,
        }}
      >
        <div className="CardInfo">
          <div className="CardHeader">
            <div>{day.weekdayShort}</div>
            <div>{day.day}</div>
          </div>
          <div className="CardWeather">
            <div>
              <span style={{ fontSize: "15px" }}>
                {data?.daily?.[elem]?.temp?.min?.toFixed(0)}º/
              </span>
              <span> {data?.daily?.[elem]?.temp?.max?.toFixed(0)}ºC</span>
            </div>
            <img alt="" src={imgSource} />
          </div>
          <div className="RainProbability">
            {(data?.daily?.[elem]?.pop * 100).toFixed(0)}%
          </div>
        </div>
        <div className="HumidityContainer">
          <div
            className="Humidity"
            style={
              data?.daily?.[elem].rain > 40
                ? { height: `${data?.daily?.[elem]?.rain}%` }
                : { height: "40%" }
            }
          >
            {data.daily[elem].rain ? data?.daily?.[elem]?.rain : 0}mm
          </div>
        </div>
        <img className="WeatherDayBackgroudImage" alt="" src={imgSource} />
      </div>
    );
  }

  renderWeatherCardByHour(elem: any) {
    const { data } = this.props;
    const hour = DateTime.local().plus({ hours: elem });
    const imgSource =
      this.weatherIconSwitch(data?.hourly?.[elem]?.weather?.[0]?.icon) || "";

    return (
      <>
        <div className="WeatherCardDate" style={{ display: "none" }}>
          <div style={{ opacity: 0.5 }}>
            {DateTime.fromSeconds(data.hourly[elem].dt).day ===
            DateTime.fromSeconds(data.current.dt).day
              ? Strings.ranges.today +
                ", " +
                DateTime.fromSeconds(data.hourly[elem].dt).day +
                "/" +
                DateTime.fromSeconds(data.hourly[elem].dt).month
              : Strings.ranges.tomorrow +
                ", " +
                DateTime.fromSeconds(data.hourly[elem].dt).day +
                "/" +
                DateTime.fromSeconds(data.hourly[elem].dt).month}
          </div>
        </div>
        <div
          className="Card"
          style={{ marginTop: "22px", paddingRight: "3px" }}
        >
          <div className="CardInfo">
            <div className="CardHeader">
              <div style={{ fontSize: "14px" }}>{hour.hour + ":00"}</div>
            </div>
            <div className="CardWeather">
              <div style={{ fontSize: "14px" }}>
                {data?.hourly?.[elem]?.temp?.toFixed(0)}ºC
              </div>
              <img alt="" src={imgSource} />
            </div>
            <div className="RainProbability">
              {(data?.hourly?.[elem]?.pop * 100).toFixed(0)}%
            </div>
          </div>
          <div className="HumidityContainer">
            <div
              className="Humidity"
              style={
                data?.hourly?.[elem].rain > 40
                  ? { height: `${data?.hourly?.[elem]?.rain}%` }
                  : { height: "40%" }
              }
            >
              {data.hourly[elem].rain ? data?.hourly?.[elem]?.rain?.["1h"] : 0}
              mm
            </div>
          </div>
          <img className="WeatherDayBackgroudImage" alt="" src={imgSource} />
        </div>
      </>
    );
  }

  render() {
    const { displayDays, mainCardSize, cardsDivSize, isMobile, isIPhone5 } =
      this.state;
    const { data, byHour } = this.props;
    console.log('DATE WEATHER', data);
    const imgSource =
      this.weatherIconSwitch(data?.current?.weather?.[0]?.icon) || "";

    var aux = 0,
      i = 0;
    var indexByHour = -1;

    var settingsByHour: any = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: this.handleResizeByHour(),
      slidesToScroll: this.handleResizeByHour(),
      speed: 500,
      autoplaySpeed: 5000,
      className: "MeteorologySlider ByDay",
      prevArrow: (
        <button type="button" className="slick-arrow">
          <em className="moon-baixa" style={{ transform: "rotate(90deg)" }} />
        </button>
      ),
      nextArrow: (
        <button type="button" className="slick-arrow">
          <em className="moon-baixa" style={{ transform: "rotate(-90deg)" }} />
        </button>
      ),
    };

    return (
      <div
        className="WeatherContainer"
        style={byHour ? { marginRight: 0 } : { height: 153 }}
      >
        <div
          className="Weather"
          style={
            displayDays.length && !byHour
              ? { margin: "0 50px" }
              : { display: "initial" }
          }
        >
          <div
            className="MainCard"
            style={
              byHour ? { width: "100%", height: 153 } : { width: mainCardSize }
            }
          >
            <div
              className="Header"
              style={byHour && !isMobile ? { justifyContent: "unset" } : {}}
            >
              <div
                className="Location Item"
                style={byHour ? { marginRight: "20%" } : {}}
              >
                <Icon name="placeholder" />
                <span>{data?.city}</span>
              </div>
              <div className="Item">
                <img alt="" src={imgSource} />
                <span style={{ fontSize: "13px" }}>
                  {data?.current?.weather?.[0]?.icon === "03d" || data?.current?.weather?.[0]?.icon === "03n" || data?.current?.weather?.[0]?.icon === "04d" || data?.current?.weather?.[0]?.icon === "04n" ?
                    Strings.weather.cloudy : data?.current?.weather?.[0]?.description?.toUpperCase()
                    }
                </span>
              </div>
            </div>
            <div
              className="Content"
              style={byHour && !isMobile ? { justifyContent: "unset" } : {}}
            >
              <div className="Item" style={byHour ? { marginRight: "5%" } : {}}>
                <Icon name="thermometer" />
                <span>{data?.current?.temp?.toFixed(0)}ºC</span>
              </div>
              <div className="Item" style={byHour ? { marginRight: "5%" } : {}}>
                <Icon name="water-drop" />
                <div>
                  <span>
                    {data.current.rain ? data?.current?.rain?.["1h"] : 0}
                  </span>
                  <span className="Unity">mm</span>
                </div>
              </div>
              <div className="Item" style={byHour ? { marginRight: "5%" } : {}}>
                <Icon name="water-drop" />
                <div>
                  <span>
                    {(
                      (data?.current?.pop || data?.daily[0]?.pop) * 100
                    ).toFixed(0) || 0}
                  </span>
                  <span className="Unity">%</span>
                </div>
              </div>
              <div className="Item">
                <Icon name="wind" />
                <div>
                  <span>{data?.current?.wind_speed?.toFixed(0) || 0}</span>
                  <span className="Unity">{this.getWindDirection()}</span>
                </div>
              </div>
            </div>
            <img alt="" className="WeatherMainBackgroudImage" src={imgSource} />
          </div>

          {byHour ? (
            <>
              <Row className="PageMargin">
                <div
                  className="WeekCards"
                  style={{ width: "100%", display: "block", padding: "20px" }}
                >
                  <Slider {...settingsByHour}>
                    {data?.hourly?.map((elem: any) => {
                      indexByHour++;
                      return this.renderWeatherCardByHour(indexByHour);
                    })}
                  </Slider>
                </div>
              </Row>
              <Row className="PageMargin">
                <Col
                  md={12}
                  lg={12}
                  xs={24}
                  sm={24}
                  style={{ padding: "20px" }}
                >
                  {data ? (
                    <Row>
                      <Col
                        md={4}
                        xs={5}
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        {Strings.weather.forecast}
                      </Col>
                      <Col md={4} xs={3}></Col>
                      <Col md={4} xs={4}>
                        <Icon name="thermometer" style={{ fontSize: "30px" }} />
                      </Col>
                      <Col md={4} xs={4}>
                        <Icon name="water-drop" style={{ fontSize: "30px" }} />
                      </Col>
                      <Col md={4} xs={4}>
                        <Icon name="water-drop" style={{ fontSize: "30px" }} />
                      </Col>
                      <Col md={4} xs={4}>
                        <Icon name="wind" style={{ fontSize: "30px" }} />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {data?.daily?.map((elem: any) => {
                    i++;
                    return (
                      <div>
                        <Row>
                          <Col
                            md={4}
                            xs={4}
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            {i < 2 ||
                            DateTime.fromSeconds(elem?.dt).day === 1 ? (
                              this.monthSwitch(
                                DateTime.fromSeconds(elem?.dt).month
                              )
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4} xs={4}>
                            <div>
                              {DateTime.fromSeconds(elem?.dt).day +
                                " " +
                                this.weekDaySwitch(
                                  DateTime.fromSeconds(elem?.dt).weekday
                                )}
                            </div>
                          </Col>

                          <Col md={4} xs={3}>
                            <img
                              style={{ width: "25px", height: "25px" }}
                              alt=""
                              src={
                                this.weatherIconSwitch(
                                  elem?.weather?.[0]?.icon.replace("n", "d")
                                ) || ""
                              }
                            />
                          </Col>

                          <Col md={4} xs={5}>
                            <div className="Item">
                              <span
                                style={isIPhone5 ? { fontSize: "12px" } : {}}
                              >
                                {elem?.temp?.min?.toFixed(0)}º/
                                {elem?.temp?.max?.toFixed(0)}ºC
                              </span>
                            </div>
                          </Col>

                          <Col md={4} xs={4}>
                            <div className="Item">
                              <span className="">
                                {elem.rain ? elem?.rain : 0}
                              </span>
                              <span className="Unity">mm</span>
                            </div>
                          </Col>

                          <Col md={4} xs={4}>
                            <div className="Item">
                              <span>{(elem?.pop * 100).toFixed(0) || 0}</span>
                              <span className="Unity ">%</span>
                            </div>
                          </Col>

                          <Col md={4} xs={4}>
                            <div className="Item">
                              <span>{elem?.wind_speed?.toFixed(0) || 0}</span>
                              <span className="Unity ">
                                {this.getWindDirectionByNumber(elem.wind_deg)}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ opacity: 0.2 }}></hr>
                      </div>
                    );
                  })}
                </Col>
                <Col
                  md={12}
                  lg={12}
                  xs={24}
                  sm={24}
                  style={{ padding: "20px" }}
                >
                  {data ? (
                    <Row>
                      <Col
                        md={4}
                        xs={5}
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        {Strings.weather.history}
                      </Col>
                      <Col md={4} xs={3}></Col>
                      <Col md={4} xs={4}>
                        <Icon name="thermometer" style={{ fontSize: "30px" }} />
                      </Col>
                      <Col md={4} xs={4}>
                        <Icon name="water-drop" style={{ fontSize: "30px" }} />
                      </Col>
                      <Col md={4} xs={4}>
                        <Icon name="water-drop" style={{ fontSize: "30px" }} />
                      </Col>
                      <Col md={4} xs={4}>
                        <Icon name="wind" style={{ fontSize: "30px" }} />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {data?.historical?.map((elem: any) => {
                    aux++;
                    return (
                      <div>
                        <Row>
                          <Col
                            md={4}
                            xs={4}
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            {aux < 2 ||
                            DateTime.fromSeconds(elem?.dt).day === 1 ? (
                              this.monthSwitch(
                                DateTime.fromSeconds(elem?.dt).month
                              )
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4} xs={5}>
                            <div>
                              {DateTime.fromSeconds(elem?.dt).day +
                                " " +
                                this.weekDaySwitch(
                                  DateTime.fromSeconds(elem?.dt).weekday
                                )}
                            </div>
                          </Col>

                          <Col md={4} xs={3}>
                            <img
                              style={{ width: "25px", height: "25px" }}
                              alt=""
                              src={
                                this.weatherIconSwitch(
                                  elem?.weather?.[0]?.icon.replace("n", "d")
                                ) || ""
                              }
                            />
                          </Col>

                          <Col md={4} xs={4}>
                            <div className="Item">
                              <span>{elem?.temp?.toFixed(0)}ºC</span>
                            </div>
                          </Col>
                          <Col md={4} xs={4}>
                            <div className="Item">
                              <span className="">
                                {elem.rain ? elem?.rain?.["1h"] : 0}
                              </span>
                              <span className="Unity">mm</span>
                            </div>
                          </Col>

                          <Col md={4} xs={4}>
                            <div className="Item">
                              <span>{elem?.pop || 0}</span>
                              <span className="Unity ">%</span>
                            </div>
                          </Col>

                          <Col md={4} xs={4}>
                            <div className="Item">
                              <span>{elem?.wind_speed?.toFixed(0) || 0}</span>
                              <span className="Unity ">
                                {this.getWindDirectionByNumber(elem.wind_deg)}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ opacity: 0.2 }}></hr>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </>
          ) : (
            <div className="WeekCards" style={{ width: cardsDivSize }}>
              {displayDays.map((elem: any) => {
                return this.renderWeatherCard(elem);
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || "pt",
});

export default connect(mapStateToProps)(Weather);
