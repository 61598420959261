/* eslint-disable jsx-a11y/iframe-has-title */
/*
 *
 * Training
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { Icon } from "components";
import { API, Endpoints } from "utils/api";
import { toast, translate } from "utils/utils";
import { DateTime } from "luxon";
import Strings from "utils/strings";
import "./styles.scss";

import PDFIcon from "../../assets/images/PDF_icon.png";

export class Training extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      training: {},
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(setTitle(Strings.library.training));

    this.getData();
  }

  getData = async () => {
    const { match, dispatch } = this.props;

    dispatch(setLoader(true));
    const response = await API.get({
      url: Endpoints.uriLibrary(match.params.id),
    });

    if (response.ok) {
      const data = response.data.results;

      this.setState({ training: data });
    } else {
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    dispatch(setLoader(false));
  };

  getLanguageAttachedFiles() {
    const { training } = this.state;

    let langFiles: any[] = [];
    training?.attachedFiles?.map((document: any) => {
      if (translate(document.url)) {
        langFiles.push(document);
      }
    });
    return langFiles;
  }

  render() {
    const { training } = this.state;
    const { dispatch } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.library.training}</title>
          <meta name="description" content="Description of Training" />
        </Helmet>

        <div className="PageHeader">
          <div
            className="PageHeaderTitle"
            onClick={() => dispatch(push("/library"))}
            style={{ cursor: "pointer" }}
          >
            <Icon name="back" style={{ fontWeight: "normal" }} />
            <span>{translate(training.name) || Strings.library.training}</span>
          </div>
        </div>
        {training ? (
          <div className="TrainingContent MobileContainer">
            {training.type === "image" ? (
              training.image ? (
                <img
                  className="TrainingImage"
                  alt={translate(training.name)}
                  src={training.image}
                />
              ) : (
                <></>
              )
            ) : training.type === "video" ? (
              training.video ? (
                <video
                  src={training.video}
                  controls
                  style={{ backgroundColor: "black" }}
                />
              ) : (
                <></>
              )
            ) : training.youtubeLink ? (
              <iframe src={training.youtubeLink} allowFullScreen />
            ) : (
              <></>
            )}
            <div className="TrainingInfoContainer">
              <div
                className="TrainingInfo"
                style={
                  !training?.attachedFiles?.length ? { width: "100%" } : {}
                }
              >
                <div className="TrainingHeader">
                  {translate(training.name)}
                  <span>
                    {DateTime.fromISO(
                      training.publishSchedule
                        ? training.publishScheduleDate
                        : training._created
                    ).toFormat("dd/MM/yyyy")}
                  </span>
                </div>
                <td
                  dangerouslySetInnerHTML={{
                    __html: translate(training.description),
                  }}
                />
              </div>
              {this.getLanguageAttachedFiles().length ? (
                <div className="TrainingFiles">
                  <div className="TrainingHeader">{Strings.fields.files}</div>
                  <div
                    className="TrainingFileContainer"
                    style={
                      !translate(training.description)
                        ? { minHeight: 30, width: "100%" }
                        : { minHeight: 30 }
                    }
                  >
                    {this.getLanguageAttachedFiles().map(
                      // eslint-disable-next-line array-callback-return
                      (document: any) => {
                        if (translate(document.name))
                          return (
                            <div
                              className="TrainingFile"
                              style={{ justifyContent: "normal" }}
                              onClick={() =>
                                window.open(translate(document.url))
                              }
                            >
                              <img
                                className="PDFIcon"
                                alt={translate(document.name)}
                                src={PDFIcon}
                              />
                              {translate(document.name)}
                            </div>
                          );
                      }
                    )}
                    {!this.getLanguageAttachedFiles()?.find(
                      (document: any) => translate(document.name)
                    ) && (
                      <div style={{ margin: 10 }}>
                        {Strings.generic.noContent}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(Training);
