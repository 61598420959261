/*
 *
 * ConfirmAccount
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setToken, setUser } from "store/actions";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import "./styles.scss";
import { RotateSpinner } from "react-spinners-kit";
import { API, Endpoints } from "utils/api";
import { push } from "connected-react-router";
import { toast } from "utils/utils";

export class ConfirmAccount extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const { dispatch, match } = this.props;
    const {
      params: { id, code },
    } = match || {};

    const response = await API.post({
      url: Endpoints.uriUsers(`confirm/${id}`),
      data: { code },
    });

    if (response.ok) {
      const { user, token } = response.data.results;

      toast.success(Strings.authentication.accountConfirmed);

      dispatch(setUser({ ...user }));
      dispatch(setToken(token));
      dispatch(push("/"));
    } else {
      toast.error(Strings.serverErrors.notFound);
      dispatch(push("/login"));
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.authentication.confirmAccount}</title>
          <meta name="description" content="Description of Confirm Account" />
        </Helmet>
        <div className="ConfirmAccountContent ImageContainer">
          <RotateSpinner size={58} color="#000" />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(ConfirmAccount);
