/**
 *
 * Header
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Icon } from "components";
import { Dropdown, Menu, Button, Select } from "antd";
import Strings from "utils/strings";
import { setLogout, setLanguage, setUser } from "store/actions";

import logo from "assets/images/logos/fertiberia-tech-gray.png";
import UserPlaceholder from "assets/images/placeholders/placeholder-profile.png";
import "./styles.scss";
import { LANGUAGES, translate/* , getOS, ANDROID_LINK, APPLE_LINK */ } from "utils/utils";

import ReactDOM from 'react-dom';
//@ts-ignore
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';

const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
export class Header extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      selectedLanguage: "pt",
      isMobile: window.innerWidth < 992,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    let reduxLanguage = this.props.language;
    if (!reduxLanguage) {
      console.log('LANG: ', navigator.language);
      const browserLang: any = navigator.language.substring(0, 2);
      if (["en", "pt", "fr", "es"].includes(browserLang)) {
        reduxLanguage = browserLang;
        this.props.dispatch(setLanguage(browserLang));
        this.setState({ selectedLanguage: browserLang });
      } else reduxLanguage = this.state.selectedLanguage;
    }
    Strings.setLanguage(reduxLanguage);



    this.handleResize();

    ReactDOM.render(<SmartBanner title={'Fertiberia'} />, document.getElementById('app_banner'));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleSidebar() {
    const { onToggleSidebar } = this.props;
    onToggleSidebar();
  }

  goToProfile() {
    const { dispatch } = this.props;
    dispatch(push("/profile"));
  }

  logout() {
    const { dispatch } = this.props;

    localStorage.removeItem("token");
    dispatch(setLogout());
    dispatch(push("/"));
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth < 992 });
  }

  renderBurger() {
    const { sidebarOpen } = this.props;
    const { isMobile } = this.state;

    if (!isMobile) return null;

    return (
      <div className="BurgerContainer" onClick={this.toggleSidebar}>
        {sidebarOpen ? (
          <Icon name="dots-vertical" className="BurgerMenu __bigger" />
        ) : (
          <Icon name="Menu" className="BurgerMenu" />
        )}
      </div>
    );
  }

  renderTopSidebar() {
    const { dispatch } = this.props;

    const { pathname } = window.location;

    return (
      <div className="TopSidebar">
        <div className="TopSidebarMenus">
          <a
            className={`TopSidebarMenuIndividual ${
              pathname === "/library" && "__active"
            }`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/library"));
            }}
            href="/library"
          >
            <span>{Strings.sidebar.library}</span>
          </a>
          <a
            className={`TopSidebarMenuIndividual ${
              pathname === "/deficiencies-identifying" && "__active"
            }`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/deficiencies-identifying"));
            }}
            href="/deficiencies-identifying"
          >
            <span>{Strings.sidebar.deficiencyIdentification}</span>
          </a>
          <a
            className={`TopSidebarMenuIndividual ${
              pathname === "/agronomic-services" && "__active"
            }`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/agronomic-services"));
            }}
            href="/agronomic-services"
          >
            <span>{Strings.sidebar.agronomicServices}</span>
          </a>
          <a
            className={`TopSidebarMenuIndividual ${
              pathname === "/meteorology" && "__active"
            }`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/meteorology"));
            }}
            href="/"
          >
            <span>{Strings.sidebar.meteorology}</span>
          </a>
          <a
            className={`TopSidebarMenuIndividual ${
              pathname === "/news" && "__active"
            }`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/news"));
            }}
            href="/"
          >
            <span>{Strings.sidebar.news}</span>
          </a>
          <a
            className={`TopSidebarMenuIndividual ${
              pathname === "/adp-products" && "__active"
            }`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/adp-products"));
            }}
            href="/adp-products"
          >
            <span>{Strings.sidebar.adpClassic}</span>
          </a>
          <a
            className={`TopSidebarMenuIndividual ${
              pathname === "/contact" && "__active"
            }`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/contact"));
            }}
            href="/contact"
          >
            <span>{Strings.sidebar.contacts}</span>
          </a>
        </div>
      </div>
    );
  }

  renderLinks() {
    const { dispatch, aboutUs } = this.props;

    const { pathname } = window.location;

    return (
      <>
        <a
          className={`HeaderMenuIndividual ${pathname === "/" && "__active"}`}
          onClick={(e) => {
            e.preventDefault();
            dispatch(push("/"));
          }}
          href="/"
        >
          <span>{Strings.sidebar.home.toUpperCase()}</span>
        </a>
        <a
          className={`HeaderMenuIndividual ${
            pathname === "/page/au" && "__active"
          }`}
          onClick={(e) => {
            e.preventDefault();
            dispatch(push("/page/au"));
          }}
          href="/page/au"
        >
          <span>{((aboutUs && translate(aboutUs.title)) || Strings.sidebar.aboutUs).toUpperCase()}</span>
        </a>
        <a
          className={`HeaderMenuIndividual ${
            pathname === "/products" && "__active"
          }`}
          onClick={(e) => {
            e.preventDefault();
            dispatch(push("/products"));
          }}
          href="/products"
        >
          <span>{Strings.sidebar.products.toUpperCase()}</span>
        </a>
        <a
          className={`HeaderMenuIndividual ${
            pathname === "/cultures" && "__active"
          }`}
          onClick={(e) => {
            e.preventDefault();
            dispatch(push("/cultures"));
          }}
          href="/cultures"
        >
          <span>{Strings.sidebar.cultures.toUpperCase()}</span>
        </a>
        <a
          className={`HeaderMenuIndividual ${
            pathname === "/promoters" && "__active"
          }`}
          onClick={(e) => {
            e.preventDefault();
            dispatch(push("/promoters"));
          }}
          href="/promoters"
        >
          <span>{Strings.sidebar.promoters.toUpperCase()}</span>
        </a>
      </>
    );
  }

  renderTitle() {
    const { isMobile, selectedLanguage } = this.state;
    const { user, dispatch, language } = this.props;

    // const os = getOS();

    if (user) {
      const img = new Image();
      img.src = user.photo;

      img.onerror = () => {
        dispatch(setUser({ ...user, photo: UserPlaceholder  }));
      };
    }

    const menu = (
      <Menu onClick={() => {}}>
        <Menu.Item
          key="1"
          style={{
            height: 40,
            display: "flex",
            alignItems: "center",
          }}
          icon={<Icon style={{ marginRight: 10 }} name="user2" />}
          onClick={() => dispatch(push("/profile"))}
        >
          {Strings.generic.profile}
        </Menu.Item>
        <Menu.Item
          key="separator"
          style={{
            height: 1,
            backgroundColor: "#EEE",
            padding: 0,
          }}
        />
        <Menu.Item
          key="2"
          style={{
            height: 40,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => this.logout()}
          icon={<Icon style={{ marginRight: 10 }} name="logout" />}
        >
          {Strings.authentication.signOut}
        </Menu.Item>
      </Menu>
    );

    return (
      <React.Fragment>
        <div className="HeaderLeftSection">
          {this.renderBurger()}
          {(!isMobile && (
            <>
              <a
                href="/"
                className="HeaderLogo"
                onClick={(e: any) => {
                  e.preventDefault();
                  dispatch(push("/"));
                }}
              >
                <img src={logo} alt="App Logo" />
              </a>
              <div className="HeaderMenus">{this.renderLinks()}</div>
            </>
          )) ||
            null}
        </div>
        <div className="HeaderRightSection">
          {/* {(!isMobile && (
						<div className="HeaderMenus">
							<a
								className="HeaderMenuIndividual __active"
								onClick={(e) => {
									e.preventDefault();
									dispatch(push("/"));
								}}
								href="/"
							>
								<span>Home</span>
							</a>
							<a
								className="HeaderMenuIndividual"
								onClick={(e) => {
									e.preventDefault();
									dispatch(push("/"));
								}}
								href="/"
							>
								<span>Menu 2</span>
							</a>
							<a
								className="HeaderMenuIndividual"
								onClick={(e) => {
									e.preventDefault();
									dispatch(push("/"));
								}}
								href="/"
							>
								<span>Menu 3</span>
							</a>
							<a
								className="HeaderMenuIndividual"
								onClick={(e) => {
									e.preventDefault();
									dispatch(push("/"));
								}}
								href="/"
							>
								<span>Menu 4</span>
							</a>
						</div>
					)) || null} */}
          <Select
            key={`language_${selectedLanguage}`}
            className="tagsSelector LanguageSelector"
            style={{ width: "100%" }}
            placeholder={Strings.language.header}
            defaultValue={language}
            onChange={(lang: any) => {
              Strings.setLanguage(lang);
              this.props.dispatch(setLanguage(lang));
              this.setState({ selectedLanguage: lang });
            }}
          >
            {LANGUAGES.map((lang: any) => (
              <Option key={lang.label} value={lang.value}>
                {lang.label}
              </Option>
            ))}
          </Select>
          {/* {os === 'Android' || os === 'iOS' ? <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => {
                console.log('os', os)

                if (os === 'Android') {
                    window.open(ANDROID_LINK);
                } else if (os === 'iOS') {
                    window.open(APPLE_LINK);
                }
              }}
            >
              <span>Download</span>
          </Button> : null} */}
          {(!user && (
            <Button
              type="primary"
              // icon={<Icon name="user2" style={{ marginRight: 10 }} />}
              onClick={() => dispatch(push("/login"))}
            >
              <span>{Strings.authentication.login_Register}</span>
            </Button>
          )) ||
            null}
          {(user && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <div className="HeaderUserContainer">
                {(!isMobile && <span>{user.name}</span>) || null}
                {/* <div className="HeaderUserAvatar"> */}
                <img
                  className="HeaderUserAvatar"
                  src={user.photo || UserPlaceholder}
                  alt="User Avatar"
                />
                {/* </div> */}
              </div>
            </Dropdown>
          )) ||
            null}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { isMobile } = this.state;
    return (
      <>
        {!isMobile && this.renderTopSidebar()}
        <div className="HeaderContainer">
          <div id="AppHeader" className="Header">
            {this.renderTitle()}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  router: state.router,
  title: state.title,
  user: state.user,
  language: state.language,
  settings: state.settings,
});

export default connect(mapStateToProps)(Header);
