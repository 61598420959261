import { PrivateRoute } from "components";
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import {
  Home,
  Login,
  RecoverPassword,
  Error500,
  Register,
  Profile,
  Products,
  Product,
  Formula,
  Culture,
  Cultures,
  Notice,
  Promoters,
  ConfirmAccount,
  DeficienciesIdentifying,
  Page,
  Meteorology,
  Library,
  Training,
  AgronomicServices,
  AgronomicService,
  AnalysisType,
  News,
  Contact,
} from "screens";

export const offlinePages = [
  "/login",
  "/register",
  "/accept-invite",
  "/recover-password",
  "/confirm",
];

export class Routes extends React.Component<any, any> {
  shouldComponentUpdate(nextProps: any) {
    const { user, token } = this.props;
    const isLoggedIn = Boolean(user && token);
    const willBeLoggedIn = Boolean(nextProps.user && nextProps.token);

    return isLoggedIn !== willBeLoggedIn;
  }

  componentDidUpdate() {
    const elem = document.getElementById("app_content");

    if (elem) {
      elem.scrollTop = 0;
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/adp-products" component={Products} />
        <Route exact path="/cultures" component={Cultures} />
        <Route
          exact
          path="/deficiencies-identifying"
          component={DeficienciesIdentifying}
        />
        <Route exact path="/news/:id" component={Notice} />
        <Route exact path="/cultures/:id" component={Culture} />
        <Route exact path="/products/:id" component={Product} />
        <Route exact path="/adp-products/:id" component={Product} />
        <Route exact path="/formula/:id" component={Formula} />
        <Route exact path="/promoters" component={Promoters} />
        <Route exact path="/recover-password" component={RecoverPassword} />
        <Route
          exact
          path="/recover-password/:id/:code"
          component={RecoverPassword}
        />
        <Route exact path="/confirm/:id/:code" component={ConfirmAccount} />
        <Route exact path="/page/:type" component={Page} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/meteorology" component={Meteorology} />
        <Route exact path="/library" component={Library} />
        <Route exact path="/library/:id" component={Training} />
        <Route exact path="/agronomic-services" component={AgronomicServices} />
        <Route
          exact
          path="/agronomic-services/:id"
          component={AgronomicService}
        />
        <Route
          exact
          path="/agronomic-services/:id/:type"
          component={AnalysisType}
        />
        <Route exact path="/news" component={News} />

        <Route exact path="/500" component={Error500} />
        <Redirect to="/" />
      </Switch>
    );
  }
}

const mapStateToProps = (state: any) => ({
  router: state.router,
  user: state.user,
  token: state.token,
});

export default connect(mapStateToProps)(Routes);
