// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CultureCard{height:450px;background-repeat:no-repeat;background-position:center;background-size:cover;position:relative;margin:5px}@media screen and (max-width: 600px){.CultureCard{margin:0}}.CultureCard.main{height:470px}.CultureCard.Sidebar{height:200px;opacity:.7}.CultureCard.Sidebar.Selected{opacity:1}.CultureCard.Sidebar.Selected em{color:#fff}.CultureCard.Sidebar em{font-size:27px;right:0;position:absolute;margin:15px;cursor:pointer;z-index:9;color:#444}.CultureCard .CultureCardOpacity{position:absolute;background-color:#000;opacity:.3;height:100%;width:100%}.CultureCard .CultureCardOverlay{display:flex;background-color:rgba(0,0,0,.7);width:100%;bottom:0;position:absolute;justify-content:space-between;align-items:center;padding:10px 25px;color:#fff;height:100px;z-index:2}.CultureCard .CultureCardOverlay.Sidebar{height:50px;background:linear-gradient(transparent 0, rgba(0, 0, 0, 0.8) 80%)}.CultureCard .CultureCardOverlay.Sidebar .CultureCardName{font-size:16px;font-weight:bold;text-transform:initial}.CultureCard .CultureCardOverlay .CultureCardName{font-size:16px;font-weight:bold;text-transform:uppercase}.CultureCard .CultureCardOverlay .SeeDetailsButton{max-width:160px;height:55px !important;border:1px solid #fff;color:#fff;background-color:transparent;margin:0 5px}.CultureCard .CultureCardOverlay .SeeDetailsButton:hover{background-color:#fff;color:#000}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
