/**
 *
 * Sidebar
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import logo from "assets/images/logos/fertiberia-tech-gray.png";
import Strings from "utils/strings";
import "./styles.scss";

export class Sidebar extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      activeTab: "/dashboard",
    };

    this.selectTab = this.selectTab.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  selectTab(url: String) {
    const { activeTab } = this.state;

    this.setState({ activeTab: activeTab === url ? "" : url });
  }

  goTo(url: String) {
    const clientWidth = document.body.clientWidth;
    this.setState({ activeTab: url });
    this.props.onMobile(clientWidth < 992);
    this.props.dispatch(push(`/${url}`));
  }

  render() {
    const { open, dispatch } = this.props;

    const { pathname } = window.location;

    return (
      <div className="SidebarWrapper">
        <div className={`SidebarContainer${open ? " open" : " closed"}`}>
          <div className="SidebarHome">
            <a className="SidebarLogoContainer" href="/">
              {/* <img src={logo} alt="App Logo" /> */}
              <img className="Logo" alt="Logo" src={logo} />
            </a>
          </div>
          <div className="SidebarContent">
            <a
              className={`SidebarMenuObject ${pathname === "/" && "__active"}`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/"));
                this.props.closeSidebar();
              }}
              href="/"
            >
              <span>{Strings.sidebar.home.toUpperCase()}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/aboutUs" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/aboutUs"));
                this.props.closeSidebar();
              }}
              href="/aboutUs"
            >
              <span>{Strings.sidebar.aboutUs.toUpperCase()}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/products" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/products"));
                this.props.closeSidebar();
              }}
              href="/products"
            >
              <span>{Strings.sidebar.products.toUpperCase()}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/cultures" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/cultures"));
                this.props.closeSidebar();
              }}
              href="/cultures"
            >
              <span>{Strings.sidebar.cultures.toUpperCase()}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/promoters" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/promoters"));
                this.props.closeSidebar();
              }}
              href="/promoters"
            >
              <span>{Strings.sidebar.promoters.toUpperCase()}</span>
            </a>
            {/* Top sidebar */}
            <div className="Separator" />
            <a
              className={`SidebarMenuObject ${
                pathname === "/library" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/library"));
                this.props.closeSidebar();
              }}
              href="/library"
            >
              <span>{Strings.sidebar.library}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/deficiencies-identifying" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/deficiencies-identifying"));
                this.props.closeSidebar();
              }}
              href="/deficiencies-identifying"
            >
              <span>{Strings.sidebar.deficiencyIdentification}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/agronomic-services" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/agronomic-services"));
                this.props.closeSidebar();
              }}
              href="/agronomic-services"
            >
              <span>{Strings.sidebar.agronomicServices}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/meteorology" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/meteorology"));
                this.props.closeSidebar();
              }}
              href="/meteorology"
            >
              <span>{Strings.sidebar.meteorology}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/news" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/news"));
                this.props.closeSidebar();
              }}
              href="/news"
            >
              <span>{Strings.sidebar.news}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/adp-products" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/adp-products"));
                this.props.closeSidebar();
              }}
              href="/adp-products"
            >
              <span>{Strings.sidebar.adpClassic}</span>
            </a>
            <a
              className={`SidebarMenuObject ${
                pathname === "/contact" && "__active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                dispatch(push("/contact"));
                this.props.closeSidebar();
              }}
              href="/contact"
            >
              <span>{Strings.sidebar.contacts}</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  settings: state.settings,
  user: state.user,
  language: state.language || "pt",
});

export default connect(mapStateToProps)(Sidebar);
