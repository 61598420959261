/*
 *
 * Register
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { Row, Col, Checkbox } from "antd";
import { push } from "connected-react-router";
import "./styles.scss";
import { emailIsValid, toast, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import { RotateSpinner } from "react-spinners-kit";

export class Register extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      email: "",
      password: "",
      repeatPassword: "",
      checked: false,
      acceptMarketing: false,
      registering: false,
    };
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    if (user) {
      dispatch(push("/"));
    }
    this.setState({ language: this.props.language });
  }

  registerIsValid() {
    const { name, email, password, repeatPassword, checked } = this.state;

    if (
      !name ||
      !name.trim() ||
      !email ||
      !email.trim() ||
      !emailIsValid(email) ||
      !password ||
      !repeatPassword ||
      !checked
    ) {
      toast.warn(Strings.errors.pleaseFillFormCorrectly);
      return false;
    }

    if (password !== repeatPassword) {
      toast.warn(Strings.errors.passwordsDontMatch);
      return false;
    }

    return true;
  }

  async register() {
    const { name, email, password, language, acceptMarketing } = this.state;
    const { dispatch } = this.props;

    if (this.registerIsValid()) {
      this.setState({ registering: true });

      const body = {
        name,
        email,
        password,
        acceptMarketing,
      };

      const response = await API.post({
        url: Endpoints.uriUsers(),
        data: body,
      });

      if (response.ok) {
        toast.success(Strings.authentication.registeredSuccessfully);
        dispatch(push("/login"));
      } else {
        const message = JSON.parse(response.data?.message);
        this.setState({ registering: false });
        return toast.error(message[language] ? message[language] : message.en);
        
      }

      this.setState({ registering: false });
    }
  }

  render() {
    const { name, email, password, repeatPassword, registering, checked, acceptMarketing } = this.state;
    const { dispatch } = this.props;

    console.log('MKT', acceptMarketing);

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.authentication.register}</title>
          <meta name="description" content="Description of Register" />
        </Helmet>
        <div className="RegisterContent MobileContainer">
          <div className="RegisterContentWrapper">
            <Row>
              <Col
                xs={24}
                sm={{ span: 18, offset: 3 }}
                lg={{ span: 18, offset: 3 }}
              >
                {/* <h2 style={{ fontWeight: 'bold' }}>{Strings.authentication.register}</h2> */}
                <div className="RegisterBox">
                  <Row>
                    <Col xs={24} lg={12}>
                      <div className="RegisterWrapper">
                        {/* <h2>{Strings.authentication.newAccount}</h2> */}
                        <form
                          onSubmit={(e: any) => {
                            e.preventDefault();

                            if (!registering) {
                              this.register();
                            }
                          }}
                        >
                          <h2 style={{ fontWeight: "normal" }}>
                            {Strings.authentication.newAccount}
                          </h2>
                          <input
                            placeholder={Strings.authentication.name}
                            className="RegisterInputClass"
                            value={name}
                            type="text"
                            onChange={(e: any) =>
                              this.setState({ name: e.target.value })
                            }
                            required
                          />
                          <input
                            placeholder={Strings.authentication.email}
                            type="email"
                            className="RegisterInputClass"
                            value={email}
                            onChange={(e: any) =>
                              this.setState({ email: e.target.value })
                            }
                            required
                          />
                          <input
                            placeholder={Strings.authentication.password}
                            type="password"
                            className="RegisterInputClass"
                            value={password}
                            onChange={(e: any) =>
                              this.setState({ password: e.target.value })
                            }
                            required
                          />
                          <input
                            placeholder={Strings.authentication.repeatPassword}
                            className="RegisterInputClass"
                            value={repeatPassword}
                            type="password"
                            onChange={(e: any) =>
                              this.setState({ repeatPassword: e.target.value })
                            }
                            required
                          />
                          <button type="submit" className="LoginButtonClass">
                            {registering ? (
                              <div className="LoadingSpinner">
                                <RotateSpinner size={24} color="#FFFFFF" />
                              </div>
                            ) : (
                              Strings.authentication.register
                            )}
                          </button>
                          <div className="RegisterOptions">
                            <a
                              href="/login"
                              onClick={(e: any) => {
                                e.preventDefault();
                                dispatch(push("/login"));
                              }}
                              className="LoginOptionLink"
                            >
                              <small>
                                {Strings.authentication.iHaveAnAccount}
                              </small>
                            </a>
                          </div>
                        </form>
                        <div className="CheckBoxContainer" style={{ textAlign: "center", marginTop: 20 }}>
													<Checkbox
														checked={checked}
														onChange={(e: any) =>
															this.setState({
																checked: e.target.checked,
															})
														}
													>
														{Strings.contact.acceptTerms + " "}
														<a href="/page/pp" target="_blank">
															{Strings.sidebar.privacy}
														</a>
													</Checkbox>
												</div>
                        <div className="CheckBoxContainer" style={{ textAlign: "center", marginTop: 20 }}>
													<Checkbox
														checked={acceptMarketing}
														onChange={(e: any) =>
															this.setState({
																acceptMarketing: e.target.checked,
															})
														}
													>
														{Strings.contact.acceptMarketing}
													</Checkbox>
												</div>
                      </div>
                    </Col>
                    <Col xs={0} lg={12}>
                      <div className="LoginWelcome">
                        {/* <div className="LoginAuthenticationWrapper">
													<Result status="500" />
												</div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  language: state.language || "pt",
});

export default connect(mapStateToProps)(Register);
