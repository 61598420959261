/*
 *
 * Products
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { ProductCard, Icon, Slider } from "components";
import { toast, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";

export class Products extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      adpProducts: false,
      categories: [],
      isMobile: window.innerWidth <= 580,
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const { adpProducts } = this.state;
    const { dispatch } = this.props;

    dispatch(setTitle(Strings.sidebar.products));

    const { pathname } = window.location;

    if (pathname === "/adp-products" && !adpProducts)
      this.setState({ adpProducts: true }, () => this.getData());
    else if (pathname === "/products" && adpProducts)
      this.setState({ adpProducts: false }, () => this.getData());
    else this.getData();
  }

  componentDidUpdate() {
    const { adpProducts } = this.state;
    const { pathname } = window.location;

    if (pathname === "/adp-products" && !adpProducts)
      this.setState({ adpProducts: true }, () => this.getData());
    else if (pathname === "/products" && adpProducts)
      this.setState({ adpProducts: false }, () => this.getData());
  }

  handleResize() {
    this.setState({
      isMobile: window.innerWidth <= 580,
      isLaptop: window.innerWidth <= 1440,
    });
  }

  getData = async () => {
    const { adpProducts } = this.state;
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    const response = await API.get({
      url: Endpoints.uriProducts("", adpProducts ? "adp" : ""),
    });

    if (response.ok) {
      // this.setState({ news: response.data.results.news });
      this.setState({ categories: response.data.results });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  render() {
    const { categories, adpProducts } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.products}</title>
          <meta name="description" content="Description of Products" />
        </Helmet>
        <div
          className="PageHeader"
          style={adpProducts ? { backgroundColor: "black" } : {}}
        >
          <div className="PageHeaderTitle">
            {adpProducts ? (
              <Icon name="adp-classic_white" />
            ) : (
              <Icon name="fertilizer-2" />
            )}
            <span>
              {adpProducts
                ? Strings.products.adpProducts
                : Strings.sidebar.products}
            </span>
          </div>
          {/* <div
            className={`PageHeaderFiltersButton ${
              adpProducts && "adpProducts"
            }`}
          >
            {isMobile ? "" : Strings.fields.filters}
            <Icon name="edit" />
          </div> */}
        </div>
        <div className="ProductsContent MobileContainer">
          {categories &&
            // eslint-disable-next-line array-callback-return
            categories.map((elem: any) => {
              if (elem.category)
                return (
                  <div className="CategorySection">
                    <div className="CategoryTitle">
                      <img
                        alt={translate(elem.category.name)}
                        src={elem.category.icon}
                      ></img>
                      <span>{translate(elem.category.name)}</span>
                    </div>
                    <div>
                      {elem.products && elem.products.length ? (
                        <Slider
                          cards={elem.products.map((product: any) => {
                            return <ProductCard data={product} main />;
                          })}
                        />
                      ) : (
                        <div>No Content</div>
                      )}
                    </div>
                  </div>
                );
            })}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(Products);
