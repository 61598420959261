/*
 *
 * Footer
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Row, Col } from "antd";
import { DateTime } from "luxon";

import "./styles.scss";
import logo from "assets/images/logos/fertiberia-tech-white.png";
import { translate } from "utils/utils";
import Strings from "utils/strings";

export class Footer extends React.PureComponent<any, any> {
  componentDidMount() {
    this.setState({ language: this.props.language });
  }

  render() {
    const { dispatch, pages, language } = this.props;

    const year = DateTime.utc().toFormat('yyyy')

    return (
      <footer className="Footer_Container">
        <div className="Footer">
          <Row gutter={[50, 50]}>
            <Col xs={24} lg={5}>
              <div className="Footer_Logo">
                <img src={logo} alt="Footer Logo" />
              </div>
            </Col>

            {pages ? <Col xs={12} md={8} lg={6}>
              <div className="Footer_Menu">
                <span className="Footer_Menu_Title">
                  {Strings.footer.about}
                </span>
                {pages.map((page: any) => (
                  <a
                    href={`/page/${page.type}`}
                    onClick={(e: any) => {
                      e.preventDefault();
                      dispatch(push(`/page/${page.type}`));
                    }}
                    className="Footer_Menu_Item"
                  >
                    {translate(page.title)}
                  </a>
                ))}
                <a
                  href="/contact"
                  onClick={(e: any) => {
                    e.preventDefault();
                    dispatch(push("/contact"));
                  }}
                  className="Footer_Menu_Item"
                >
                  {Strings.footer.sendUsEmail}
                </a>
              </div>
            </Col> : null}
            <Col xs={12} md={8} lg={6}>
              <div className="Footer_Menu">
                <span className="Footer_Menu_Title">
                  {Strings.footer.services}
                </span>
                <a
                  href="/deficiencies-identifying"
                  onClick={(e: any) => {
                    e.preventDefault();
                    dispatch(push("/deficiencies-identifying"));
                  }}
                  className="Footer_Menu_Item"
                >
                  {Strings.sidebar.deficiencyIdentification}
                </a>
                <a
                  href="/agronomic-services"
                  onClick={(e: any) => {
                    e.preventDefault();
                    dispatch(push("/agronomic-services"));
                  }}
                  className="Footer_Menu_Item"
                >
                  {Strings.sidebar.agronomicServices}
                </a>
              </div>
            </Col>
            <Col xs={12} md={8} lg={6}>
              <div className="Footer_Menu">
                <span className="Footer_Menu_Title">
                  {Strings.footer.social}
                </span>
                <a
                  href={ language === "pt" ? "https://www.facebook.com/adpfertilizantes.pt" : "https://www.facebook.com/FertiberiaTECHES" }
                  onClick={(e: any) => {
                    e.preventDefault();
                    window.open(language === "pt" ? "https://www.facebook.com/adpfertilizantes.pt" : "https://www.facebook.com/FertiberiaTECHES");
                  }}
                  className="Footer_Menu_Item"
                >
                  Facebook
                </a>
                <a
                  href={ language === "pt" ? "https://www.instagram.com/adpfertilizantes/" : "https://www.instagram.com/fertiberia.tech/" }
                  onClick={(e: any) => {
                    e.preventDefault();
                    window.open(language === "pt" ? "https://www.instagram.com/adpfertilizantes/" : "https://www.instagram.com/fertiberia.tech/");
                  }}
                  className="Footer_Menu_Item"
                >
                  Instagram
                </a>
                <a
                  href={ language === "pt" ? "https://www.youtube.com/channel/UC1SNis95OPMVw1W-Kc1_VVg" : "https://www.youtube.com/channel/UCJDgfB29Gc12vblB0P0p6PQ" }
                  onClick={(e: any) => {
                    e.preventDefault();
                    window.open(language === "pt" ? "https://www.youtube.com/channel/UC1SNis95OPMVw1W-Kc1_VVg" : "https://www.youtube.com/channel/UCJDgfB29Gc12vblB0P0p6PQ");
                  }}
                  className="Footer_Menu_Item"
                >
                  Youtube
                </a>
                <a
                  href={ language === "pt" ? "https://pt.linkedin.com/company/adp-fertilizantes" : "https://www.linkedin.com/company/fertiberia-tech-es/" }
                  onClick={(e: any) => {
                    e.preventDefault();
                    window.open(language === "pt" ? "https://pt.linkedin.com/company/adp-fertilizantes" : "https://www.linkedin.com/company/fertiberia-tech-es/");
                  }}
                  className="Footer_Menu_Item"
                >
                  Linkedin
                </a>
              </div>
            </Col>
            <Col xs={24}>
              <div style={{ padding: 0 }}>
                <div className="Footer_Menu __alignRight" style={{ alignItems: 'center' }}>
                  <span className="Footer_Menu_Item">
                    {`${Strings.footer.copyright}${year} ${Strings.footer.fertiberiaTech}`}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  router: state.router,
  language: state.language || "pt",
});

export default connect(mapStateToProps)(Footer);
