/*
 *
 * Formula
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Row } from "antd";
import { Icon } from "components";
import { formulaFormat, translate, toast } from "utils/utils";
import { API, Endpoints } from "utils/api";
import PDFIcon from "../../assets/images/PDF_icon.png";
import Strings from "utils/strings";
import "./styles.scss";

export class Formula extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			formula: this.props.formula,
			product: this.props.product,
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.products.formulas));

		this.getData();
	}

	getData = async () => {
		const { formula, product } = this.state;
		const { dispatch, match } = this.props;

		if (!formula && product) {
			dispatch(setLoader(true));

			const response = await API.get({
				url: Endpoints.uriProducts(product._id || product),
			});

			if (response.ok) {
				const { product } = response.data.results;

				const formula = product.formulas.find((elem: any) => elem._id === match.params.id);

				this.setState({
					product,
					formula,
				});
			} else {
				dispatch(setLoader(false));
				return toast.error(response.data?.message || Strings.serverErrors.title);
			}
			dispatch(setLoader(false));
		}
	};

	renderDocumentation() {
		const { formula } = this.state;

		if (!formula?.attachedFiles?.filter((elem: any) => translate(elem.file)).length)
			return <></>;

		return (
			<>
				<div className="Subtitle">{Strings.products.documentation}</div>
				<div className="FormulaDocuments" style={{ minHeight: 30 }}>
					{formula?.attachedFiles.map((file: any) =>
						translate(file.name) ? (
							<div
								className="FormulaDocument"
								onClick={() =>
									translate(file.file) && window.open(translate(file.file))
								}
							>
								<img className="PDFIcon" alt={translate(file.name)} src={PDFIcon} />
								{translate(file.name)}
							</div>
						) : null
					)}
				</div>
			</>
		);
	}

	render() {
		const { formula, product } = this.state;
		const { dispatch, backPath } = this.props;

		const cultures = formula?.cultures?.filter(
			(elem: any) =>
				(translate(elem.culture?.name) || translate(elem.title)) &&
				translate(elem.description)
		);

		console.log('FORMULAR', formula)

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.formulas.header}</title>
					<meta name="description" content="Formula screen" />
				</Helmet>
				<div className="PageHeader" style={{ backgroundColor: product?.color }}>
					<div
						className="PageHeaderTitle"
						style={{ cursor: 'pointer', textTransform: 'none', fontSize: 20 }}
						onClick={() =>
							backPath
								? dispatch(push(backPath))
								: product && dispatch(push(`/products/${product._id}`))
						}
					>
						<Icon name="back" style={{ fontWeight: "normal" }} />
						<div>{translate(formula?.name)}</div>
					</div>
				</div>
				<Row className="FormulaContent MobileContainer">
					<div className="FormulaImageContainer">
						{/* <div
							className="FormulaImage"
							style={{ backgroundImage: `url(${product?.image})` }}
						></div> */}
						<img alt={translate(formula?.name)} src={product?.image} />
					</div>
					<div className="FormulaInfo">
						<div className="FormulaTitle" style={{ color: product?.color }}>
							{translate(formula?.name)}
						</div>
						<div style={{ marginBottom: 10 }}>
							{translate(formula?.information)}
						</div>
						{formula?.nutrientFormulas?.length ? <div className="Subtitle">{Strings.formulas.details}</div> : null}
						<div className="FormulaContent" style={{ maxWidth: 350 }}>
							{formula?.nutrientFormulas?.map((elem: any) => (
								<div className="NutrientFormulas">
									<div className="NutrientName">
										{translate(elem.nutrient.name)}&nbsp;
										{formulaFormat(elem.nutrient.formula, elem.nutrient.isFormula)}
									</div>
									<div className="NutrientPercentage">{elem.percentage}%</div>
								</div>
							))}
						</div>
						{translate(formula?.moreInformation) ?
							<div className="Subtitle" style={{ fontSize: 12, marginTop: 15, marginBottom: 10 }}>
								{Strings.formulas.formulaInformation}
							</div> : null}
						<div className="" style={{ whiteSpace: "break-spaces" }}>
							{translate(formula?.moreInformation)}
						</div>
					</div>
					<div className="FormulaDocumentation">
						{cultures?.length ? (
							<div style={{ marginBottom: 10 }}>
								{cultures?.find(
									(elem: any) =>
										(translate(elem.culture?.name) || translate(elem.title)) &&
										translate(elem.description)
								) ? (
									<div className="Subtitle">
										{Strings.formulas.culturesAndDoses}
									</div>
								) : null}
								{cultures?.map((elem: any) =>
									(translate(elem.culture?.name) || translate(elem.title)) &&
									translate(elem.description) ? (
										<div className="FormulaCulture">
											{translate(elem.culture?.name)}
											&nbsp;{translate(elem.title)}: &nbsp;
											{translate(elem.description)}
										</div>
									) : null
								)}
							</div>
						) : (
							<></>
						)}
						{this.renderDocumentation()}
					</div>
				</Row>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language || "pt",
	formula: state.router?.location?.state?.formula,
	product: state.router?.location?.state?.product,
	backPath: state.router?.location?.state?.backPath,
});

export default connect(mapStateToProps)(Formula);
