/*
 *
 * News
 *
 */

import React from "react";
import { connect } from "react-redux";
import { API, Endpoints } from "utils/api";
import { Icon, Pagination } from "components";
import { toast, translate } from "utils/utils";
import { Button } from "antd";
import { push } from "connected-react-router";
import { setTitle, setLoader } from "store/actions";
import Strings from "utils/strings";
import "./styles.scss";

export class News extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = { page: 0 };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle("News"));

    this.getData();
  }

  getData = async () => {
    const { dispatch } = this.props;

    dispatch(setLoader(true));
    const response = await API.get({
      url: Endpoints.uriNews(),
    });

    if (response.ok) {
      const data = response.data.results;

      this.setState({ news: data });
    } else {
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    dispatch(setLoader(false));
  };

  getCurrentNews = () => {
    const { news, page } = this.state;
    var auxNews = JSON.parse(JSON.stringify(news));
    auxNews = auxNews.splice(page * 5, 5);
    return auxNews;
  };

  render() {
    const { news, page } = this.state;
    const { dispatch } = this.props;
    return (
      <>
        {news ? (
          <div className="PageHeader">
            <div
              className="PageHeaderTitle"
              onClick={() => dispatch(push("/home"))}
              style={{ cursor: "pointer" }}
            >
              <Icon name="back" style={{ fontWeight: "normal" }} />
              <div>{Strings.sidebar.news}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="PageContent">
          {news && news.length ? (
            this.getCurrentNews().map((notice: any) => {
              if (!translate(notice.name)) return <></>;
              return (
                <div>
                  <div
                    className="NewsContainer"
                    style={{ backgroundImage: `url(${notice.image})` }}
                  >
                    <div className="NewContent NewScreen">
                      <div className="NewTitle">{translate(notice.name)}</div>
                      <div className="NewContentText">
                        {translate(notice.shortDescription)}
                      </div>
                      <Button
                        className="SeeNewsButton"
                        style={{ width: "75%" }}
                        onClick={() => dispatch(push(`/news/${notice._id}`))}
                      >
                        <span>{Strings.news.seeNews.toUpperCase()}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
          {news && news.length > 5 ? (
            <Pagination
              page={page}
              totalItems={news?.length || 0}
              onChangePage={(page: any) => this.setState({ page })}
            />
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(News);
