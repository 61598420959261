/**
 *
 * Pagination
 *
 */

import React from "react";
import "./styles.scss";

export class Pagination extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      totalPages: Math.ceil(this.props.totalItems / 5),
    };
  }

  changePage = (page: any) => {
    const { totalPages } = this.state;
    const { onChangePage } = this.props;
    if (page > totalPages || page < 1 || page === this.state.page) return;
    onChangePage(page - 1);
  };

  render() {
    const { totalPages } = this.state;
    const { page } = this.props;
    let pages = [];
    if (totalPages < 9) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <div
            className={`PageNumber ${page === i - 1 ? "__active" : ""}`}
            onClick={() => this.changePage(i)}
          >
            {i}
          </div>
        );
      }
    } else {
      const start = page > 5 ? page - 4 : 1;
      const range = totalPages - page > 5 ? page + 3 : totalPages + 1;
      for (let i = start; i < range; i++) {
        pages.push(
          <div
            className={`PageNumber ${page === i - 1 ? "__active" : ""}`}
            onClick={() => this.changePage(i)}
          >
            {i}
          </div>
        );
      }
    }
    return (
      <div className="Pagination" key={`${page}_${totalPages}`}>
        <div className="PageNumber" onClick={() => this.changePage(1)}>
          &#8810;
        </div>
        <div
          className="PageNumber"
          onClick={() => this.changePage(this.props.page)}
        >
          &#x3c;
        </div>
        {page > 5 && <div className="PageNumber">...</div>}
        {pages}
        {page < totalPages - 5 && <div className="PageNumber">...</div>}
        <div
          className="PageNumber"
          onClick={() => this.changePage(this.props.page + 2)}
        >
          &#x3e;
        </div>
        <div className="PageNumber" onClick={() => this.changePage(totalPages)}>
          &#x226B;
        </div>
      </div>
    );
  }
}

export default Pagination;
