/* eslint-disable array-callback-return */
/*
 *
 * Culture
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setTitle, setLoader, setUser } from "store/actions";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { Row, Col, Switch, Image, Select } from "antd";
import { Icon, SelectableTabs, ProductCard } from "components";
import { toast, formulaFormat, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { EditSidebar } from "components/EditSidebar";
import { TextField } from "@material-ui/core";
import { DateTime } from "luxon";
import "./styles.scss";

const { Option } = Select;

export class Culture extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			selectedTab: "fertilizing",
			isFavorite: false,
			isMobile: window.innerWidth <= 600,
			isLaptop: window.innerWidth <= 1440,
			filters: {
				country: {},
				state: {},
				destination: "",
				expectedProduction: "Consoante Adubação Habitual",
				usualFertilizing: true,
			},
			item: {},
			countries: [],
			language: "pt",
		};

		this.handleResize = this.handleResize.bind(this);
		window.addEventListener("resize", this.handleResize);
	}

	componentDidMount() {
		const { dispatch, match, user } = this.props;

		dispatch(setTitle(Strings.sidebar.cultures));

		const isFavorite = !!user?.cultures?.find((elem: any) => match.params.id === (elem._id || elem));

		this.setState({ isFavorite });

		this.getData();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		this.setState({
			isMobile: window.innerWidth <= 600,
			isLaptop: window.innerWidth <= 1440,
		});
	}

	getData = async () => {
		const { match, dispatch, user } = this.props;
		const { filters } = this.state;

		dispatch(setLoader(true));

		const list = [
			API.get({
				url: Endpoints.uriMisc(`countries/promoters/`),
			}),
			API.get({
				url: Endpoints.uriCultures(match.params.id),
			}),
		];
		if (user?._id)
			list.push(
				API.get({
					url: Endpoints.uriUsers(user._id),
				})
			);

		const [countriesResponse, cultureResponse, userResponse] = await Promise.all(list);

		if (countriesResponse.ok) {
			this.setState({
				countries: countriesResponse.data.results,
			});
		} else {
			dispatch(setLoader(false));
			return toast.error(countriesResponse.data?.message || Strings.serverErrors.title);
		}

		if (cultureResponse.ok) {
			const { culture } = cultureResponse.data.results;
			let productDestinations: any[] = [];
			let expectProductions: any[] = [];

			let hasDeficiencies = false;
			let hasToxicities = false;

			for (let nutrient of culture.nutrientsDeficiencyToxicity || []) {
				const auxCorrections = JSON.parse(JSON.stringify(nutrient.corrections || []));
				let i = 0;
				for (let correction of auxCorrections) {
					correction.formulas =
						correction.product?.formulas?.filter((elem: any) =>
							correction.formulas.includes(elem._id)
						) || [];
					auxCorrections[i] = correction;
					i++;
				}

				nutrient.corrections = auxCorrections;

				if (nutrient.type === "deficiency") {
					hasDeficiencies = true;
				} else if (nutrient.type === "toxicity") {
					hasToxicities = true;
				}
			}
			const hasImportants = culture?.nutrientsImportantRemovel?.find(
				(elem: any) => elem.type === "important"
			);
			const hasRemovels = culture?.nutrientsImportantRemovel?.find(
				(elem: any) => elem.type === "removel"
			);

			if (userResponse) {
				if (userResponse.ok) {
					const location = userResponse.data.results?.locations?.find(
						(el: any) => el.country === user.country && el.city === user.city
					);
					countriesResponse.data.results.map((elem: any) => {
						if (location && elem.name === location.country) {
							elem.states.map((st: any) => {
								if (location?.state?.includes(st.name)) {
									culture?.fertilizing?.map((elem: any) =>
										elem.states.map((s: any) => {
											if (s === st.name) {
												productDestinations.push(elem.productionDestiny);
												elem?.expectedProduction?.map((el: any) =>
													expectProductions.push(el.production)
												);
											}
										})
									);
									this.setState({
										filters: {
											...filters,
											country: elem,
											state: st,
											destination: productDestinations[0],
										},
									});
								}
							});
						}
					});
				} else {
					dispatch(setLoader(false));
					return toast.error(userResponse.data?.message || Strings.serverErrors.title);
				}
			}

			let selectedTab = null;
			if (culture?.fertilizing.length) selectedTab = "fertilizing";
			else if (hasImportants) selectedTab = "importantNutrient";
			else if (hasRemovels) selectedTab = "extractNutrient";
			else if (hasDeficiencies || hasToxicities) selectedTab = "deficienciesAndToxicities";

			this.setState({
				culture,
				hasDeficiencies,
				hasToxicities,
				hasImportants,
				hasRemovels,
				selectedTab,
			});
		} else {
			dispatch(setLoader(false));
			return toast.error(cultureResponse.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	patchCultureFavorites = async () => {
		const { isFavorite } = this.state;
		const { user, dispatch, match } = this.props;

		dispatch(setLoader(true));
		if (user) {
			const response = await API.patch({
				url: Endpoints.uriUsers(`${user._id}/${match.params.id}`),
				data: {
					type: "cultures",
					action: isFavorite ? "remove" : "add",
				},
			});
			if (response.ok) {
				const user = response.data.results;
				dispatch(setUser({ ...user }));
				this.setState({
					isFavorite: user.cultures.find(
						(elem: any) => (elem._id || elem) === match.params.id
					),
				});
			}
		} else dispatch(push("/login"));

		dispatch(setLoader(false));
	};

	renderDeficienciesAndToxicities() {
		const { culture, hasDeficiencies, hasToxicities } = this.state;
		const { dispatch } = this.props;

		if (!hasDeficiencies && !hasToxicities) return <></>;

		return (
			<div style={{ padding: "10px" }}>
				{hasDeficiencies ? (
					<>
						<h2 className="Subtitle" style={{ marginBottom: 5 }}>
							{Strings.cultures.deficiencies}
						</h2>
						<Row gutter={24}>
							{culture?.nutrientsDeficiencyToxicity?.map((elem: any) => {
								if (elem.type !== "deficiency") return <></>;
								return (
									<Col md={12} className="NutrientsDeficiency" style={{ padding: '5px 12px 30px 12px' }}>
										<h3 className="NutrientsName">
											{translate(elem.nutrient.name)?.toUpperCase()}
											{formulaFormat(elem.nutrient.formula, elem.nutrient.isFormula)}
										</h3>

										{elem.symptoms.map((nutrient: any) => (
											<div className="Symptom">
												{nutrient.image && (
													<Image
														alt={translate(elem.nutrient.name)}
														className="SymptomImage"
														height={130}
														width={130}
														preview
														src={nutrient.image}
													/>
												)}

												<p style={!nutrient.image ? { marginLeft: 0 } : {}}>{translate(nutrient.description)}</p>
											</div>
										))}
										{elem.corrections.length ? (
											<div className="NutrientsCorrectionContainer">
												<div
													style={{
														color: "black",
														fontWeight: "bold",
													}}
												>
													{Strings.cultures.corrections}
												</div>
												{elem.corrections.map((el: any) => (
													<Row>
														{/* <Col md={24} xs={24} sm={24}>
															{el.product.logo ? (
																<div>
																	<img
																		style={{
																			maxWidth: "300px",
																			height: "50px",
																			cursor: "pointer",
																		}}
																		src={el.product.logo}
																		alt={translate(
																			el.product.name
																		)}
																		onClick={() =>
																			dispatch(
																				push(
																					`/products/${el.product._id}`,
																					{
																						backPath: `/cultures/${culture._id}`,
																					}
																				)
																			)
																		}
																	/>
																</div>
															) : (
																<div
																	style={{
																		// maxWidth: "300px",
																		height: "50px",
																		fontWeight: "bold",
																		fontSize: "34px",
																		color: el.product.color,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		dispatch(
																			push(
																				`/products/${el.product._id}`,
																				{
																					backPath: `/cultures/${culture._id}`,
																				}
																			)
																		)
																	}
																>
																	{translate(
																		el.product.name
																	)?.toUpperCase()}
																</div>
															)}
														</Col> */}
														{el.formulas.map((form: any) => translate(form?.name) ? (
															<Col
																lg={7}
																xs={11}
																sm={11}
																style={{ marginRight: "10px" }}
															>
																<div
																	className="CorrectionBox"
																	style={{
																		backgroundColor:
																			el.product.color,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		dispatch(
																			push(
																				`/formula/${form._id}`,
																				{
																					product:
																						el.product
																							._id,
																					backPath: `/cultures/${culture._id}`,
																				}
																			)
																		)
																	}
																>
																	{translate(form?.name)}
																</div>
															</Col>
														) : <></>)}
													</Row>
												))}
											</div>
										) : (
											<></>
										)}
									</Col>
								);
							})}
						</Row>
					</>
				) : (
					<></>
				)}
				{hasToxicities ? (
					<>
						<h2 className="Subtitle" style={{ marginBottom: 5 }}>
							{Strings.cultures.toxicities}
						</h2>
						<Row gutter={24}>
							{culture?.nutrientsDeficiencyToxicity?.map((elem: any) => {
								if (elem.type !== "toxicity") return <></>;
								return (
									<Col md={12} className="NutrientsDeficiency" style={{ padding: '5px 12px 30px 12px' }}>
										<h3 className="NutrientsName">
											{translate(elem.nutrient.name)?.toUpperCase()}
											{formulaFormat(elem.nutrient.formula, elem.nutrient.isFormula)}
										</h3>
										{elem.symptoms.map((nutrient: any) => (
											<div className="Symptom">
												{nutrient.image && (
													<Image
														alt={translate(elem.nutrient.name)}
														className="SymptomImage"
														height={130}
														width={130}
														preview
														src={nutrient.image}
													/>
												)}
												<p style={!nutrient.image ? { marginLeft: 0 } : {}}>{translate(nutrient.description)}</p>
											</div>
										))}
										{elem.corrections.length ? (
											<div className="NutrientsCorrectionContainer">
												<div
													style={{
														color: "black",
														fontWeight: "bold",
													}}
												>
													{Strings.cultures.corrections}
												</div>
												{elem.corrections.map((el: any) => (
													<Row>
														{/* <Col md={24} xs={24} sm={24}>
															{el.product.logo ? (
																<div>
																	<img
																		style={{
																			maxWidth: "300px",
																			height: "50px",
																			cursor: "pointer",
																		}}
																		src={el.product.logo}
																		alt={translate(
																			el.product.name
																		)}
																		onClick={() =>
																			dispatch(
																				push(
																					`/products/${el.product._id}`,
																					{
																						backPath: `/cultures/${culture._id}`,
																					}
																				)
																			)
																		}
																	/>
																</div>
															) : (
																<div
																	style={{
																		// maxWidth: "300px",
																		height: "50px",
																		fontWeight: "bold",
																		fontSize: "34px",
																		color: el.product.color,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		dispatch(
																			push(
																				`/products/${el.product._id}`,
																				{
																					backPath: `/cultures/${culture._id}`,
																				}
																			)
																		)
																	}
																>
																	{translate(
																		el.product.name
																	)?.toUpperCase()}
																</div>
															)}
														</Col> */}

														{el.formulas.map((form: any) => translate(form?.name) ? (
															<Col
																lg={7}
																xs={11}
																sm={11}
																style={{ marginRight: "10px" }}
															>
																<div
																	className="CorrectionBox"
																	style={{
																		backgroundColor:
																			el.product.color,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		dispatch(
																			push(
																				`/formula/${form._id}`,
																				{
																					product:
																						el.product
																							._id,
																					backPath: `/cultures/${culture._id}`,
																				}
																			)
																		)
																	}
																>
																	{translate(form?.name)}
																</div>
															</Col>
														) : <></>)}
													</Row>
												))}
											</div>
										) : (
											<></>
										)}
									</Col>
								);
							})}
						</Row>
					</>
				) : (
					<></>
				)}
			</div>
		);
	}

	renderImportantNutrient() { 
		const { culture, hasImportants } = this.state;

		if (!hasImportants) return <></>;

		return (
			<div style={{ padding: "10px" }}>
				<Row gutter={24}>
					<Col xs={24}>
						<h2 className="Subtitle">{Strings.cultures.mostImportantNutrients}</h2>
						{culture?.nutrientsImportantRemovel?.map((elem: any) => {
							if (elem.type !== "important") return <></>;
							return (
								<div className="NutrientsDeficiency" style={{ padding: '5px 12px 30px 12px' }}>
									<h3 className="NutrientsName">
										{translate(elem.nutrient.name)}
										{formulaFormat(elem.nutrient.formula, elem.nutrient.isFormula)}
									</h3>
									<span style={!translate(elem.text) ? { color: "grey" } : {}}>
										{translate(elem.text) ||
											Strings.cultures.hasNoNutrientDescription}
									</span>
								</div>
							);
						})}
					</Col>
				</Row>
			</div>
		);
	}

	renderSoilFertilization() {
		const { culture, filters } = this.state;
		if (!culture.fertilizing.length) return <></>;

		return (
			<div style={{ padding: "10px" }}>
				<h2 className="Subtitle">{Strings.cultures.fertilizing}</h2>
				<Row gutter={24}>
					<Col md={2} style={{ marginRight: "50px" }}>
						<div className="NutrientsDeficiency">
							<h3 className="FiltersTitle">{Strings.fields.country}</h3>
							<span>
								{filters.country?.name === "" ||
								Object.keys(filters.country).length === 0
									? "-"
									: filters.country.name}
							</span>
						</div>
					</Col>
					<Col md={2} style={{ marginRight: "50px" }}>
						<div className="NutrientsDeficiency">
							<h3 className="FiltersTitle">{Strings.fields.state}</h3>
							<span>
								{filters.state?.name === "" ||
								Object.keys(filters.state).length === 0
									? "-"
									: filters.state?.name}
							</span>
						</div>
					</Col>
				</Row>
				<Col md={8}>
					<button
						className="ChangeFiltersButton"
						onClick={() =>
							this.setState({
								openSidebar: "true",
								item: { filters },
							})
						}
					>
						{Strings.generic.changeData}
					</button>
				</Col>
				<hr style={{ opacity: 0.4 }}></hr>
				<Row>
					<h4 style={{ opacity: 0.8, fontWeight: "bold" }}>{Strings.fields.results}</h4>
				</Row>
				<Row>
					{culture.soilFertilization.map((elem: any) =>
						elem.country.name === filters.country.name ? (
							elem.states.map((el: any) =>
								el.state === filters.state?.name ? (
									el.soilNutrients.map((nut: any) => (
										<>
											<Col
												xs={24}
												style={{ padding: "10px" }}
											>
												<Row>
													<Col md={12} sm={12} xs={12}>
														<div className="NutrientsDeficiency">
															<h3 className="FiltersTitle">
																{Strings.cultures.soilNutrient}
															</h3>
															<span>
																{translate(
																	nut.soilNutrient.name
																) === "" || !nut.soilNutrient
																	? "-"
																	: translate(
																			nut.soilNutrient.name
																	  )}
															</span>
														</div>
													</Col>
													<Col md={12} sm={12} xs={12}>
														<div className="NutrientsDeficiency">
															<h3 className="FiltersTitle">
																{Strings.cultures.lessFrequent}
															</h3>
															<span>
																{translate(
																	nut.lessFrequent.description
																) === "" || !nut.lessFrequent
																	? "-"
																	: translate(
																			nut.lessFrequent
																				.description
																	  ) +
																	  " " +
																	  nut.lessFrequent.value +
																	  "%"}
															</span>
														</div>
													</Col>
												</Row>
												<Row>
													<Col md={12} sm={12} xs={12}>
														<div className="NutrientsDeficiency">
															<h3 className="FiltersTitle">
																{Strings.cultures.frequent}
															</h3>
															<span>
																{translate(
																	nut.frequent.description
																) === "" || !nut.frequent
																	? "-"
																	: translate(
																			nut.frequent.description
																	  ) +
																	  " " +
																	  nut.frequent.value +
																	  "%"}
															</span>
														</div>
													</Col>
													<Col md={12} sm={12} xs={12}>
														<div className="NutrientsDeficiency">
															<h3 className="FiltersTitle">
																{Strings.cultures.veryFrequent}
															</h3>
															<span>
																{translate(
																	nut.veryFrequent.description
																) === "" || !nut.veryFrequent
																	? "-"
																	: translate(
																			nut.veryFrequent
																				.description
																	  ) +
																	  " " +
																	  nut.veryFrequent.value +
																	  "%"}
															</span>
														</div>
													</Col>
												</Row>
												<hr style={{ opacity: 0.4 }}></hr>
											</Col>
										</>
									))
								) : (
									<></>
								)
							)
						) : (
							<></>
						)
					)}
				</Row>
				{this.renderSidebar()}
			</div>
		);
	}

	renderExtractNutrients() { 
		const { culture, hasRemovels } = this.state;

		if (!hasRemovels) return <></>;

		return (
			<div style={{ padding: "10px" }}>
				<Row gutter={24}>
					{hasRemovels ? (
						<Col xs={24} md={10}>
							<h2 className="Subtitle">{Strings.cultures.removelNutrients}</h2>
							<span>{translate(culture.nutrientsRemovelObservation) || ""}</span>
							{culture?.nutrientsImportantRemovel?.map((elem: any) => {
								if (elem.type !== "removel") return <></>;
								return (
									<div className="NutrientsToxicity" style={{ padding: '5px 12px 30px 12px' }}>
										<h3 className="NutrientsName">
											{translate(elem.nutrient.name)}
											{formulaFormat(elem.nutrient.formula, elem.nutrient.isFormula)}
										</h3>
										<span
											style={!translate(elem.text) ? { color: "grey" } : {}}
										>
											{translate(elem.text) ||
												Strings.cultures.hasNoNutrientDescription}
										</span>
									</div>
								);
							})}
						</Col>
					) : (
						<></>
					)}
				</Row>
			</div>
		);
	}

	onCloseSidebar = () => {
		this.setState({ openSidebar: null });
	};

	onCloseTypeSidebar = () => {
		this.setState({ sidebarType: null });
	};

	onSubmitSidebar = async () => {
		const { item } = this.state;
		await this.setState({
			filters: item.filters,
		});

		this.onCloseSidebar();
	};

	renderTitleSwitch(type: any) {
		switch (type) {
			case "country":
				return (
					<div style={{ cursor: "default" }}>
						<b>{Strings.fields.country?.toUpperCase()}</b>
						<h5 style={{ color: "white" }}>{Strings.fields.chooseCountry}</h5>
					</div>
				);
			case "state":
				return (
					<div style={{ cursor: "default" }}>
						<b>{Strings.fields.state.toUpperCase()}</b>
						<h5 style={{ color: "white" }}>{Strings.fields.chooseState}</h5>
					</div>
				);
			case "county":
				return (
					<div style={{ cursor: "default" }}>
						<b>{Strings.fields.county?.toUpperCase()}</b>
						<h5 style={{ color: "white" }}>{Strings.fields.chooseCounty}</h5>
					</div>
				);
			case "destination":
				return (
					<div style={{ cursor: "default" }}>
						<b>{Strings.cultures.destination?.toUpperCase()}</b>
						<h5 style={{ color: "white" }}>{Strings.cultures.destination}</h5>
					</div>
				);
			case "expectedProduction":
				return (
					<div style={{ cursor: "default" }}>
						<b>{Strings.cultures.expectedProduction?.toUpperCase()}</b>
						<h5 style={{ color: "white" }}>{Strings.cultures.expectedProduction}</h5>
					</div>
				);
			default:
				return;
		}
	}

	renderTypeSidebar() {
		const { sidebarType } = this.state;

		return (
			<>
				{sidebarType === "country" ||
				sidebarType === "state" ||
				sidebarType === "destination" ||
				sidebarType === "expectedProduction" ? (
					<>
						<EditSidebar
							title={
								<div>
									<div
										onClick={() => {
											this.onCloseTypeSidebar();
										}}
										style={{ cursor: "pointer", marginBottom: "15px" }}
									>
										<Icon
											name="back"
											style={{
												transform: "rotate(180deg)",
												marginRight: "8px",
											}}
										/>
										{Strings.generic.back}
									</div>

									{this.renderTitleSwitch(sidebarType)}
								</div>
							}
							open={this.state.openSidebar}
							onClose={this.onCloseTypeSidebar}
							//onChange={this.onChangeSidebar}
							extraFields={() => this.renderFieldsSwitch(sidebarType)}
							hideFooter={true}
							hasFields={true}
							fields={[
								{},
								{
									version: DateTime.local(),
								},
							]}
							closable={false}
						></EditSidebar>
					</>
				) : (
					<></>
				)}
			</>
		);
	}

	renderExtraFieldsCountrySidebar() {
		const { item, countries, destination, language } = this.state;
		return (
			<>
				{countries.map((elem: any) => (
					<div
						className={`LocationButton ${item?.filters?.country?.name === elem.name}`}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
						onClick={() => {
							const country = countries.find((c: any) => {
								return c.name === elem.name;
							});
							this.setState({
								item: {
									...item,
									filters: {
										...item.filters,
										country,
										state: "",
										destination: { ...destination, [language]: "" },
										expectedProduction: "",
									},
								},
							});

							this.onCloseTypeSidebar();
						}}
					>
						{elem.name ? `${elem.name}` : ""}
					</div>
				))}
			</>
		);
	}

	renderExtraFieldsStateSidebar() {
		const { item, destination, language } = this.state;
		return (
			<>
				{item.filters?.country.states?.map((elem: any) => (
					<div
						className={`LocationButton ${item?.filters?.state?.name === elem.name}`}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
						onClick={() => {
							const statesList = item?.filters?.country?.states;

							const state = statesList.find((c: any) => {
								return c.name === elem.name || c.name === item?.filters?.state;
							});

							this.setState({
								item: {
									...item,
									filters: {
										...item.filters,
										state,
										destination: { ...destination, [language]: "" },
										expectedProduction: "",
									},
								},
							});
							this.onCloseTypeSidebar();
						}}
					>
						{elem.name ? `${elem.name}` : ""}
					</div>
				))}
			</>
		);
	}

	renderExtraFieldsDestinationSidebar() {
		const { item, culture } = this.state;
		let destinations: any[] = [];
		culture?.fertilizing?.map((elem: any) =>
			elem.states.map((st: any) => {
				if (st === item.filters?.state?.name) {
					destinations.push(elem.productionDestiny);
				}
			})
		);
		return (
			<>
				{destinations?.map((elem: any) => (
					<div
						className={`LocationButton ${item?.filters?.destination === elem}`}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
						onClick={() => {
							const destination = destinations.find((c: any) => {
								return c === elem;
							});
							this.setState({
								item: {
									...item,
									filters: {
										...item.filters,
										destination,
										expectedProduction: "",
									},
								},
							});
							this.onCloseTypeSidebar();
						}}
					>
						{translate(elem)}
					</div>
				))}
			</>
		);
	}

	renderExtraFieldsExpectedProductionSidebar() {
		const { item, culture, language } = this.state;
		let expectedProductions: any[] = [];
		culture?.fertilizing?.map((elem: any) =>
			elem.states.map((st: any) => {
				if (st === item.filters?.state?.name) {
					if (elem.productionDestiny[language] === item.filters.destination[language])
						elem?.expectedProduction?.map((el: any) =>
							expectedProductions.push(el.production)
						);
				}
			})
		);
		return (
			<>
				{expectedProductions?.map((elem: any) => (
					<div
						className={`LocationButton ${item?.filters?.expectedProduction === elem}`}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
						onClick={() => {
							const expectedProduction = expectedProductions.find((c: any) => {
								return c === elem;
							});
							this.setState({
								item: {
									...item,
									filters: {
										...item.filters,
										expectedProduction,
										usualFertilizing: false,
									},
								},
							});
							this.onCloseTypeSidebar();
						}}
					>
						{elem ? `${elem}` : ""}
					</div>
				))}
			</>
		);
	}

	renderFieldsSwitch(type: any) {
		switch (type) {
			case "country":
				return this.renderExtraFieldsCountrySidebar();
			case "state":
				return this.renderExtraFieldsStateSidebar();
			case "destination":
				return this.renderExtraFieldsDestinationSidebar();
			case "expectedProduction":
				return this.renderExtraFieldsExpectedProductionSidebar();
			default:
				return;
		}
	}

	renderSidebarSwitch(selectedTab: any) {
		switch (selectedTab) {
			case "soilFertilization":
				return this.renderSoilFiltersFields();
			case "fertilizing":
				return this.renderFiltersFields();
			default:
				return;
		}
	}

	renderFiltersFields = () => {
		const { filters, item } = this.state;
		return (
			<>
				<div
					className="ProfileOptionButton"
					onClick={() => this.setState({ sidebarType: "country" })}
				>
					<TextField
						label={Strings.fields.country}
						type="text"
						value={item.filters?.country.name}
						className="ProfileInputClass CultureTextField"
						placeholder={Strings.promoters?.chooseCountry}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Icon name="back" className="backButton" />
				</div>
				<div
					className="ProfileOptionButton"
					onClick={() => this.setState({ sidebarType: "state" })}
				>
					<TextField
						label={Strings.fields.state}
						type="text"
						value={item.filters?.state?.name || ""}
						className="ProfileInputClass CultureTextField"
						placeholder={Strings.promoters?.chooseState}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Icon name="back" className="backButton" />
				</div>
				<div
					className="ProfileOptionButton"
					onClick={() => this.setState({ sidebarType: "destination" })}
				>
					<TextField
						label={Strings.cultures.destination}
						type="text"
						value={translate(item.filters?.destination)}
						className="ProfileInputClass CultureTextField"
						placeholder={Strings.cultures.chooseDestination}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Icon name="back" className="backButton" />
				</div>
				<div
					className="ProfileOptionButton"
					onClick={() => !item.filters?.usualFertilizing && this.setState({ sidebarType: "expectedProduction" })}
				>
					<TextField
						label={Strings.cultures.expectedProduction}
						type="text"
						value={item.filters?.expectedProduction}
						disabled
						className="ProfileInputClass CultureTextField"
						placeholder={Strings.cultures.chooseExpectedProduction}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Icon name="back" className="backButton" />
				</div>
				<div
					className={`General_ColorFul_Switch ${
						item.filters?.usualFertilizing ? "__active" : ""
					}`}
					style={{
						fontSize: 14,
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					{Strings.cultures.usualFertilizing}
					<Switch
						className={`Switch ${item?.filters?.usualFertilizing ? "__active" : ""}`}
						checked={item?.filters?.usualFertilizing || false}
						size="default"
						onChange={(value) => {
							value
								? this.setState({
										filters: {
											...filters,
											expectedProduction: "Consoante Adubação Habitual",
										},
										item: {
											...item,
											filters: {
												...item.filters,
												expectedProduction: "Consoante Adubação Habitual",
												usualFertilizing: true,
											},
										},
								  })
								: this.setState({
										filters: {
											...filters,
											expectedProduction: "",
										},
										item: {
											...item,
											filters: {
												...item.filters,
												expectedProduction: "",
												usualFertilizing: false,
											},
										},
								  });
						}}
					/>
				</div>
				{this.renderTypeSidebar()}
			</>
		);
	};

	renderSidebar() {
		const { selectedTab } = this.state;
		return (
			<>
				<EditSidebar
					title={
						<div style={{ cursor: "default" }}>
							<b>{Strings.cultures.fertilizingFilters}</b>
						</div>
					}
					open={this.state.openSidebar}
					onClose={this.onCloseSidebar}
					// onChange={this.onChangeSidebar}
					onSubmit={this.onSubmitSidebar}
					defaultValue={this.state.item}
					closable={false}
					hasFields={true}
					extraFields={() => this.renderSidebarSwitch(selectedTab)}
				/>
			</>
		);
	}

	renderSoilFiltersFields = () => {
		const { item } = this.state;
		return (
			<>
				<div
					className="ProfileOptionButton"
					onClick={() => this.setState({ sidebarType: "country" })}
				>
					<TextField
						label={Strings.fields.country}
						type="text"
						value={item.filters?.country.name}
						className="ProfileInputClass CultureTextField"
						placeholder={Strings.promoters?.chooseCountry}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Icon name="back" className="backButton" />
				</div>
				<div
					className="ProfileOptionButton"
					onClick={() => this.setState({ sidebarType: "state" })}
				>
					<TextField
						label={Strings.fields.state}
						type="text"
						value={item.filters?.state?.name}
						className="ProfileInputClass CultureTextField"
						placeholder={Strings.promoters?.chooseState}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Icon name="back" className="backButton" />
				</div>

				{this.renderTypeSidebar()}
			</>
		);
	};

	renderFertilizing() {
		const { culture, filters, countries, isMobile } = this.state;
		const { dispatch, match } = this.props;
		if (!culture?.fertilizing.length) return <></>;

		let destinations: any[] = [];
		culture?.fertilizing?.map((elem: any) =>
			elem.states.map((st: any) => {
				if (st === filters?.state?.name) {
					destinations.push(elem.productionDestiny);
				}
			})
		);

		return (
			<div style={{ padding: "10px" }}>
				<h2 className="Subtitle">{Strings.cultures.fertilizing}</h2>
				<Row gutter={24}>
					<Col md={4} style={{ marginRight: "50px" }}>
						<div className="NutrientsDeficiency">
							<h3 className="FiltersTitle">{Strings.fields.country}</h3>
							<Select
								key={filters.country}
								style={{ width: 150 }}
								optionFilterProp="children"
								placeholder={Strings.fields.country}
								onChange={(country) => this.setState({
									filters: { ...filters, country: countries.find((elem: any) => elem._id === country), state: null }
								})}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								defaultValue={filters.country.name}>
								{countries.map((country: any, index: number) => {
								  return (
									<Option
										key={`select_option_${country}_${index}`}
										value={country._id}
									>
										{translate(country.translations)}
									</Option>
								  );
								})}
							</Select>
						</div>
					</Col>
					<Col md={4} style={{ marginRight: "50px" }}>
						<div className="NutrientsDeficiency">
							<h3 className="FiltersTitle">{Strings.fields.state}</h3>
							<Select
								key={filters.state}
								style={{ width: 150 }}
								optionFilterProp="children"
								placeholder={Strings.fields.state}
								onChange={(state) => this.setState({
									filters: { ...filters, state: filters?.country?.states?.find((elem: any) => elem._id === state), destination: null }
								})}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								defaultValue={filters.state?.name}>
								{filters?.country?.states?.map((state: any, index: number) => {
								  return (
									<Option
									  key={`select_option_${state}_${index}`}
									  value={state._id}
									>
									  {state.name}
									</Option>
								  );
								})}
							</Select>
						</div>
					</Col>
					<Col md={8} style={{ marginRight: "50px" }}>
						<div className="NutrientsDeficiency">
							<h3 className="FiltersTitle">{Strings.cultures.destination}</h3>
							<Select
								key={filters.destination}
								style={{ width: 150 }}
								optionFilterProp="children"
								placeholder={Strings.cultures.destination}
								onChange={(index) => this.setState({
									filters: { ...filters, destination: destinations[index] }
								})}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								defaultValue={filters.destination ? translate(filters.destination) : null}>
								{destinations.map((destination: any, index: number) => {
									return (
										<Option
											key={`select_option_${destination}_${index}`}
											value={index}
											>
											{translate(destination)}
										</Option>
									);
								})}
							</Select>
						</div>
					</Col>
					{/* <Col md={4} style={{ marginRight: "50px" }}>
						<div className="NutrientsDeficiency">
							<h3 className="FiltersTitle">{Strings.cultures.expectedProduction}</h3>
							<span>
								{filters.expectedProduction === "Consoante Adubação Habitual" ||
								filters.expectedProduction === ""
									? "-"
									: filters.expectedProduction}
							</span>
						</div>
					</Col>
					<Col md={3} style={{ marginRight: "50px" }}>
						<div className="NutrientsDeficiency">
							<h3 className="FiltersTitle">{Strings.cultures.usualFertilizing}</h3>
							<Switch
								className={`Switch ${
									filters.usualFertilizing ? "__active" : ""
								}`}
								checked={!!filters.usualFertilizing}
								size="default"
								onChange={(value) => this.setState({
									filters: { ...filters, usualFertilizing: value }
								})}
							/>
						</div>
					</Col> */}
				</Row>
				{/* <Col md={8}>
					<button
						className="ChangeFiltersButton"
						onClick={() =>
							this.setState({
								openSidebar: "true",
								item: { filters },
							})
						}
					>
						{filters.country?._id && filters.state?._id &&
							(filters.expectedProduction || filters.usualFertilizing) ?
							Strings.generic.changeData :
							Strings.generic.selectData}
					</button>
				</Col> */}
				<hr style={{ opacity: 0.4 }}></hr>
				<Row>
					<h4 style={{ opacity: 0.8, fontWeight: "bold" }}>{Strings.fields.results}</h4>
				</Row>
				<Row>
					{culture?.fertilizing.map((elem: any) =>
						elem.country.name === filters.country.name ||
						elem.country.name === filters.country ? (
							elem.states.map((st: any) =>
								st === filters.state?.name || st === filters.state ? (
									translate(elem.productionDestiny) ===
									translate(filters.destination) ? (
										filters.usualFertilizing ? (
											elem.usualProduction.doses.map((el: any) => {
												const formula = el.product?.formulas?.find(
													(formula: any) =>
														el.formula ===
														formula._id
												)
												return (
													<Col sm={24} style={{ padding: "10px" }}>
														{/* <Row>
															{el?.product?.logo ? (
																<img
																	src={el.product.logo}
																	alt={el.product.name}
																	style={{
																		width: "auto",
																		maxWidth: "220px",
																		maxHeight: "40px",
																		height: "auto",
																	}}
																></img>
															) : (
																<div
																	style={{
																		width: "auto",
																		fontWeight: "bold",
																		fontSize: "34px",
																		color: el.product.color,
																	}}
																>
																	{translate(el.product.name)}
																</div>
															)}
														</Row> */}
														<Row>
															<Col md={12} xs={12}>
																<div className="NutrientsDeficiency">
																	<h3 className="FiltersTitle">
																		{
																			Strings.cultures
																				.fertilizingType
																		}
																	</h3>
																	<span>
																		{translate(
																			el.fertilizingType?.name
																		) || ""}
																	</span>
																</div>
															</Col>
															<Col md={12} xs={12}>
																<div className="NutrientsDeficiency">
																	<h3 className="FiltersTitle">
																		{Strings.products.formulation}
																	</h3>
																	{formula ? <div
																		className="CorrectionBox"
																		style={{
																			backgroundColor:
																				el.product.color,
																			cursor: "pointer",
																			maxWidth: 300,
																		}}
																		onClick={() =>
																			dispatch(
																				push(
																					`/formula/${formula._id}`,
																					{
																						product: el.product._id,
																						backPath: `/cultures/${culture._id}`,
																					}
																				)
																			)
																		}
																	>
																		{translate(formula.name)}
																	</div> : '-'}
																</div>
															</Col>
															<Col md={12} xs={12}>
																<div className="NutrientsDeficiency">
																	<h3 className="FiltersTitle">
																		{Strings.products.season}
																	</h3>
																	<span>
																		{translate(el.season) === "" ||
																		!el.season
																			? "-"
																			: translate(el.season)}
																	</span>
																</div>
															</Col>
															{translate(el.dose) && <Col md={12} xs={12}>
																<div className="NutrientsDeficiency">
																	<h3 className="FiltersTitle">
																		{Strings.products.dose}
																	</h3>
																	<span>
																		{translate(el.dose) === "" ||
																		!el.dose
																			? ""
																			: translate(el.dose)}
																	</span>
																</div>
															</Col>}
															<Col md={12} xs={12}>
																<div className="NutrientsDeficiency">
																	<h3 className="FiltersTitle">
																		{el.maxConcentration ? Strings.products.maxConcentration : ''}
																	</h3>
																	<span>
																		{el.maxConcentration || ''}
																	</span>
																</div>
															</Col>
															<Col md={24} xs={24}>
																<div className="NutrientsDeficiency">
																	<h3 className="FiltersTitle">
																		{el.observations ? Strings.products.observation : ''}
																	</h3>
																	<span>
																		{translate(el.observations)}
																	</span>
																</div>
															</Col>
														</Row>
														<hr style={{ opacity: 0.4 }}></hr>
													</Col>
												)
											})
										) : (
											elem.expectedProduction.map((pro: any) =>
												pro.production === filters.expectedProduction ? (
													pro?.doses.map((el: any) => (
														<Col
															md={12}
															sm={24}
															style={{ padding: "10px" }}
														>
															{/* <Row>
																{el?.product?.logo ? (
																	<img
																		src={el.product.logo}
																		alt={el.product.name}
																		style={{
																			width: "auto",
																			maxWidth: "220px",
																			maxHeight: "40px",
																			height: "auto",
																		}}
																	></img>
																) : (
																	<div
																		style={{
																			width: "auto",
																			fontWeight: "bold",
																			fontSize: "34px",
																			color: el.product
																				.color,
																		}}
																	>
																		{translate(
																			el.product.name
																		)}
																	</div>
																)}
															</Row> */}
															<Row>
																<Col md={12} sm={12}>
																	<div className="NutrientsDeficiency">
																		<h3 className="FiltersTitle">
																			{
																				Strings.cultures
																					.fertilizingType
																			}
																		</h3>
																		<span>
																			{translate(
																				el.fertilizingType
																					?.name
																			) || ""}
																		</span>
																	</div>
																</Col>
																<Col md={12} sm={12}>
																	<div className="NutrientsDeficiency">
																		<h3 className="FiltersTitle">
																			{
																				Strings.products
																					.formulation
																			}
																		</h3>
																		<span>
																			{
																				translate(el.product?.formulas?.find(
																					(
																						formula: any
																					) =>
																						el.formula ===
																						formula._id
																				)?.name)
																			}
																		</span>
																	</div>
																</Col>
																<Col md={12} sm={12}>
																	<div className="NutrientsDeficiency">
																		<h3 className="FiltersTitle">
																			{
																				Strings.products
																					.season
																			}
																		</h3>
																		<span>
																			{translate(
																				el.season
																			) === "" || !el.season
																				? "-"
																				: translate(
																						el.season
																				  )}
																		</span>
																	</div>
																</Col>
																<Col md={12} sm={12}>
																	<div className="NutrientsDeficiency">
																		<h3 className="FiltersTitle">
																			{Strings.products.dose}
																		</h3>
																		<span>
																			{translate(
																				el.dose
																			) === "" || !el.dose
																				? "-"
																				: translate(
																						el.dose
																				  )}
																		</span>
																	</div>
																</Col>
																<Col md={12} sm={12}>
																	<div className="NutrientsDeficiency">
																		<h3 className="FiltersTitle">
																			{el.maxConcentration ? Strings.products.maxConcentration : ''}
																		</h3>
																		<span>
																			{el.maxConcentration || ''}
																		</span>
																	</div>
																</Col>
																<Col md={24} sm={24}>
																	<div className="NutrientsDeficiency">
																		<h3 className="FiltersTitle">
																			{el.observations ? Strings.products.observation : ''}
																		</h3>
																		<span>
																			{translate(el.observations)}
																		</span>
																	</div>
																</Col>
															</Row>
															<hr style={{ opacity: 0.4 }}></hr>
														</Col>
													))
												) : (
													<></>
												)
											)
										)
									) : (
										<></>
									)
								) : (
									<></>
								)
							)
						) : (
							<></>
						)
					)}
				</Row>

				{this.renderSidebar()}
			</div>
		);
	}

	render() {
		const { culture, selectedTab, isMobile, isFavorite, hasRemovels, hasImportants } =
			this.state;
		const { dispatch } = this.props;

		const settings = {
			dots: isMobile ? true : false,
			arrows: isMobile ? false : true,
			infinite: culture?.products?.length > 2,
			// autoplay: true,
			slidesToShow: isMobile ? 1 : 2,
			slidesToScroll: 1,
			speed: 500,
			autoplaySpeed: 5000,
			className: "ProductsSlider",
			prevArrow: (
				<button type="button" className="slick-arrow">
					<em className="moon-baixa" style={{ transform: "rotate(90deg)" }} />
				</button>
			),
			nextArrow: (
				<button type="button" className="slick-arrow">
					<em className="moon-baixa" style={{ transform: "rotate(-90deg)" }} />
				</button>
			),
		};

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.cultures}</title>
					<meta name="description" content="Description of Culture" />
				</Helmet>
				<div className="PageHeader">
					<div
						className="PageHeaderTitle"
						onClick={() => dispatch(push("/cultures"))}
						style={{ cursor: "pointer" }}
					>
						<Icon name="back" style={{ fontWeight: "normal" }} />
						<div>{translate(culture?.name)}</div>
					</div>
					<Icon
						name={isFavorite ? "star-full" : "star-empty"}
						style={{ cursor: "pointer", fontSize: 34 }}
						onClick={() => this.patchCultureFavorites()}
					/>
				</div>
				{culture?.imageLandscape ? (
					<div
						className="CultureBackgroundImage"
						style={{ backgroundImage: `url(${culture?.imageLandscape})` }}
					/>
				) : (
					<></>
				)}
				<div className="CultureContent MobileContainer">
					<div
						className="CultureSelectables"
						style={
							!culture?.products || !culture.products.length
								? { flex: "0 0 100%", maxWidth: "100%" }
								: {}
						}
					>
						<SelectableTabs
							allWidthTabs
							selectedTab={selectedTab}
							tabs={[
								{
									key: "fertilizing",
									title: Strings.cultures.fertilizing,
									disabled: !culture?.fertilizing || !culture?.fertilizing.length,
									onClick: () => this.setState({ selectedTab: "fertilizing" }),
									renderScreen: () => this.renderFertilizing(),
								},
								{
									key: "importantNutrient",
									title: Strings.cultures.nutrientsImportantRemovel,
									disabled:
										!hasImportants ||
										!culture?.nutrientsImportantRemovel.length,
									onClick: () =>
										this.setState({ selectedTab: "importantNutrient" }),
									renderScreen: () => this.renderImportantNutrient(),
								},
								{
									key: "extractNutrient",
									title: Strings.cultures.extractNutrients,
									disabled:
										!hasRemovels || !culture?.nutrientsImportantRemovel.length,
									onClick: () =>
										this.setState({ selectedTab: "extractNutrient" }),
									renderScreen: () => this.renderExtractNutrients(),
								},
								{
									key: "deficienciesAndToxicities",
									title: Strings.cultures.deficienciesAndToxicities,
									disabled:
										!culture?.nutrientsDeficiencyToxicity ||
										!culture?.nutrientsDeficiencyToxicity.length,
									onClick: () =>
										this.setState({ selectedTab: "deficienciesAndToxicities" }),
									renderScreen: () => this.renderDeficienciesAndToxicities(),
								},
								// {
								// 	key: "soilFertilization",
								// 	title: Strings.cultures.soilFertilization,
								// 	disabled:
								// 		!culture?.soilFertilization ||
								// 		!culture?.soilFertilization.length,
								// 	onClick: () =>
								// 		this.setState({ selectedTab: "soilFertilization" }),
								// 	renderScreen: () => this.renderSoilFertilization(),
								// },
							]}
						/>
						{!selectedTab ? (
							<div style={{ padding: "10px" }}>{Strings.generic.noContent}</div>
						) : (
							<></>
						)}
					</div>
					{culture?.products && culture.products.length ? (
						<div className="ProductsColumn">
							<div className="Subtitle">{Strings.sidebar.products}</div>
							<div style={{ marginBottom: 15 }}>{Strings.cultures.products}</div>
							<Slider {...settings}>
								{culture.products.map((product: any) => {
									return <ProductCard data={product} main noDescription />;
								})}
							</Slider>
						</div>
					) : null}
					{/* <div className="ProductsColumn">
				<div className="Subtitle">{Strings.sidebar.products}</div>
				<div style={{ marginBottom: 15 }}>
					{culture?.products && culture.products.length
						? Strings.cultures.products
						: Strings.cultures.hasNoProducts}
				</div>
				{culture?.products && culture.products.length ? (
					<Slider {...settings}>
						{culture.products.map((product: any) => {
							return <ProductCard data={product} main noDescription />;
						})}
					</Slider>
					) : (
						<></>
					)}
			</div> */}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user,
	language: state.language || "pt",
});

export default connect(mapStateToProps)(Culture);
