// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer_Container{display:flex;justify-content:center;width:100%;z-index:50}.Footer_Container .Footer{background-color:#7d878c;color:#fff;width:100%;padding:50px 40px}@media screen and (max-width: 768px){.Footer_Container .Footer{padding:40px 20px;overflow:hidden}}.Footer_Container .Footer .Footer_Logo{max-width:250px}@media screen and (max-width: 991px){.Footer_Container .Footer .Footer_Logo{display:flex;justify-content:center;object-fit:contain}.Footer_Container .Footer .Footer_Logo img{max-width:250px}}@media screen and (max-width: 600px){.Footer_Container .Footer .Footer_Logo{max-width:150px;margin-left:auto;margin-right:auto;display:block}}.Footer_Container .Footer .Footer_Logo img{width:100%}@media screen and (max-width: 600px){.Footer_Container .Footer .Footer_Logo img{object-fit:contain}}.Footer_Container .Footer .Footer_Menu{display:flex;flex-direction:column}.Footer_Container .Footer .Footer_Menu.__alignRight{align-items:flex-end;text-align:right}@media screen and (max-width: 991px){.Footer_Container .Footer .Footer_Menu.__alignRight{align-items:flex-start;text-align:left}}.Footer_Container .Footer .Footer_Menu .Footer_Menu_Title{font-size:20px;font-weight:700;margin-bottom:15px}@media screen and (max-width: 768px){.Footer_Container .Footer .Footer_Menu .Footer_Menu_Title{font-size:20px}}.Footer_Container .Footer .Footer_Menu .Footer_Menu_Item{font-size:16px;color:#fff}@media screen and (max-width: 768px){.Footer_Container .Footer .Footer_Menu .Footer_Menu_Item{font-size:15px}}.Footer_Container .Footer .Footer_Menu .Footer_Menu_Item:not(:last-child){margin-bottom:10px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
