// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoticeContent{min-height:calc(100vh - 312px);padding:25px}.NoticeContent .NoticeTitle{font-weight:bold;font-size:22px}.NoticeContent .ModifiedDate{color:#959fa3}.NoticeContent .NoticeBody{margin-top:20px}.NoticeBackgroundImage{height:20vw;background-repeat:no-repeat;background-size:cover;background-position:center}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
