/*
 *
 * Home
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setUser, setLoader } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { toast, translate, ANDROID_LINK, APPLE_LINK } from "utils/utils";
import { Weather, ProductCard, CultureCard, Icon } from "components";
import { Button } from "antd";
import Slider from "react-slick";
import deficienciesBackground from "../../assets/images/backgrounds/carencias.jpg";
import { API, Endpoints } from "utils/api";
import downloadApple from "assets/images/appstore.png";
import downloadAndroid from "assets/images/playstore.png";
import weatherBackground from "assets/images/backgrounds/weather.png";
import Strings from "utils/strings";
import "./styles.scss";


export class Home extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			products: [],
			cultures: [],
			isLaptop: window.innerWidth < 1280,
			isMobile: window.innerWidth <= 920,
			showOneCulture: window.innerWidth <= 768,
			IPhone: window.innerWidth <= 600,
			apiLoading: true,
		};

		this.handleResize = this.handleResize.bind(this);
		window.addEventListener("resize", this.handleResize);
	}

	componentDidMount() {
		this.getData();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		this.setState({
			isLaptop: window.innerWidth < 1280,
			isMobile: window.innerWidth <= 920,
			showOneCulture: window.innerWidth <= 768,
			IPhone: window.innerWidth <= 600,
		});
	}

	getData = async () => {
		const { dispatch, user } = this.props;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriUsers("home"),
		});

		if (response.ok) {
			const data = response.data.results;
			if (user?._id)
				dispatch(
					setUser({
						...user,
						city: data.weather.city,
						country: data.weather.country,
					})
				);

			this.setState({ ...data, apiLoading: false });
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	setLocation = async (position: any) => {
		const { user, dispatch } = this.props;
		const location = {
			coordinates: {
				type: "Point",
				coordinates: [position.coords.latitude, position.coords.longitude],
			},
		};

		if (!user) return;

		dispatch(setLoader(true));

		// this.setState({ location })
		const response = await API.post({
			url: Endpoints.uriUsers(`${user._id}/location`),
			data: { ...location, default: true },
		});

		if (response.ok) {
			// this.setState({ ...response.data.results });
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
		dispatch(setLoader(false));
	};

	renderNewsSection() {
		const { news } = this.state;
		const { dispatch } = this.props;

		const settings = {
			dots: true,
			arrows: false,
			infinite: news?.length,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			autoplaySpeed: 5000,
			className: "slides",
		};

		return (
			<>
				{news && news.length ? (
					<Slider {...settings}>
						{news.map((notice: any) => {
							return (
								<div>
									<div
										className="NewsContainer"
										style={{ backgroundImage: `url(${notice.image})` }}
									>
										{/* <div className="News">
											{Strings.news.title.toUpperCase()}
										</div> */}
										<div className="NewContent">
											<div className="NewTitle">{translate(notice.name)}</div>
											<div className="NewContentText">
												{translate(notice.shortDescription)}
											</div>
											<Button
												className="SeeNewsButton"
												onClick={() =>
													dispatch(push(`/news/${notice._id}`))
												}
											>
												<span>{Strings.news.seeNews.toUpperCase()}</span>
											</Button>
										</div>
									</div>
								</div>
							);
						})}
					</Slider>
				) : (
					<></>
				)}
			</>
		);
	}

	renderCreateAccountSection() {
		const { dispatch } = this.props;

		return (
			<>
				<div className="weatherBackground">
					<div className="weatherBackground_Info">
						{Strings.authentication.toGetAllAppBenefitsRegister}
						<Button
							type="primary"
							// icon={<Icon name="user2" style={{ marginRight: 10 }} />}
							onClick={() => dispatch(push("/register"))}
							>
							<span>{Strings.authentication.registerHere}</span>
						</Button>
					</div>
					<img alt="" className="weatherBackground_Image" src={weatherBackground} />
					<div className="weatherBackground_Overlay"></div>
				</div>
			</>
		);
	}

	renderProductsSection() {
		const { products, isLaptop, isMobile, showOneCulture, IPhone } = this.state;
		const { dispatch, user } = this.props;

		const settings = {
			dots: IPhone ? true : false,
			arrows: IPhone ? false : true,
			infinite: isLaptop ? products?.length > 1 : products?.length > 2,
			autoplay: true,
			slidesToShow: isLaptop ? 1 : products?.length < 2 ? products?.length : 2,
			slidesToScroll: 1,
			speed: 500,
			autoplaySpeed: 5000,
			className: "ProductsSlider",
			prevArrow: (
				<button type="button" className="slick-arrow">
					<em className="moon-baixa" style={{ transform: "rotate(90deg)" }} />
				</button>
			),
			nextArrow: (
				<button type="button" className="slick-arrow">
					<em className="moon-baixa" style={{ transform: "rotate(-90deg)" }} />
				</button>
			),
		};

		return (
			<div className="HomeSection">
				<div className="HomeSectionInfo">
					<div className="HomeSectionContent">
						<div className="HomeSectionTitle">
							{(user
								? Strings.products.title
								: Strings.sidebar.products
							).toUpperCase()}
						</div>
						<div className="HomeSectionText">{Strings.products.text}</div>
					</div>
					{!isMobile && (
						<Button
							className="SeeDetailsButton"
							style={{ fontWeight: "bold" }}
							onClick={() => dispatch(push(`/products`))}
						>
							<span>{Strings.products.viewAll.toUpperCase()}</span>
						</Button>
						// <a className="SeeMore" href="/products">
						// 	{Strings.products.viewAll.toUpperCase()}
						// </a>
					)}
				</div>
				{products && products.length ? (
					<Slider {...settings}>
						{products.map((product: any) => {
							return <ProductCard data={product} />;
						})}
					</Slider>
				) : (
					<div className="ProductsEmptySectionContainer">
						<div
							className="ProductsEmptySection"
							style={showOneCulture ? { width: "100%" } : {}}
						>
							{!isLaptop && (
								<div
									className="ProductsEmptySectionCard"
									style={{
										left: "160px",
										scale: "0.9",
										opacity: "0.5",
									}}
								/>
							)}
							{!isMobile && (
								<div
									className="ProductsEmptySectionCard"
									style={{
										left: "89px",
										scale: "0.95",
										opacity: "0.7",
									}}
								/>
							)}
							<div className="ProductsEmptySectionCard">
								<div className="ProductsEmptySectionCardTitle">
									{Strings.products.yourProducts.toUpperCase()}
								</div>
								<div className="ProductsEmptySectionCardText">
									{Strings.products.noFavoriteProducts}
								</div>
								<Button
									className="ProductsEmptySectionCardButton"
									onClick={() =>
										dispatch(push(`${user ? "/profile" : "/login"}`, { openCompleteProfileSidebar: true }))
									}
								>
									<span>{Strings.products.addProducts.toUpperCase()}</span>
								</Button>
							</div>
						</div>
					</div>
				)}
				{isMobile && (
					<a
						style={{ color: "#787878", textAlign: "center", marginTop: "25px" }}
						href="/products"
					>
						{Strings.products.viewAll.toUpperCase()}
					</a>
				)}
			</div>
		);
	}

	renderDeficienciesSectionInfo() {
		const { dispatch } = this.props;

		return (
			<div className="DeficienciesSectionInfo">
				<div className="DeficienciesSectionContent">
					<div className="DeficienciesSectionTitle">
						{Strings.deficiencies.title.toUpperCase()}
					</div>
					<div className="DeficienciesSectionText">{Strings.deficiencies.text}</div>
				</div>
				<Button
					className="SeeDetailsButton"
					style={{ fontWeight: "bold" }}
					onClick={() => dispatch(push(`/deficiencies-identifying`))}
				>
					<span>{Strings.deficiencies.buttonTxt.toUpperCase()}</span>
				</Button>
			</div>
		);
	}

	renderCulturesSection() {
		const { cultures, isLaptop, isMobile, IPhone } = this.state;
		const { dispatch, user } = this.props;

		const settings = {
			dots: IPhone ? true : false,
			arrows: IPhone ? false : true,
			infinite: isLaptop ? cultures?.length > 1 : cultures?.length > 2,
			autoplay: true,
			slidesToShow: isLaptop ? 1 : cultures?.length < 2 ? cultures?.length : 2,
			slidesToScroll: 1,
			speed: 500,
			autoplaySpeed: 5000,
			className: "ProductsSlider CultureSlider",
			prevArrow: (
				<button type="button" className="slick-arrow">
					<em className="moon-baixa" style={{ transform: "rotate(90deg)" }} />
				</button>
			),
			nextArrow: (
				<button type="button" className="slick-arrow">
					<em className="moon-baixa" style={{ transform: "rotate(-90deg)" }} />
				</button>
			),
		};

		return (
			<div
				className="HomeSection"
				style={isMobile ? { flexDirection: "column-reverse" } : { marginBottom: 112 }}
			>
				{isMobile && (
					<a
						style={{ color: "#787878", textAlign: "center", marginTop: "25px" }}
						href="/cultures"
					>
						{Strings.cultures.viewAll.toUpperCase()}
					</a>
				)}
				{cultures && cultures.length ? (
					<Slider {...settings}>
						{cultures.map((culture: any) => {
							return <CultureCard data={culture} />;
						})}
					</Slider>
				) : (
					<div
						className="ProductsEmptySectionContainer"
						style={
							isMobile
								? {
										// justifyContent: 'flex-start',
										padding: 0,
								  }
								: isLaptop
								? {
										// justifyContent: 'flex-start',
										padding: "0 80px 0 0",
								  }
								: {
										// justifyContent: 'flex-start',
										padding: "0 135px 0 0",
								  }
						}
					>
						<div className="ProductsEmptySection">
							{!isLaptop && (
								<div
									className="ProductsEmptySectionCard"
									style={{
										left: "160px",
										scale: "0.9",
										opacity: "0.5",
									}}
								/>
							)}
							{!isMobile && (
								<div
									className="ProductsEmptySectionCard"
									style={{
										left: "89px",
										scale: "0.95",
										opacity: "0.7",
									}}
								/>
							)}
							<div className="ProductsEmptySectionCard">
								<div className="ProductsEmptySectionCardTitle">
									{Strings.cultures.yourCultures.toUpperCase()}
								</div>
								<div className="ProductsEmptySectionCardText">
									{Strings.cultures.noFavoriteCultures}
								</div>
								<Button
									className="ProductsEmptySectionCardButton"
									onClick={() =>
										dispatch(push(`${user ? "/profile" : "/login"}`, { openCompleteProfileSidebar: true }))
									}
								>
									<span>{Strings.cultures.addCulture.toUpperCase()}</span>
								</Button>
							</div>
						</div>
					</div>
				)}
				<div
					className="HomeSectionInfo"
					// style={
					//   isMobile
					//     ? { maxWidth: 600, padding: "10px 44px 30px 0px" }
					//     : { maxWidth: 600, padding: "10px 0 10px 45px" }
					// }
				>
					<div className="HomeSectionContent">
						<div className="HomeSectionTitle">
							{(user
								? Strings.cultures.title
								: Strings.sidebar.cultures
							).toUpperCase()}
						</div>
						<div className="HomeSectionText">{Strings.cultures.text}</div>
					</div>
					{!isMobile && (
						<Button
							className="SeeDetailsButton"
							style={{ fontWeight: "bold" }}
							onClick={() => dispatch(push(`/cultures`))}
						>
							<span>{Strings.cultures.viewAll.toUpperCase()}</span>
						</Button>
						// <a className="SeeMore" href="/cultures">
						// 	{Strings.cultures.viewAll.toUpperCase()}
						// </a>
					)}
				</div>
			</div>
		);
	}

	renderDeficienciesSection() {
		const { isMobile } = this.state;

		return (
			<div className="DeficienciesSection">
				<div
					className="DeficienciesSectionImage"
					style={{ backgroundImage: `url(${deficienciesBackground})` }}
				>
					<div className="DeficienciesSectionImageOverflow">
						<Icon name="focus" />
						{isMobile && this.renderDeficienciesSectionInfo()}
					</div>
				</div>
				{!isMobile && this.renderDeficienciesSectionInfo()}
			</div>
		);
	}

	renderAppSection() {
		return (
			<div className="Home_Apps">
				<div className="Home_Overlay" />
				<div className="Home_App_Container">
					<h1 className="App_Text">{Strings.generic.download1}</h1>
					<h2 className="App_Download">{Strings.generic.download2}</h2>
					<div className="App_Buttons">
						{APPLE_LINK ? (
							<a href={APPLE_LINK} target="_blank" rel=" noopener noreferrer">
								<img src={downloadApple} alt="download on app store" />
							</a>
						) : (
							<div className="noLink">
								<img src={downloadApple} alt="download on app store" />
							</div>
						)}
						{ANDROID_LINK ? (
							<a href={ANDROID_LINK} target="_blank" rel=" noopener noreferrer">
								<img src={downloadAndroid} alt="download on google play" />
							</a>
						) : (
							<div className="noLink">
								<img src={downloadAndroid} alt="download on google play" />
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
	renderSafetyDatasheetsSection() {
		return (
			<div className="Safety_Datasheets_Section">
				<div className="Safety_Datasheets_Container">
					<h1 className="Section_Title">{Strings.safetyDatasheets.header}</h1>
					<h2 className="Section_Description">{Strings.safetyDatasheets.description}</h2>
					<Button
						className="Section_Button"
						onClick={() =>
							window.open('https://drive.google.com/drive/folders/1TdlgS7bu2i5KSB6nhoTkJBdqVTPyOFWF')
						}
					>
						<span>{Strings.safetyDatasheets.seeDatasheets.toUpperCase()}</span>
					</Button>
				</div>
			</div>
		);
	}

	render() {
		const { weather, apiLoading } = this.state;
		const { user, dispatch } = this.props;

		console.log('LANG: ', navigator.language)

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.home}</title>
					<meta name="description" content="Description of Home" />
				</Helmet>
				{apiLoading ?
					<div className="HomeContent"></div> :
					<div className="HomeContent">
						{this.renderNewsSection()}
						{!user ?
							this.renderCreateAccountSection() :
							weather && Object.keys(weather).length ? 
								<div
									style={{ cursor: "pointer" }}
									onClick={() => {
										dispatch(push("/meteorology"));
									}}
								>
									<Weather data={weather} byHour={false} />
								</div> : null
						}
						{/* {user && weather && Object.keys(weather).length ? (
							<div
								style={{ cursor: "pointer" }}
								onClick={() => {
									dispatch(push("/meteorology"));
								}}
							>
								<Weather data={weather} byHour={false} />
							</div>
						) : (
							this.renderCreateAccountSection()
						)} */}
						{this.renderProductsSection()}
						{this.renderDeficienciesSection()}
						{this.renderCulturesSection()}
						{this.renderAppSection()}
						{this.renderSafetyDatasheetsSection()}
					</div>
				}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user,
	language: state.language || "pt",
});

export default connect(mapStateToProps)(Home);
