/*
*
* ErrorBoundary
*
*/

import React from 'react';
import { connect } from 'react-redux';
import Strings from "utils/strings";
import './styles.scss';

import digging from 'assets/images/digging.png';

export class ErrorBoundary extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		// Sentry
	}

	render() {
		if (this.state.hasError) {
			return <div className="SomethingWentWrong">
				<img alt="" src={digging} />
				<h1>{Strings.serverErrors.somethingWentWrong}</h1>
				<a className="BackHome" href="/home">
					{Strings.serverErrors.backToHome}
				</a>
			</div>
		}

		return this.props.children; 
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(ErrorBoundary);