/*
*
* AboutUs
*
*/

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Icon } from "components";
import { toast } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { translate } from "utils/utils";
import "./styles.scss";

export class AboutUs extends React.Component<any, any> {
constructor(props: any) {
	super(props);

	this.state = {
		page: {}
	};
}

componentDidMount() {
	const { dispatch } = this.props;

	// change this
	dispatch(setTitle(Strings.sidebar.aboutUs));

	this.getData();
}

componentDidUpdate() {
	const { page } = this.state;
	const { type } = this.props.match.params;
	if (page.type !== type) {
		this.getData();
	}
}

getData = async () => {
	const { dispatch, match } = this.props;

	dispatch(setLoader(true));
	const response = await API.get({
		url: Endpoints.uriPages(match.params.type),
	});

	if (response.ok) {
		this.setState({ page: response.data.results.pages });
	} else {
		dispatch(setLoader(false));
		return toast.error(response.data?.message || Strings.serverErrors.title);
	}
	dispatch(setLoader(false));
};

render() {
	const { page } = this.state;
	console.log('PAGE', page);

	return (
	<React.Fragment>
		<Helmet>
			<title>{Strings.sidebar.aboutUs}</title>
			<meta name="description" content="Description of AboutUs" />
		</Helmet>
		<div className="PageHeader">
			{ page?.type === 'au' ? (
				<div className="PageHeaderTitle">
					<Icon name="aboutus" />
					<span>{translate(page?.title)}</span>
				</div>
			) : (
				<div className="PageHeaderTitle">
					<span>{translate(page?.title)}</span>
				</div>
			)}
		</div>
		<div className="AboutUsContent MobileContainer">
		<td dangerouslySetInnerHTML={{ __html: translate(page?.content) }} />
		</div>
	</React.Fragment>
	);
}
}

const mapStateToProps = (state: any) => ({
language: state.language || 'pt',
});

export default connect(mapStateToProps)(AboutUs);
