/*
 *
 * AnalysisType
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { Row, Col } from "antd";
import { Icon } from "components";
import { toast, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import { setTitle, setLoader } from "store/actions";
import { push } from "connected-react-router";
import "./styles.scss";

export class AnalysisType extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      analysisType: {},
      isIPhone: window.innerWidth <= 600,
      isMobile: window.innerWidth <= 768,
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle("AnalysisType"));

    this.getData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      isIPhone: window.innerWidth <= 600,
      isMobile: window.innerWidth <= 768,
    });
  }

  getData = async () => {
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));
    try {
      const response = await API.get({
        url: Endpoints.uriAgronomicServices(match.params.id),
      });

      if (response.ok) {
        const data = response.data.results;

        data?.analysisTypes?.map((elem: any) => {
          if (elem.type === match.params.type) {
            this.setState({ analysisType: elem });
          }
        });

        this.setState({ agronomicService: data });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      dispatch(setLoader(false));
      console.log(err);
    }

    dispatch(setLoader(false));
  };

  render() {
    const { agronomicService, analysisType, isIPhone } = this.state;
    const { dispatch, match } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.agronomicServices.header}</title>
          <meta name="description" content="Analysis Type screen" />
        </Helmet>
        <div className="PageHeader">
          <div
            className="PageHeaderTitle"
            onClick={() =>
              dispatch(push(`/agronomic-services/${match.params.id}`))
            }
            style={{ cursor: "pointer" }}
          >
            <Icon name="back" style={{ fontWeight: "normal" }} />
            <div>{translate(agronomicService?.name)}</div>
          </div>
        </div>
        <Row className="AgronomicServiceContent ">
          <div className="AgronomicServiceImageContainer">
            <div
              className="AgronomicServiceImage"
              style={{
                backgroundImage: `url(${agronomicService?.image})`,
                backgroundSize: "cover",
              }}
            ></div>
          </div>
          <Col
            md={14}
            xs={24}
            className="MobileContainer"
            style={
              !isIPhone
                ? {
                    padding: "25px 20px",
                  }
                : {}
            }
          >
            <Col
              md={24}
              style={{
                color: "#7D878C",
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "15px",
              }}
              className="SubtitleMargin"
            >
              {Object.entries(Strings.agronomicServices).find(elem => elem[0] === analysisType?.type)?.[1].toUpperCase()}
            </Col>
            <Row className="SubtitleMargin">
              <Col md={10} xs={24}>
                {analysisType?.values?.length ? (
                  <>
                    <Col
                      md={24}
                      className="Subtitle"
                      style={{ marginBottom: "5px" }}
                    >
                      {Strings.agronomicServices.values}
                    </Col>
                    {analysisType?.values?.map((elem: any) => (
                      <Col md={24}>{translate(elem)}</Col>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col md={12} xs={24} className="SubtitleMarginTop">
                <Col
                  md={24}
                  className="Subtitle"
                  style={{ marginBottom: "5px" }}
                >
                  {Strings.agronomicServices.observation}
                </Col>
                <Col md={24}>{translate(analysisType?.observation)}</Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(AnalysisType);
