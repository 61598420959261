/*
 *
 * Product
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setTitle, setLoader, setUser } from "store/actions";
import { Helmet } from "react-helmet";
import { Row } from "antd";
import { Icon, SelectableTabs } from "components";
import { toast, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import PDFIcon from "../../assets/images/PDF_icon.png";
import Strings from "utils/strings";
import "./styles.scss";

export class Product extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      adpProducts: false,
      isFavorite: false,
      selectedTab: "formulas",
    };
  }

  componentDidMount() {
    const { adpProducts } = this.state;
    const { dispatch, match, user } = this.props;
    // change this
    dispatch(setTitle(Strings.sidebar.products));

    const isFavorite = !!user?.products?.find(
      (elem: any) => match.params.id === (elem._id || elem)
    );
    this.setState({ isFavorite });

    const { pathname } = window.location;
    if (pathname.includes("/adp-products") && !adpProducts)
      this.setState({ adpProducts: true });
    else if (pathname.includes("/products") && adpProducts)
      this.setState({ adpProducts: false });

    this.getData();
  }

  componentDidUpdate() {
    const { adpProducts } = this.state;

    const { pathname } = window.location;

    // const isFavorite = !!user?.products.find((elem: any) => match.params.id === elem._id);

    // if (this.state.isFavorite !== isFavorite) this.setState({ isFavorite });

    if (pathname.includes("/adp-products") && !adpProducts)
      this.setState({ adpProducts: true });
    else if (pathname.includes("/products") && adpProducts)
      this.setState({ adpProducts: false });
  }

  getData = async () => {
    const { match, dispatch } = this.props;

    dispatch(setLoader(true));

    const response = await API.get({
      url: Endpoints.uriProducts(match.params.id),
    });

    if (response.ok) {
      // this.setState({ news: response.data.results.news });
      this.setState({ product: response.data.results.product });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    dispatch(setLoader(false));
  };

  patchProductFavorites = async () => {
    const { isFavorite } = this.state;
    const { user, dispatch, match } = this.props;

    dispatch(setLoader(true));

    if (user) {
      const response = await API.patch({
        url: Endpoints.uriUsers(`${user._id}/${match.params.id}`),
        data: {
          type: "products",
          action: isFavorite ? "remove" : "add",
        },
      });

      if (response.ok) {
        const user = response.data.results;
        dispatch(setUser({ ...user }));
        this.setState({
          isFavorite: user.products.find(
            (elem: any) => (elem._id || elem) === match.params.id
          ),
        });
      }
    } else dispatch(push("/login"));

    dispatch(setLoader(false));
  };

  renderFormulas() {
    const { product } = this.state;
    const { match, dispatch } = this.props;

    const { pathname } = window.location;

    return (
      <div className="ProductFormulas">
        {product?.formulas.map((formula: any) => (
          <div
            key={formula._id}
            className="ProductFormula"
            onClick={() =>
              dispatch(push(`/formula/${formula._id}`, { formula, product, backPath: pathname.includes("/adp-products") ? `/adp-products/${match.params.id}` : `/products/${match.params.id}` }))
            }
          >
            {translate(formula.name)}
            {/* <Icon name="back" style={{ transform: "rotate(180deg)" }} /> */}
          </div>
        ))}
      </div>
    );
  }

  renderDocumentation() {
    const { product } = this.state;

    return (
      <div className="ProductFormulas" style={{ minHeight: 30 }}>
        {product?.attachedFiles?.filter(
              (elem: any) => translate(elem.file)
            )?.map((document: any) => {
          if (translate(document.name))
            return (
              <div
                className="ProductFormula"
                style={{ justifyContent: "normal" }}
                onClick={() => window.open(translate(document.file))}
              >
                <img
                  className="PDFIcon"
                  alt={translate(document.name)}
                  src={PDFIcon}
                />
                {translate(document.name)}
              </div>
            );
        })}
        {/* {!product?.attachedFiles?.filter(
              (elem: any) => translate(elem.file)
            ).length && <div style={{ margin: 10 }}>{Strings.generic.noContent}</div>} */}
      </div>
    );
  }

  render() {
    const { product, selectedTab, adpProducts, isFavorite } = this.state;
    const { dispatch, backPath } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.products.header}</title>
          <meta name="description" content="Description of Product" />
        </Helmet>
        <div
          key={`${isFavorite}_header`}
          className="PageHeader"
          style={{ backgroundColor: product?.color }}
        >
          <div
            className="PageHeaderTitle"
            style={{ cursor: 'pointer', textTransform: 'none', fontSize: 20 }}
            onClick={() =>
              backPath ?
                dispatch(push(backPath)) :
                dispatch(push(adpProducts ? "/adp-products" : "/products"))
            }
          >
              <Icon name="back" style={{ fontWeight: "normal" }} />
              <div>{translate(product?.name)}</div>
          </div>
          <Icon
              name={isFavorite ? "star-full" : "star-empty"}
              style={{ cursor: "pointer", fontSize: 34 }}
              onClick={() => this.patchProductFavorites()}
          />
        </div>
        <Row className="ProductContent">
          <div className="ProductImageContainer">
            {/* <div
              className="ProductImage"
              style={{ backgroundImage: `url(${product?.image})` }}
            ></div> */}
            <img alt={translate(product?.name)} src={product?.image} />
          </div>
          <div className="ProductInfo">
            <div className="ProductTitle" style={{ color: product?.color }}>
              {translate(product?.name)}
            </div>
            <div>{translate(product?.slogan)}</div>
            <div className="ProductDescription">
              <td
                dangerouslySetInnerHTML={{ __html: translate(product?.info) }}
              />
            </div>
          </div>
          <div className="ProductSelectables">
            <SelectableTabs
              selectedTab={selectedTab}
              activeStyle={{ borderBottomColor: product?.color }}
              tabs={[
                {
                  key: "formulas",
                  icon: "oil",
                  title: Strings.products.formulas,
                  disabled: !product?.formulas?.length,
                  onClick: () => this.setState({ selectedTab: "formulas" }),
                  renderScreen: () => this.renderFormulas(),
                },
                {
                  key: "documentation",
                  icon: "flask",
                  title: Strings.products.documentation,
                  disabled: !product?.attachedFiles?.filter(
                    (elem: any) => translate(elem.file)
                  )?.length,
                  onClick: () =>
                    this.setState({ selectedTab: "documentation" }),
                  renderScreen: () => this.renderDocumentation(),
                },
              ]}
            />
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  backPath: state.router?.location?.state?.backPath,
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(Product);
