/*
 *
 * Contact
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Col, Input, Row, Checkbox, Select } from "antd";
import { API, Endpoints } from "utils/api";
import { toast, emailIsValid } from "utils/utils";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import PhoneInput from 'components/PhoneInput';
import Dropzone from "react-dropzone";
import { Icon } from "components";
import Strings from "utils/strings";
import "./styles.scss";
const { TextArea } = Input;

const { Option } = Select;

export class Contact extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			name: "",
			from: "",
			phone: "",
			message: "",
			checked: false,
			phoneCountry: "pt",
			isMobile: window.innerWidth <= 991,
		};

		window.addEventListener("resize", this.handleResize);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.contact));
		this.getCountries();
		this.setState({ language: this.props.language });
	}

	handleResize = () => {
		const { isMobile } = this.state;

		if (window.innerWidth <= 991 && !isMobile)
			this.setState({
				isMobile: true,
			});
		else if (window.innerWidth > 991 && isMobile)
			this.setState({
				isMobile: false,
			});
	};

	setDefaultData = () => {
		const { user } = this.props;

		if (!user) return;

		if (user.country) {
			const states = this.getStates(user.country) || [];

			if (user.city) {
				const state = this.getCountyState(user.city, states);
				if (state) this.setState({ country: user.country }, () => this.getCounties(state));
			}
		}

		this.setState({
			name: user.name,
			from: user.email,
			phone: user.phone || "",
			county: user.city || "",
			phoneCountry: user.language || "pt",
		});
	};

	getCountyState(city: any, states: any) {
		for (const s of states) {
			for (const county of s.counties) {
				if (county.name === city) {
					return s;
				}
			}
		}

		return null;
	}

	async submitForm(event: any) {
		event.preventDefault();
		const { name, from, phone, country, state, county, subject, message, attachedFile } = this.state;
		const { dispatch } = this.props;

		const body = new FormData();
		body.append("name", name);
		body.append("from", from);
		body.append("phone", phone);
		body.append("subject", subject);
		body.append("message", message);
		body.append("country", country);
		body.append("state", state);
		body.append("county", county);

		if (attachedFile) {
			console.log('FILE', attachedFile);
			body.append("attach", attachedFile.file ? attachedFile.file : attachedFile);
		}

		dispatch(setLoader(true));

		if (!this.canSubmit()) {
			toast.warn(Strings.errors.pleaseFillFormCorrectly);
			dispatch(setLoader(false));
		} else {
			const response = await API.post({
				url: Endpoints.uriMisc(`send-attach-email`),
				data: body,
			});

			if (response) {
				if (response.status >= 200 && response.status < 400) {
					this.setState(
						{
							name: "",
							from: "",
							phone: "",
							subject: "",
							message: "",
							country: "",
							state: "",
							county: "",
							attachedFile: null,
							checked: false,
						},
						() => this.setDefaultData()
					);
					toast.success(Strings.contact.success);
				} else {
					dispatch(setLoader(false));
					return toast.error(response.data?.message || Strings.serverErrors.title);
				}
			}
		}

		dispatch(setLoader(false));
	}

	canSubmit() {
		const { name, from, phone, country, state, county, subject, message, checked } = this.state;
		if (
			Boolean(name?.trim()) &&
			Boolean(from?.trim() && emailIsValid(from)) &&
			Boolean(message?.trim()) &&
			Boolean(phone?.trim()) &&
			Boolean(subject?.trim()) &&
			Boolean(country?.trim()) &&
			Boolean(state?.trim()) &&
			Boolean(county?.trim()) &&
			checked
		) {
			return true;
		} else {
			return false;
		}
	}

	getCountries = async () => {
		const { dispatch, language } = this.props;

		const response = await API.get({
			url: Endpoints.uriMisc(`countries/promoters`),
		});

		if (response.ok) {
			const countries = response.data.results;
			this.setState(
				{
					countries,
					countriesOptions: countries.map((elem: any) => (
						<Option key={elem._id} value={elem.name}>
							{elem.translations[language]}
						</Option>
					)),
				},
				() => this.setDefaultData()
			);
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	};

	getStates(countryName: string) {
		const { countries } = this.state;

		const country = countries.find((elem: any) => elem.name === countryName);

		this.setState({
			country: countryName,
			statesOptions: country?.states.map((elem: any) => (
				<Option key={elem._id} value={elem.name}>
					{elem.name}
				</Option>
			)),
		});

		return country?.states || [];
	}

	getCounties(stateName: any) {
		const { countries } = this.state;

		let state = null;

		if (typeof stateName === "string") {
			const country = countries.find((elem: any) => elem.name === this.state.country);
			state = country.states.find((elem: any) => elem.name === stateName);
		} else {
			state = stateName;
		}

		this.setState({
			state: stateName.name || stateName,
			countiesOptions: state.counties.map((elem: any) => (
				<Option key={elem._id} value={elem.name}>
					{elem.name}
				</Option>
			)),
		});
	}

	onChangeFile = (file: any) => {
		this.setState({ attachedFile: file });
	}

	onDropFile = async (files: any) => {
		try {
			const onChange = this.onChangeFile;
		
			const file = files[files.length - 1];
			let reader = new FileReader();
			reader.onload = function(e: any) {
				let blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
				console.log('BLOB', blob);
				console.log('FILENAME', file.name);
				onChange({ file: blob, fileName: file.name });
			};
			reader.readAsArrayBuffer(file);
		
			this.setState({ fileToDelete: null });
		} catch(err) {
		  	toast.warning(Strings.errors.notSupportedFile);
		}
	}

	render() {
		const {
			name,
			from,
			phone,
			phoneCountry,
			countriesOptions,
			country,
			statesOptions,
			state,
			countiesOptions,
			county,
			subject,
			message,
			attachedFile,
			fileToDelete,
			checked,
			isMobile,
		} = this.state;

		const { user } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.contact}</title>
					<meta name="description" content="Description of Contact" />
				</Helmet>
				<div className="LoginContent MobileContainer">
					<div className="LoginContentWrapper">
						<Row>
							<Col xs={24} sm={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }}>
								<div className="LoginBox">
									<Row>
										<Col xs={24} lg={12}>
											<div
												className="LoginWrapper"
												style={{ padding: "30px 50px" }}
											>
												{/* <h2>{Strings.authentication.welcome}</h2> */}
												<form onSubmit={(e) => this.submitForm(e)}>
													<h2 style={{ fontWeight: "normal" }}>
														{Strings.contact.sendMessage}
													</h2>
													<Col xs={24} md={24}>
														<Input
															placeholder={Strings.contact.name}
															className="LoginInputClass"
															required
															value={name || ""}
															style={{ marginBottom: "10px" }}
															onChange={(e: any) =>
																this.setState({
																	name: e.target.value,
																})
															}
														/>
														<Input
															placeholder={Strings.contact.email}
															className="LoginInputClass"
															required
															value={from || ""}
															style={{ marginBottom: "10px" }}
															onChange={(e: any) =>
																this.setState({
																	from: e.target.value,
																})
															}
														/>
														<Row gutter={12}>
															<Col xs={24} lg={12}>
																{/* Country */}
																<Select
																	key={`country_${country}`}
																	className="tagsSelector"
																	style={{
																		width: "100%",
																		marginBottom: 10,
																	}}
																	placeholder={
																		Strings.fields.country
																	}
																	showSearch
																	defaultValue={country || null}
																	onChange={(elem: any) => {
																		this.getStates(elem);
																	}}
																>
																	{countriesOptions}
																</Select>
															</Col>
															<Col xs={24} lg={12}>
																{/* State */}
																<Select
																	key={`state_${state}`}
																	className="tagsSelector"
																	style={{
																		width: "100%",
																		marginBottom: 10,
																	}}
																	placeholder={
																		Strings.fields.state
																	}
																	showSearch
																	defaultValue={state || null}
																	onChange={(elem: any) => {
																		this.getCounties(elem);
																	}}
																>
																	{statesOptions}
																</Select>
															</Col>
														</Row>
														<Row gutter={12}>
															<Col xs={24} lg={12}>
																{/* County */}
																<Select
																	key={`county_${county}`}
																	className="tagsSelector"
																	style={
																		isMobile
																			? {
																					width: "100%",
																					marginBottom: 10,
																			  }
																			: { width: "100%" }
																	}
																	placeholder={
																		Strings.fields.county
																	}
																	showSearch
																	defaultValue={county || null}
																	onChange={(elem: any) => {
																		this.setState({
																			county: elem,
																		});
																	}}
																>
																	{countiesOptions}
																</Select>
															</Col>
															<Col xs={24} lg={12}>
																<PhoneInput
																	key={`phone_${JSON.stringify(
																		phoneCountry
																	)}`}
																	defaultCountry={phoneCountry}
																	value={phone}
																	inputClass={`input-phone`}
																	onChange={(phone: any) => {
																		this.setState({
																			phone: phone,
																		});
																	}}
																/>
															</Col>
														</Row>
														<Input
															placeholder={Strings.contact.subject}
															className="LoginInputClass"
															required
															value={subject || ""}
															style={{ margin: "10px 0" }}
															onChange={(e: any) =>
																this.setState({
																	subject: e.target.value,
																})
															}
														/>
														<TextArea
															required
															value={message || ""}
															placeholder={Strings.contact.message}
															onChange={(e: any) =>
																this.setState({
																	message: e.target.value,
																})
															}
															rows={5}
															style={{ maxHeight: "300px" }}
														/>
														{user ? 
														<Dropzone
															accept="image/jpg, image/jpeg, image/png, .pdf, .mp4"
															multiple={false}
															className="ant-input"
															onDrop={files => this.onDropFile(files)}
															style={{ margin: '10px 0 5px 0' }}>
															<Icon name="file" style={{ marginRight: '10px' }}/><span style={{ color: '#bfbfbf' }}>{Strings.fields.clickToUpload}</span>
														</Dropzone> : <></>}
														{attachedFile ? <div
															className="fileItem"
															onMouseEnter={() => this.setState({ fileToDelete: true })}
															onMouseLeave={() => this.setState({ fileToDelete: null })}>
															<Icon
																name={fileToDelete ? "close" : "file"}
																onClick={() => this.setState({ attachedFile: null })}
																className="fileIcon" />
															<span className="fileName">{attachedFile.fileName}</span>
														</div> : <></>}
													</Col>

													<button
														type="submit"
														className="LoginButtonClass"
													>
														{/* <div className="LoadingSpinner">
                              <RotateSpinner size={24} color="#FFFFFF" />
                            </div> */}
														{Strings.contact.send}
													</button>
												</form>

												<div className="CheckBoxContainer">
													<Checkbox
														checked={checked}
														onChange={(e: any) =>
															this.setState({
																checked: e.target.checked,
															})
														}
													>
														{Strings.contact.acceptTerms + " "}
														<a href="/page/pp" target="_blank">
															{Strings.sidebar.privacy}
														</a>
													</Checkbox>
												</div>
											</div>
										</Col>
										<Col xs={0} lg={12}>
											<div className="MessageFormImage"></div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language || "pt",
	user: state.user,
});

export default connect(mapStateToProps)(Contact);
