/**
 *
 * ProductCard
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle } from "store/actions";
import { push } from "connected-react-router";
import { Button } from "antd";
import { translate } from "utils/utils";
import "./styles.scss";
import Strings from "utils/strings";

export class ProductCard extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      adpProducts: false,
    };
  }

  componentDidMount() {
    const { adpProducts } = this.state;
    const { dispatch } = this.props;

    dispatch(setTitle(Strings.sidebar.products));

    const { pathname } = window.location;

    if (pathname === "/adp-products" && !adpProducts)
      this.setState({ adpProducts: true });
    else if (pathname === "/products" && adpProducts)
      this.setState({ adpProducts: false });
  }

  componentDidUpdate() {
    const { adpProducts } = this.state;
    const { pathname } = window.location;

    if (pathname === "/adp-products" && !adpProducts)
      this.setState({ adpProducts: true });
    else if (pathname === "/products" && adpProducts)
      this.setState({ adpProducts: false });
  }

  render() {
    const { adpProducts } = this.state;
    const { dispatch, data, main, noDescription } = this.props;

    return (
      <div
        key={`${main}_${data}`}
        className={`ProductCard ${main && "main"} ${
          (noDescription || !translate(data.shortInfo)) && "noDescription"
        }`}
      >
        <div
          className="ProductCardImage"
          // style={{ backgroundImage: `url(${data.image})` }}
        ></div>
        <img
          alt={translate(data.name)}
          src={data.image}
          style={{ cursor: "pointer" }}
          onClick={() =>
            dispatch(
              push(
                adpProducts
                  ? `/adp-products/${data._id}`
                  : `/products/${data._id}`
              )
            )
          }
        />
        <div className="ProductCardInfo">
          <div className="ProductCardTitle" style={{ color: data.color }}>
            {translate(data.name)}
          </div>
          <div
            className={`ProductCardDescription ${main && "main"}`}
          >
            {noDescription || !translate(data.shortInfo)
              ? ""
              : translate(data.shortInfo)}
          </div>
          <Button
            className="SeeDetailsButton"
            style={{ margin: "auto" }}
            onClick={() =>
              dispatch(
                push(
                  adpProducts
                    ? `/adp-products/${data._id}`
                    : `/products/${data._id}`
                )
              )
            }
          >
            <span>{Strings.products.viewDetails.toUpperCase()}</span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(ProductCard);
