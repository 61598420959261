// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/backgrounds/message_form_background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".CheckBoxContainer{width:100%;margin-top:5px}.CheckBoxContainer a{color:#7d878c}.MessageFormImage{padding:20px;height:100%;background-color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-size:cover;background-repeat:no-repeat}.fileIcon{margin-right:10px;cursor:pointer}.fileIcon:hover{color:red}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
