/* eslint-disable array-callback-return */
/*
 *
 * Meteorology
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader, setUser } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { Weather, Icon } from "components";
import { Row, Col, Select } from "antd";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";
import { DateTime } from "luxon";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { GEO_API_KEY } from "../../constants.js";
import Strings from "utils/strings";
import { EditSidebar } from "components/EditSidebar";
import "./styles.scss";

const { Option } = Select;

export class Meteorology extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      portugalStatesCountiesList: [],
      spainStatesCountiesList: [],

      locItem: {},
      statesOptions: [],
      countiesOptions: [],

      isIPhone5: window.innerWidth <= 320,
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    if (!user) {
      dispatch(push("/login"));
      return;
    } else if (!user.country) {
      toast.warn(Strings.errors.selectLocation);
      dispatch(push("/profile", { openCompleteProfileSidebar: true }));
      return;
    }

    dispatch(setTitle(Strings.sidebar.meteorology));

    this.getData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      isIPhone5: window.innerWidth <= 320,
    });
  }

  getData = async () => {
    const { dispatch, user } = this.props;

    dispatch(setLoader(true));

    const list = [
      API.post({
        url: Endpoints.uriMisc("meteorology"),
      }),
      API.get({
        url: Endpoints.uriMisc(`countries/promoters/`),
      }),
    ];
    if (user?._id)
      list.push(
        API.get({
          url: Endpoints.uriUsers(user._id),
        })
      );

    const [meteorologyResponse, countriesResponse, userResponse] =
      await Promise.all(list);

    if (meteorologyResponse.ok) {
      const data = meteorologyResponse.data.results;

      this.setState({ ...data });
    } else {
      return toast.error(
        meteorologyResponse.data?.message || Strings.serverErrors.title
      );
    }

    if (countriesResponse.ok) {
      this.setState({
        countries: countriesResponse.data.results,
      });
    } else {
      dispatch(setLoader(false));
      return toast.error(
        countriesResponse.data?.message || Strings.serverErrors.title
      );
    }

    if (userResponse) {
      if (userResponse.ok) {
        const userData = userResponse.data.results;

        const location = userResponse.data.results?.locations?.find(
          (el: any) => el.country === user.country && el.city === user.city
        );
        this.setState({ userData: userData, location: location });
        countriesResponse.data.results.map((elem: any) => {
          if (elem.name === location?.country) {
            elem.states.map((st: any) => {
              if (st.name === location?.state) {
                this.setState({
                  location: {
                    ...location,
                    country: elem,
                    state: st,
                  },
                  //userData: userData,
                });
              }
            });
          }
        });
      } else {
        dispatch(setLoader(false));
        return toast.error(
          userResponse.data?.message || Strings.serverErrors.title
        );
      }
    }

    dispatch(setLoader(false));
  };

  getWeather = async () => {
    const { locItem } = this.state;
    const { dispatch } = this.props;
    dispatch(setLoader(true));

    const list = [
      API.post({
        url: Endpoints.uriMisc("meteorology"),
        data: { location: locItem },
      }),
      API.get({
        url: Endpoints.uriMisc(`countries/promoters/`),
      }),
    ];

    const [meteorologyResponse, countriesResponse] = await Promise.all(list);

    if (meteorologyResponse.ok) {
      const data = meteorologyResponse.data.results;

      this.setState({ ...data });
    } else {
      return toast.error(
        meteorologyResponse.data?.message || Strings.serverErrors.title
      );
    }

    if (countriesResponse.ok) {
      this.setState({
        countries: countriesResponse.data.results,
      });
    } else {
      dispatch(setLoader(false));
      return toast.error(
        countriesResponse.data?.message || Strings.serverErrors.title
      );
    }

    dispatch(setLoader(false));
  };



  getCountryInfo = async (country: string) => {
    const { dispatch } = this.props;
    let auxCountryId = country === "Portugal" ? "PT" : "ES";
    let hideLoader = true;
    try {
      const response = await API.get({
        url: Endpoints.uriMisc(`countries/general/${auxCountryId}`),
      });
      if (response.ok) {
        auxCountryId === "PT"
          ? this.setState({
              portugalStatesCountiesList: response.data.results.states,
            })
          : this.setState({
              spainStatesCountiesList: response.data.results.states,
            });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      return toast.error(String(err || Strings.serverErrors.title));
    } finally {
      if (hideLoader) dispatch(setLoader(false));
    }
  };

  getStates(country: string) {
    const { countries } = this.state;

    if (country === "Espanha") country = "Spain";

    const states = countries.find((elem: any) => elem.name === country)?.states || [];

    this.setState({
      statesOptions: states.map((elem: any) => (
        <Option key={elem._id} value={elem.name}>
          {elem.name}
        </Option>
      )),
    });

    return states;
  }

  onCloseSidebar = () => {
    this.setState({ openSidebar: null });
  };

  onSubmitSidebar = async () => {
    const { item } = this.state;
    await this.setState({
      location: item.location,
      locItem: {
        country: item.location.country,
        city: item.location.city,
        coordinates: item.location.coordinates,
      },
    });
    this.getWeather();
    this.onCloseSidebar();
  };

  onSubmitLocationSidebar = async () => {
    const { item } = this.state;
    const { dispatch, user } = this.props;

    dispatch(setLoader(true));
    if (!item.location?.country || !item.location?.city) {
      dispatch(setLoader(false));
      return toast.warning(Strings.errors.invalidFields);
    }
  
    const body = new FormData();
    if (item.location) body.append("location", JSON.stringify(item.location));

    const response = await API.put({
      url: Endpoints.uriUsers(user._id),
      data: body,
    });

    if (response.ok) {
      const newUser = {
        ...response.data.results.user,
        country: item.location.country,
        city: item.location.city,
        location: {
          country: item.location.country,
          city: item.location.city,
        },
      };

      dispatch(setUser({ ...newUser }));
      this.setState({
        location: item.location,
        locItem: {
          country: item.location.country,
          city: item.location.city,
          coordinates: item.location.coordinates,
        },

        userData: { ...newUser, locations: response.data.results.locations },
        openSidebar: null,
        openAddLocationSidebar: false,
      });
      toast.success(response.data.message);
      this.getWeather();
    }

    dispatch(setLoader(false));
  }

  renderSidebar() {
    return (
      <>
        <EditSidebar
          title={
            <div style={{ cursor: "default" }}>
              <b>{Strings.fields.chooseLocation}</b>
            </div>
          }
          open={this.state.openSidebar}
          onClose={this.onCloseSidebar}
          onSubmit={this.onSubmitSidebar}
          defaultValue={this.state.item}
          closable={false}
          extraFields={this.renderFiltersFields}
          hasFields={false}
        />
      </>
    );
  }

  renderFiltersFields = () => {
    const { userData, item } = this.state;

    return (
      <>
        <div>
          {userData?.locations.map((elem: any) => (
            <div
              className={`LocationButton ${
                item?.location?._id === elem._id && "__selected"
              }`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => {
                // const location = elem;
                // if (location) {
                //   let country = location.country;

                //   if (country === "Portugal" || country === "Spain") {
                //     let data = item?.location?.country?.states;

                //     const auxIndex = data?.findIndex((el: any) => {
                //       return location.state === el.name;
                //     });
                //   }
                // }

                this.setState({
                  item: {
                    ...this.state.item,
                    location: item?.location?._id === elem._id ? null : elem,
                  },
                });
              }}
            >
              {elem.city && elem.country
                ? `${elem.city}, ${elem.country}`
                : `${elem.coordinates?.coordinates[0]}, ${elem.coordinates?.coordinates[1]}`}
            </div>
          ))}
        </div>
        <div
          className="AddLocationLink"
          onClick={() =>
            this.setState({ openAddLocationSidebar: true })
          }
        >
          {Strings.weather.addLocation}
        </div>
      </>
    );
  };

  renderAddLocationSidebar() {
    const { newItemLocation, openAddLocationSidebar } = this.state;
    return (
      <EditSidebar
        title={Strings.fields.location}
        open={openAddLocationSidebar}
        onClose={() => this.setState({ openAddLocationSidebar: false })}
        onSubmit={() =>
          this.setState(
            {
              item: { ...this.state.item, location: newItemLocation?.location },
            },
            () => this.onSubmitLocationSidebar()
          )
        }
        defaultValue={newItemLocation}
        closable={false}
        hasFields={true}
        fields={[
          {
            version: DateTime.local(),
          },
        ]}
        extraFields={() => this.renderNewLocationFields()}
      />
    );
  }

  renderNewLocationFields() {
    const { newItemLocation, statesOptions, countiesOptions } = this.state;
    const { dispatch, user } = this.props;

    return (
      <>
        <label className={`SingleLabel __required`}>
          {Strings.fields.location}
        </label>
        <div className="LocationBlock">
          <div className="Location">
            <Autocomplete
              className="LocationInput"
              label={Strings.fields.location}
              placeholder={Strings.fields.location}
              apiKey={GEO_API_KEY}
              value={
                newItemLocation?.location?.city &&
                `${newItemLocation?.location?.city}, ${newItemLocation?.location?.country}`
              }
              onChange={(e: any) =>
                this.setState({
                  newItemLocation: { location: e.target.value },
                })
              }
              onPlaceSelected={(place: any) => {
                const aux = place.geometry.location;
                const addressComponents = place.address_components;
                let country, city: any, state, countiesOptions, county;

                let countryName = addressComponents.find(
                  (v: any) => v.types.indexOf("country") >= 0
                );
                if (countryName) country = countryName.long_name;

                let cityName = addressComponents.find(
                  (v: any) =>
                    v.types.indexOf("locality") >= 0 ||
                    v.types.indexOf("administrative_area_level_1") >= 0
                );
                if (cityName) city = cityName.long_name;

                let stateName: any;
                let statesList: any;

                if (country === "Portugal" || country === "Espanha") {
                  let data = this.getStates(country);

                  let auxIndex = -1;

                  stateName = addressComponents.find(
                    (v: any) =>
                      v.types.indexOf("administrative_area_level_1") >= 0
                  );
                  auxIndex = data.findIndex((el: any) => {
                    return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                      el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    );
                  });

                  countiesOptions =
                    data[auxIndex]?.counties.map((elem: any) => (
                      <Option key={elem._id} value={elem.name}>
                        {elem.name}
                      </Option>
                    )) || [];

                  county =
                    data[auxIndex]?.counties.find((elem: any) => {
                      return city.includes(elem.name)
                    })?.name || null;

                  if (auxIndex === -1) {
                    stateName = addressComponents.find(
                      (v: any) =>
                        v.types.indexOf("administrative_area_level_2") >= 0
                    );
                    let newAuxIndex = -1;
                    newAuxIndex = data.findIndex((el: any) => {
                      countiesOptions =
                        el.counties.map((elem: any) => (
                          <Option key={elem._id} value={elem.name}>
                            {elem.name}
                          </Option>
                        )) || [];
                      return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                        el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      );
                    });

                    countiesOptions =
                      data[newAuxIndex]?.counties.map((elem: any) => (
                        <Option key={elem._id} value={elem.name}>
                          {elem.name}
                        </Option>
                      )) || [];

                    county =
                      data[newAuxIndex]?.counties.find((elem: any) =>
                        city.includes(elem.name)
                      )?.name || null;

                    if (newAuxIndex === -1) {
                      stateName = null;
                    }
                  }

                  if (stateName) {
                    statesList = this.getStates(country);
                    state = statesList.find((elem: any) =>
                      stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                        elem.name
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                      )
                    )?.name;
                  }
                }

                const location = {
                  city,
                  country,
                  state,
                  county,
                  coordinates: {
                    type: "Point",
                    coordinates: [aux.lat(), aux.lng()],
                  },
                  default: true,
                };

                this.setState({
                  newItemLocation: { location },
                  countiesOptions,
                });
              }}
            />
            <Icon
              className="LocationMarker"
              name="placeholder"
              onClick={() => {
                const { newItemLocation } = this.state;
                const setState = (elem: any) => this.setState(elem);
                const getStates = (country: any) => this.getStates(country);
                navigator.geolocation.getCurrentPosition(function (position) {
                  Geocode.fromLatLng(
                    `${position.coords.latitude}`,
                    `${position.coords.longitude}`
                  ).then(
                    (response: any) => {
                      const addressComponents =
                        response.results[0].address_components;
                      let country, city, state, countiesOptions;

                      let countryName = addressComponents.find(
                        (v: any) => v.types.indexOf("country") >= 0
                      );
                      if (countryName) country = countryName.long_name;

                      let cityName = addressComponents.find(
                        (v: any) =>
                          v.types.indexOf("locality") >= 0 ||
                          v.types.indexOf("administrative_area_level_1") >= 0
                      );
                      if (cityName) city = cityName.long_name;

                      let stateName: any;

                      if (country === "Portugal" || country === "Spain" || country === "Espanha") {
                        let data = getStates(country);

                        let auxIndex = -1;

                        stateName = addressComponents.find(
                          (v: any) =>
                            v.types.indexOf("administrative_area_level_1") >= 0
                        );
                        auxIndex = data.findIndex((el: any) => {
                          return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                            el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          )
                        });

                        countiesOptions =
                          data[auxIndex]?.counties.map((elem: any) => (
                            <Option key={elem._id} value={elem.name}>
                              {elem.name}
                            </Option>
                          )) || [];

                        if (auxIndex === -1) {
                          stateName = addressComponents.find(
                            (v: any) =>
                              v.types.indexOf("administrative_area_level_2") >=
                              0
                          );
                          let newAuxIndex = -1;
                          newAuxIndex = data.findIndex((el: any) => {
                            countiesOptions =
                              el.counties.map((elem: any) => (
                                <Option key={elem._id} value={elem.name}>
                                  {elem.name}
                                </Option>
                              )) || [];
                            return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                              el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            );
                          });

                          countiesOptions =
                            data[newAuxIndex]?.counties.map((elem: any) => (
                              <Option key={elem._id} value={elem.name}>
                                {elem.name}
                              </Option>
                            )) || [];

                          if (newAuxIndex === -1) {
                            stateName = null;
                          }
                        }

                        if (stateName) {
                          state = stateName.long_name;
                        }
                      }

                      const location = {
                        city,
                        country,
                        state,
                        coordinates: {
                          type: "Point",
                          coordinates: [
                            position.coords.latitude,
                            position.coords.longitude,
                          ],
                        },
                        default: true,
                      };

                      setState({
                        newItemLocation: { ...newItemLocation, location },
                        countiesOptions,
                      });
                    },
                    (error: any) => {
                      console.error(error);
                    }
                  );
                });
              }}
            />
          </div>
        </div>
        {(newItemLocation?.location?.country === "Portugal" ||
          newItemLocation?.location?.country === "Spain" ||
          newItemLocation?.location?.country === "Espanha") && (
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <label className="SingleLabel">{Strings.fields.state}</label>
              <Select
                key={`state_${newItemLocation?.location?.state}`}
                className="tagsSelector"
                style={{ width: "100%" }}
                placeholder={Strings.fields.state}
                showSearch
                defaultValue={newItemLocation?.location?.state || null}
                onChange={(elem: any) => {
                  const statesList = this.state.countries.find((elem: any) => elem.name = newItemLocation.location.country)?.states || [];

                  const state = statesList.find((s: any) => {
                    return s.name === elem;
                  });
                  const countiesOptions =
                    state?.counties.map((elem: any) => (
                      <Option key={elem._id} value={elem.name}>
                        {elem.name}
                      </Option>
                    )) || [];
                  this.setState({
                    newItemLocation: {
                      ...newItemLocation,
                      location: { ...newItemLocation.location, state: state.name },
                    },
                    countiesOptions,
                  }, async () => {
                    const response = await API.get({
                      url: Endpoints.uriUsers(user._id),
                    });

                    if (response.ok) {
                      const userData = response.data.results;

                      const location = response.data.results?.locations?.find(
                        (el: any) => el.country === user.country && el.city === user.city
                      );
                      this.setState({ userData: userData, location: location });
                      this.state.countries.map((elem: any) => {
                        if (elem.name === location?.country) {
                          elem.states.map((st: any) => {
                            if (st.name === location?.state) {
                              this.setState({
                                location: {
                                  ...location,
                                  country: elem,
                                  state: st,
                                },
                                //userData: userData,
                              });
                            }
                          });
                        }
                      });
                    } else {
                      dispatch(setLoader(false));
                      return toast.error(
                        response.data?.message || Strings.serverErrors.title
                      );
                    }
                  });
                }}
              >
                {statesOptions}
              </Select>
            </Col>
            <Col xs={24} sm={12}>
              <label className="SingleLabel">{Strings.fields.county}</label>
              <Select
                key={`state_${newItemLocation?.location?.county}`}
                className="tagsSelector"
                style={{ width: "100%", marginBottom: 10 }}
                placeholder={Strings.fields.county}
                showSearch
                defaultValue={newItemLocation?.location?.county || null}
                onChange={(elem: any) => {
                  this.setState({
                    newItemLocation: {
                      ...newItemLocation,
                      location: { ...newItemLocation.location, county: elem },
                    },
                  });
                }}
              >
                {countiesOptions}
              </Select>
            </Col>
          </Row>
        )}
      </>
    );
  }

  render() {
    const { weather, location, isIPhone5 } = this.state;
    const { dispatch } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.meteorology}</title>
          <meta name="description" content="Description of Meteorology" />
        </Helmet>
        {weather ? (
          <div className="PageHeader">
            <div
              className="PageHeaderTitle"
              onClick={() => dispatch(push("/home"))}
              style={{ cursor: "pointer" }}
            >
              <Icon name="back" style={{ fontWeight: "normal" }} />
              <div>{Strings.sidebar.meteorology}</div>
            </div>
            <div
              className={`PageHeaderFiltersButton`}
              onClick={() =>
                this.setState({ openSidebar: true, item: { location } })
              }
            >
              {isIPhone5
                ? Strings.fields.location
                : Strings.weather.changeLocation}
              <Icon className="LocationMarker" name="placeholder" />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="PageContent">
          {weather && Object.keys(weather).length ? (
            <Weather data={weather} byHour />
          ) : (
            <></>
          )}
        </div>
        {this.renderSidebar()}
        {this.renderAddLocationSidebar()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(Meteorology);
