/**
 *
 * CultureCard
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Icon } from "components";
import { Button } from "antd";
import { translate } from "utils/utils";
import "./styles.scss";
import Strings from "utils/strings";

export class CultureCard extends React.Component<any, any> {
  render() {
    const { dispatch, data, sidebar, selected, onClick } = this.props;
    return (
      <div
        className={`CultureCard ${sidebar && "Sidebar"} ${
          selected && "Selected"
        }`}
        onClick={() =>
          !sidebar ? dispatch(push(`/cultures/${data._id}`)) : {}
        }
        style={
          !sidebar
            ? {
                backgroundImage: `url(${data.image})`,
                cursor: "pointer",
              }
            : { backgroundImage: `url(${data.image})` }
        }
      >
        {sidebar ? <Icon name="check-mark" onClick={() => onClick()} /> : <></>}
        <div className={`CultureCardOverlay ${sidebar && "Sidebar"}`}>
          <div className="CultureCardName">{translate(data.name)}</div>
          {!sidebar && (
            <Button
              className="SeeDetailsButton"
              onClick={() => dispatch(push(`/cultures/${data._id}`))}
            >
              <span>{Strings.products.viewDetails.toUpperCase()}</span>
            </Button>
          )}
        </div>
        <div className="CultureCardOpacity" />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(CultureCard);
