// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SomethingWentWrong{display:flex;flex-direction:column;justify-content:center;align-items:center;height:90%}.SomethingWentWrong img{height:200px;margin:30px 0}.SomethingWentWrong h1{color:#959fa3}.SomethingWentWrong .BackHome{padding:15px;width:200px;font-weight:bold;border:1px solid #d9d9d9;text-align:center}.SomethingWentWrong .BackHome:hover{border:1px solid #000}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
