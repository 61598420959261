/* eslint-disable array-callback-return */
/*
 *
 * Promoters
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { PromoterCard, Icon } from "components";
import { EditSidebar } from "components/EditSidebar";
import { DateTime } from "luxon";
import Geocode from "react-geocode";
import { GEO_API_KEY } from "../../constants.js";
import { toast } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";
import { Button } from "antd";
import { TextField } from "@material-ui/core";

export class Promoters extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 580,
      screen: "",
      item: {},
      countries: [],
      language: "pt",
      location: {},
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    Geocode.setApiKey(GEO_API_KEY);
    Geocode.setLanguage("pt");

    // change this
    dispatch(setTitle(Strings.sidebar.promoters));

    this.handleResize();
    this.getData();
  }

  handleResize() {
    if (window.innerWidth <= 580) {
      this.setState({
        screen: "Mobile",
      });
    } else if (window.innerWidth <= 1440) {
      this.setState({
        screen: "Laptop",
      });
    } else {
      this.setState({
        screen: "Default",
      });
    }
  }

  getData = async () => {
    const { dispatch, user } = this.props;

    dispatch(setLoader(true));

    const list = [
      API.get({
        url: Endpoints.uriMisc(`countries/promoters/`),
      }),
    ];
    if (user?._id)
      list.push(
        API.get({
          url: Endpoints.uriUsers(user._id),
        })
      );

    const [countriesResponse, userResponse] = await Promise.all(list);

    if (countriesResponse.ok) {
      this.setState({
        countries: countriesResponse.data.results,
      });
    } else {
      dispatch(setLoader(false));
      return toast.error(
        countriesResponse.data?.message || Strings.serverErrors.title
      );
    }
    if (userResponse) {
      if (userResponse.ok) {
        const location = userResponse.data.results?.locations?.find(
          (el: any) => el.default && el.country === user.country && el.city === user.city
        );
        countriesResponse.data.results.map((elem: any) => {
          const country = location?.country === "Espanha" ? "Spain" : location?.country;
          if (elem.name === country) {
            if (location?.state)
              elem.states.map((st: any) => {
                if (
                  location?.state
                    ?.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    ?.includes(
                      st.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    )
                ) {
                  this.setState({
                    location: {
                      ...location,
                      country: elem,
                      state: st,
                    },
                  });
                }
              });
          }
        });
      } else {
        dispatch(setLoader(false));
        return toast.error(
          userResponse.data?.message || Strings.serverErrors.title
        );
      }
    }

    this.setPromoters();
  };

  setPromoters = async () => {
    const { location } = this.state;
    const { dispatch } = this.props;

    const response = await API.post({
      url: Endpoints.uriPromoters(`search`),
      data: {
        country: location?.country?._id,
        state: location?.state?.name,
        county: location?.county,
      },
    });

    if (response.ok) {
      this.setState({ promoters: response.data.results });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  onCloseSidebar = () => {
    this.setState({ openSidebar: null });
  };

  onCloseTypeSidebar = () => {
    this.setState({ sidebarType: null });
  };

  onChangeSidebar = (field: any, value: any) => {
    const { item } = this.state;
    this.setState({ item: { ...item, [field]: value } });
  };

  onSubmitSidebar = async () => {
    const { item } = this.state;
    await this.setState({
      location: item.location,
    });
    this.onCloseSidebar();
    await this.setPromoters();
  };

  renderFiltersFields = () => {
    const { location, item } = this.state;
    return (
      <>
        <div
          className="ProfileOptionButton"
          style={{ marginTop: 20 }}
          onClick={() => this.setState({ sidebarType: "country" })}
        >
          <TextField
            label={Strings.fields.country}
            type="text"
            value={item.location?.country?.name || location?.country?.name}
            className="ProfileInputClass CultureTextField"
            placeholder={Strings.promoters?.chooseCountry}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Icon name="back" className="backButton" />
        </div>
        <div
          className="ProfileOptionButton"
          style={{ marginTop: 20 }}
          onClick={() => this.setState({ sidebarType: "state" })}
        >
          <TextField
            label={Strings.fields.state}
            type="text"
            value={item.location?.state?.name || item.location?.state}
            className="ProfileInputClass CultureTextField"
            placeholder={Strings.promoters?.chooseState}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Icon name="back" className="backButton" />
        </div>
        <div
          className="ProfileOptionButton"
          style={{ marginTop: 20 }}
          onClick={() => this.setState({ sidebarType: "county" })}
        >
          <TextField
            label={Strings.fields.county}
            type="text"
            value={item.location?.county}
            className="ProfileInputClass CultureTextField"
            placeholder={Strings.promoters?.chooseCounty}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Icon name="back" className="backButton" />
        </div>
        {this.renderTypeSidebar()}
      </>
    );
  };

  renderSidebar() {
    return (
      <>
        <EditSidebar
          title={
            <div style={{ cursor: "default" }}>
              <b>{Strings.promoters?.choosePromoter}</b>
              <h5 style={{ color: "white" }}>
                {Strings.promoters?.choosePlace}
              </h5>
            </div>
          }
          open={this.state.openSidebar}
          onClose={this.onCloseSidebar}
          onChange={this.onChangeSidebar}
          onSubmit={this.onSubmitSidebar}
          defaultValue={this.state.item}
          closable={false}
          extraFields={this.renderFiltersFields}
          hasFields={true}
        />
      </>
    );
  }

  renderExtraFieldsCountrySidebar() {
    const { item, countries } = this.state;
    return (
      <>
        {countries.map((elem: any) => (
          <div
            className={`LocationButton ${
              item?.location?.country?.name === elem.name
            }`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => {
              const country = countries.find((c: any) => {
                return c.name === elem.name;
              });
              this.setState({
                item: {
                  ...item,
                  location: {
                    ...item.location,
                    country,
                    state: "",
                    county: "",
                  },
                },
              });
              this.onCloseTypeSidebar();
            }}
          >
            {elem.name ? `${elem.name}` : ""}
          </div>
        ))}
      </>
    );
  }

  renderExtraFieldsStateSidebar() {
    const { item } = this.state;
    return (
      <>
        {item.location?.country.states?.map((elem: any) => (
          <div
            className={`LocationButton ${
              item?.location?.state?.name === elem.name
            }`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => {
              const statesList = item?.location?.country?.states;

              const state = statesList.find((c: any) => {
                return c.name === elem.name || c.name === item?.location?.state;
              });
              this.setState({
                item: {
                  ...item,
                  location: { ...item.location, state, county: "" },
                },
              });
              this.onCloseTypeSidebar();
            }}
          >
            {elem.name ? `${elem.name}` : ""}
          </div>
        ))}
      </>
    );
  }

  renderExtraFieldsCountySidebar() {
    const { item } = this.state;
    return (
      <>
        {item.location?.state?.counties?.map((elem: any) => (
          <div
            className={`LocationButton ${
              item?.location?.county === elem.name && "__selected"
            }`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => {
              this.setState({
                item: {
                  ...item,
                  location: {
                    ...item.location,
                    county: elem.name,
                    city: elem.name,
                  },
                },
              });
              this.onCloseTypeSidebar();
            }}
          >
            {elem.name ? `${elem.name}` : ""}
          </div>
        ))}
      </>
    );
  }

  renderTypeSidebar() {
    const { sidebarType } = this.state;

    return (
      <>
        {sidebarType === "country" ||
        sidebarType === "state" ||
        sidebarType === "county" ? (
          <>
            <EditSidebar
              title={
                <div>
                  <div
                    onClick={() => {
                      this.onCloseTypeSidebar();
                    }}
                    style={{ cursor: "pointer", marginBottom: "15px" }}
                  >
                    <Icon
                      name="back"
                      style={{
                        transform: "rotate(180deg)",
                        marginRight: "8px",
                      }}
                    />
                    {Strings.generic.back}
                  </div>

                  {this.renderTitleSwitch(sidebarType)}
                </div>
              }
              open={this.state.openSidebar}
              onClose={this.onCloseTypeSidebar}
              onChange={this.onChangeSidebar}
              defaultValue={this.state.item}
              extraFields={() => this.renderFieldsSwitch(sidebarType)}
              hideFooter={true}
              hasFields={true}
              fields={[
                {},
                {
                  version: DateTime.local(),
                },
              ]}
              closable={false}
            ></EditSidebar>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  renderFieldsSwitch(type: any) {
    switch (type) {
      case "country":
        return this.renderExtraFieldsCountrySidebar();
      case "state":
        return this.renderExtraFieldsStateSidebar();
      case "county":
        return this.renderExtraFieldsCountySidebar();
      default:
        return;
    }
  }

  renderTitleSwitch(type: any) {
    switch (type) {
      case "country":
        return (
          <div style={{ cursor: "default" }}>
            <b>{Strings.fields.country?.toUpperCase()}</b>
            <h5 style={{ color: "white" }}>{Strings.fields.chooseCountry}</h5>
          </div>
        );
      case "state":
        return (
          <div style={{ cursor: "default" }}>
            <b>{Strings.fields.state?.toUpperCase()}</b>
            <h5 style={{ color: "white" }}>{Strings.fields.chooseState}</h5>
          </div>
        );
      case "county":
        return (
          <div style={{ cursor: "default" }}>
            <b>{Strings.fields.county?.toUpperCase()}</b>
            <h5 style={{ color: "white" }}>{Strings.fields.chooseCounty}</h5>
          </div>
        );
      default:
        return;
    }
  }

  auxMethod(containers: number) {
    let auxArr: any[] = [];
    for (let i = 0; i < containers; i++) {
      auxArr.push(
        <div className="no-content">
          <div className="no-content-container" />
        </div>
      );
    }
    return auxArr;
  }

  renderSwitch(screen: any) {
    switch (screen) {
      case "Mobile":
        return this.auxMethod(2);
      case "Laptop":
        return this.auxMethod(6);
      default:
        return this.auxMethod(8);
    }
  }

  render() {
    const { promoters, location, isMobile, screen } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.promoters}</title>
          <meta name="description" content="Description of Promoters" />
        </Helmet>
        <div className="PageHeader">
          <div className="PageHeaderTitle">
            <Icon name="marketing" />
            <span>{Strings.sidebar.promoters}</span>
          </div>
          <div
            className={`PageHeaderFiltersButton`}
            onClick={() =>
              this.setState({ openSidebar: true, item: { location } })
            }
          >
            {isMobile ? "" : Strings.fields.filters}
            <Icon name="edit" />
          </div>
        </div>

        {!promoters || promoters?.length === 0 ? (
          <div className="EmptyContent MobileContainer">
            {this.renderSwitch(screen)}
          </div>
        ) : (
          <div className="PromotersContent">
            {promoters?.map((product: any) => {
              return <PromoterCard data={product} main />;
            })}
          </div>
        )}

        {!promoters || promoters?.length === 0 ? (
          <div className="con align">
            {/* {Strings.promoters?.buttonText.toUpperCase()}
            <br></br> */}
            <Button
              onClick={() => this.setState({ openSidebar: "true" })}
              style={{
                backgroundColor: "#7D878C",
                color: "white",
                fontWeight: "bold",
                width: "350px",
                height: "50px",
              }}
            >
              <span>{Strings.promoters?.buttonText.toUpperCase()}</span>
            </Button>
          </div>
        ) : (
          <></>
        )}
        {this.renderSidebar()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(Promoters);
