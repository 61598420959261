/*
 *
 * Library
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { Select } from "antd";
import { Icon } from "components";
import { API, Endpoints } from "utils/api";
import { toast, translate } from "utils/utils";
import Strings from "utils/strings";
import "./styles.scss";

import fertiberiaLogo from "assets/images/logos/fertiberia-tech-white.png";

const { Option } = Select;

export class Library extends React.Component<any, any> {
  searchTimeout?: NodeJS.Timeout;

  constructor(props: any) {
    super(props);

    this.state = {
      filters: {
        sorter: "byDate",
      },
      libraryTypes: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(setTitle(Strings.sidebar.library));

    this.getLibraryTypes();
    this.filterLibrary();
  }

  componentWillReceiveProps(nextProps: any) {
    this.filterLibrary();
  }

  filterLibrary = async () => {
    const { filters } = this.state;
    const { dispatch } = this.props;

    dispatch(setLoader(true));
    try {
      const response = await API.post({
        url: Endpoints.uriLibrary("search"),
        data: filters,
      });

      if (response.ok) {
        const data = response.data.results;
        console.log("data: ", data);
        this.setState({ library: data });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      dispatch(setLoader(false));
      console.log(err);
    }

    dispatch(setLoader(false));
  };

  getLibraryTypes = async () => {
    const { dispatch } = this.props;

    dispatch(setLoader(true));
    try {
      const response = await API.get({
        url: Endpoints.uriLibraryTypes(),
      });

      if (response.ok) {
        const libraryTypes = response.data.results;
        this.setState({ libraryTypes });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      dispatch(setLoader(false));
      console.log(err);
    }
  }

  onSearch(text: string) {
    const { filters } = this.state;
    this.setState({
      filters: { ...filters, search: text },
    });

    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.filterLibrary();
    }, 500);
  }

  // getLanguageAttachedFiles(lib: any) {
  //   let langFiles: any[] = [];
  //   lib?.attachedFiles?.map((document: any) => {
  //     if (translate(document.url)) {
  //       langFiles.push(document);
  //     }
  //   });
  //   return langFiles;
  // }

  render() {
    const { library, filters, libraryTypes } = this.state;
    const { dispatch } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.library}</title>
          <meta name="description" content="Description of Library" />
        </Helmet>
        <div className="PageHeader">
          <div className="PageHeaderTitle">
            <Icon name="blackboard" />
            <div>{Strings.sidebar.library}</div>
          </div>
          <Select
            key={`state_${Strings.library.selectCategory}_${filters.category}`}
            className="tagsSelector PageHeaderFiltersSelect"
            style={{ width: "200px" }}
            defaultValue={filters.category || Strings.library.selectCategory}
            allowClear
            onChange={(elem: any) => {
              this.setState(
                {
                  filters: {
                    ...filters,
                    category: elem,
                  },
                },
                () => this.filterLibrary()
              );
            }}
          >
            {libraryTypes.map((elem: any) => {
              return <Option key={elem._id} value={elem._id}>
                {translate(elem.name)}
              </Option>
            })}
          </Select>
          {/* <Select
            key={`state_${filters.sorter}`}
            className="tagsSelector PageHeaderFiltersSelect"
            style={{ width: "150px" }}
            defaultValue={filters.sorter || null}
            onChange={(elem: any) => {
              this.setState(
                {
                  filters: {
                    ...filters,
                    sorter: elem,
                  },
                },
                () => this.filterLibrary()
              );
            }}
          >
            <Option key="byDate" value="byDate">
              {Strings.sorterFilters.byDate}
            </Option>
            <Option key="byName" value="byName">
              {Strings.sorterFilters.byName}
            </Option>
            <Option key="byType" value="byType">
              {Strings.sorterFilters.byType}
            </Option>
          </Select> */}
        </div>
        <div className="Library">
          <input
            placeholder={Strings.fields.search}
            className="LibrarySearchInput"
            value={filters.searchText}
            required
            onChange={(e: any) => this.onSearch(e.target.value)}
          />
          {library ? (
            <div className="FormationsContainer">
              {library?.map((elem: any) => translate(elem.name) ? (
                <>
                  <div
                    className="FormationCard"
                    onClick={() => dispatch(push(`/library/${elem._id}`))}
                  >
                    <div className="FormationCardImage">
                      {elem.type !== "image" ? (
                        elem.video ? (
                          <video
                            src={elem.video}
                            controls
                            preload="none"
                            style={{
                              backgroundColor: "black",
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        ) : elem.youtubeLink ? (
                          <iframe
                            title="youtubeLink"
                            src={`${elem.youtubeLink}?autoplay=0`}
                            style={{ height: "100%", width: "100%" }}
                          />
                        ) : (
                          <Icon name="film" />
                        )
                      ) : elem.image ? (
                        <>
                          <img alt={translate(elem.name)} src={elem.image} />
                          {/* <Icon name="frame placeholder" /> */}
                        </>
                      ) : (
                        <img alt={translate(elem.name)} src={fertiberiaLogo} style={{ height: 'auto' }} />
                      )}
                    </div>
                    <div className="FormationCardInfoContainer">
                      <div className="FormationName">{translate(elem.name)}</div>
                      {/* <div className="FormationCardInfo">
                        <div className="FormationCardFiles">
                          {this.getLanguageAttachedFiles(elem).length}{" "}
                          {Strings.fields.files}
                        </div>
                        {DateTime.fromISO(
                          elem.publishSchedule
                            ? elem.publishScheduleDate
                            : elem._created
                        ).toFormat("dd/MM/yyyy")}
                      </div> */}
                    </div>
                  </div>
                </>
              ) : null)}
            </div>
          ) : (
            <></>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(Library);
