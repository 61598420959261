/* eslint-disable array-callback-return */
/*
 *
 * Profile
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setUser, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Col, Row, Select, Checkbox } from "antd";
import { Icon, CultureCard } from "components";
import { EditSidebar } from "components/EditSidebar";
import { TextField } from "@material-ui/core";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { DateTime } from "luxon";
import Autocomplete from "react-google-autocomplete";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import Geocode from "react-geocode";
import { GEO_API_KEY } from "../../constants.js";
import Slider from "react-slick";
import Strings from "utils/strings";
import "./styles.scss";
import ProfileImagePlaceholder from "assets/images/placeholders/placeholder-profile.png";
import { RotateSpinner } from "react-spinners-kit";

const { Option } = Select;

export class Profile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      name: props.user?.name,
      email: props.user?.email,
      phone: props.user?.phone,
      photo: props.user?.photo,
      acceptMarketing: props.user?.acceptMarketing,

      password: "",
      newPassword: "",
      repeatNewPassword: "",

      generalChanged: false,
      passwordChanged: false,
      savingProfile: false,
      savingPassword: false,

      item: {},
      itemLocation: {},
      newItemLocation: {},

      portugalStatesCountiesList: [],
      spainStatesCountiesList: [],

      statesOptions: [],
      countiesOptions: [],

      isLaptop: window.innerWidth < 992,
      isMobile: window.innerWidth <= 600,
    };

    this.handleResize = this.handleResize.bind(this);
    this.onDrop = this.onDrop.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  async componentDidMount() {
    const { user, openCompleteProfileSidebar } = this.props;

    Geocode.setApiKey(GEO_API_KEY);
    Geocode.setLanguage("pt");

    const response = await API.get({
      url: Endpoints.uriUsers(user._id),
    });

    if (response.ok) {
      // this.setState({ news: response.data.results.news });
      this.setState({
        user: {
          ...response.data.results.user,
          location: {
            country: this.props.user.country,
            city: this.props.user.city,
          },
        },
        products: response.data.results.products,
        cultures: response.data.results.cultures,
        locations: response.data.results.locations,
      }, () => {
        if (openCompleteProfileSidebar) {
          this.setState({
            openSidebar: "completeInfo",
            item: this.state.user && JSON.parse(JSON.stringify(this.state.user))
          });
        }
      });
    } else {
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    this.getCountryInfo("Portugal");
    this.getCountryInfo("Espanha");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      isLaptop: window.innerWidth < 992,
      isMobile: window.innerWidth <= 600,
    });
  }

  getCountryInfo = async (country: string) => {
    const { dispatch } = this.props;
    let auxCountryId = country === "Portugal" ? "PT" : "ES";
    let hideLoader = true;
    try {
      const response = await API.get({
        url: Endpoints.uriMisc(`countries/general/${auxCountryId}`),
      });
      if (response.ok) {
        auxCountryId === "PT"
          ? this.setState({
              portugalStatesCountiesList: response.data.results.states,
            })
          : this.setState({
              spainStatesCountiesList: response.data.results.states,
            });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      return toast.error(String(err || Strings.serverErrors.title));
    } finally {
      if (hideLoader) dispatch(setLoader(false));
    }
  };

  getStates(country: string) {
    const { portugalStatesCountiesList, spainStatesCountiesList } = this.state;

    let data: any[] = [];

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    country === "Portugal"
      ? portugalStatesCountiesList.forEach((el: any) => {
          data.push(el);
        })
      : country === "Spain" || country === "Espanha" ?
          spainStatesCountiesList.forEach((el: any) => {
            data.push(el);
          })
          : null;

    this.setState({
      statesOptions: data.map((elem: any) => (
        <Option key={elem._id} value={elem.name}>
          {elem.name}
        </Option>
      )),
    });

    return data;
  }

  profileIsValid() {
    const { name } = this.state;

    if (!name || !name.trim()) {
      toast.warn(Strings.errors.nameIsEmpty);
      return false;
    }

    return true;
  }

  async saveProfile() {
    const { name, email, phone, photo, acceptMarketing } = this.state;
    const { user, dispatch } = this.props;

    if (this.profileIsValid()) {
      const body = new FormData();
      body.append("name", name);
      body.append("email", email);
      body.append("acceptMarketing", acceptMarketing);

      if (phone) {
        body.append("phone", phone);
      }

      if (photo) {
        body.append("photo", photo.file ? photo.file : photo);
      } else {
        body.append("photo", "");
      }

      this.setState({ savingProfile: true });

      dispatch(setLoader(true));

      const response = await API.put({
        url: Endpoints.uriUsers(user._id),
        data: body,
      });

      if (response.ok) {
        dispatch(setUser({ ...response.data.results.user }));
        toast.success(Strings.profile.generalInfoSaved);
        this.setState({
          generalChanged: false,
          user: {
            ...response.data.results.user,
            location: {
              country: this.props.user.country,
              city: this.props.user.city,
            },
          },
          products: response.data.results.products,
          cultures: response.data.results.cultures,
          locations: response.data.results.locations,
        });
      }

      this.setState({ savingProfile: false });

      dispatch(setLoader(false));
    }
  }

  passwordIsValid() {
    const { password, newPassword, repeatNewPassword } = this.state;

    if (
      !password ||
      !password.trim() ||
      !newPassword ||
      !newPassword.trim() ||
      !repeatNewPassword ||
      !repeatNewPassword.trim()
    ) {
      toast.warn(Strings.errors.pleaseFillFormCorrectly);
      return false;
    }

    if (newPassword !== repeatNewPassword) {
      toast.warn(Strings.errors.passwordsDontMatch);
      return false;
    }

    return true;
  }

  async changePassword() {
    const { password, newPassword } = this.state;
    const { dispatch } = this.props;
    if (this.passwordIsValid()) {
      const body = {
        currentPassword: password,
        password: newPassword,
      };

      this.setState({ savingPassword: true });

      dispatch(setLoader(true));

      const response = await API.put({
        url: Endpoints.uriUsers("change-password"),
        data: body,
      });

      if (response.ok) {
        toast.success(Strings.authentication.passwordChanged);
        this.setState({
          passwordChanged: false,
          password: "",
          newPassword: "",
          repeatNewPassword: "",
        });
      }

      this.setState({ savingPassword: false });

      dispatch(setLoader(false));
    }
  }

  onDrop(files: any) {
    try {
      const file = files[files.length - 1];

      new Compressor(file, {
        quality: 0.9,
        maxWidth: 400,
        mimeType: "image/jpeg",
        success: (result: any) => {
          this.getBase64(result).then((res) => {
            this.setState((state: any) => ({
              photo: { file: result, preview: res },
              generalChanged: true,
            }));
          });
        },
      });
    } catch (err) {
      toast.warning(Strings.errors.notSupportedFile);
    }
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  onCloseSidebar = () => {
    this.setState({ openSidebar: null });
  };

  onChangeSidebar = (field: any, value: any) => {
    const { item } = this.state;
    this.setState({ item: { ...item, [field]: value } });
  };

  onChangeLocationSidebar = (field: any, value: any) => {
    const { itemLocation } = this.state;
    this.setState({ itemLocation: { ...itemLocation, [field]: value } });
  };

  onChangeAddLocationSidebar = (field: any, value: any) => {
    const { newItemLocation } = this.state;
    this.setState({ newItemLocation: { ...newItemLocation, [field]: value } });
  };

  onSubmitSidebar = async (sidebarToClose: string) => {
    const { item, openSidebar } = this.state;
    const { dispatch, user } = this.props;
    dispatch(setLoader(true));

    if (openSidebar === "changePassword") {
      if (!item.password || !item.newPassword || !item.confirmPassword) {
        dispatch(setLoader(false));
        return toast.error(Strings.authentication.missing);
      }

      if (item.newPassword !== item.confirmPassword) {
        dispatch(setLoader(false));
        return toast.error(Strings.authentication.passwordDidntMatch);
      }
      const body = {
        currentPassword: item.password,
        password: item.newPassword,
      };
      const response = await API.put({
        url: Endpoints.uriUsers("/change-password"),
        data: body,
      });

      if (response.ok) {
        toast.success(Strings.authentication.passwordChanged);
        this.setState({ openSidebar: false });
      } else {
        dispatch(setLoader(false));
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } else if (openSidebar === "completeInfo") {
      if (!item.location?.country || !item.location?.city) {
        dispatch(setLoader(false));
        return toast.warning(Strings.errors.invalidFields);
      }
      const body = new FormData();
      if (item.location) body.append("location", JSON.stringify(item.location));
      body.append("products", JSON.stringify(item.products || []));
      body.append("cultures", JSON.stringify(item.cultures || []));

      const response = await API.put({
        url: Endpoints.uriUsers(user._id),
        data: body,
      });

      if (response.ok) {
        const newUser = {
          ...response.data.results.user,
          country: item.location.country,
          alpha2code: item.location.alpha2code?.toLowerCase(),
          city: item.location.city,
          location: {
            country: item.location.country,
            city: item.location.city,
          },
        };

        dispatch(setUser({ ...newUser }));
        this.setState({
          user: newUser,
          locations: response.data.results.locations,
        });
        toast.success(Strings.profile.generalInfoSaved);
        const newState = sidebarToClose === 'openAddLocationSidebar' ? { openLocationSidebar: false } : {};
        this.setState({ [sidebarToClose]: false, ...newState });
      }
    }

    dispatch(setLoader(false));
  };

  deleteLocation = async (id: any) => {
    const { dispatch, user } = this.props;

    dispatch(setLoader(true));

    const response = await API.delete({
      url: Endpoints.uriUsers(`${user._id}/location/${id}`),
    });

    if (response.ok) {
      this.setState({
        locations: response.data.results,
      });
    } else {
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  renderLocationFields() {
    const { locations, itemLocation } = this.state;

    return (
      <>
        <div className="ProductFormulas">
          {locations?.map((elem: any) => (
            <div
              className={`LocationButton ${
                itemLocation?.location?._id === elem._id && "__selected"
              }`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => {
                let countiesOptions;
                const location = elem;
                if (location) {
                  let country = location.country;

                  if (country === "Portugal" || country === "Spain" || country === "Espanha") {
                    let data = this.getStates(country);

                    let auxIndex = -1;
                    auxIndex = data.findIndex((el: any) => {
                      return location.state === el.name;
                    });

                    countiesOptions =
                      data[auxIndex]?.counties.map((elem: any) => (
                        <Option key={elem._id} value={elem.name}>
                          {elem.name}
                        </Option>
                      )) || [];

                    if (auxIndex === -1) {
                      let newAuxIndex = -1;
                      newAuxIndex = data.findIndex((el: any) => {
                        countiesOptions =
                          el.counties.map((elem: any) => (
                            <Option key={elem._id} value={elem.name}>
                              {elem.name}
                            </Option>
                          )) || [];
                      });

                      countiesOptions =
                        data[newAuxIndex]?.counties.map((elem: any) => (
                          <Option key={elem._id} value={elem.name}>
                            {elem.name}
                          </Option>
                        )) || [];
                    }
                  }
                }

                this.setState({
                  itemLocation: {
                    ...this.state.itemLocation,
                    location:
                      itemLocation?.location?._id === elem._id ? null : elem,
                  },
                  countiesOptions,
                });
              }}
            >
              {elem.city && elem.country
                ? `${elem.city}, ${elem.country}`
                : `${elem.coordinates?.coordinates[0]}, ${elem.coordinates?.coordinates[1]}`}
              <Icon
                name="trash"
                onClick={async () => {
                  this.deleteLocation(elem._id);
                }}
              />
            </div>
          ))}
        </div>
        <div
          className="AddLocationLink"
          onClick={() =>
            this.setState({ openAddLocationSidebar: true })
          }
        >
          {Strings.weather.addLocation}
        </div>
      </>
    );
  }

  renderNewLocationFields() {
    const { newItemLocation, statesOptions, countiesOptions } = this.state;

    return (
      <>
        <label className={`SingleLabel __required`}>
          {Strings.fields.location}
        </label>
        <div className="LocationBlock">
          <div className="Location">
            <Autocomplete
              className="LocationInput"
              label={Strings.fields.location}
              placeholder={Strings.fields.location}
              apiKey={GEO_API_KEY}
              language={Strings.getLanguage()}
              options={{
                language:Strings.getLanguage(),
              }}
              value={
                newItemLocation?.location?.city &&
                `${newItemLocation?.location?.city}, ${newItemLocation?.location?.country}`
              }
              onChange={(e: any) =>
                this.setState({
                  newItemLocation: { location: e.target.value },
                })
              }
              onPlaceSelected={(place: any) => {
                const aux = place.geometry.location;
                const addressComponents = place.address_components;
                let country, alpha2code, city: any, state, countiesOptions, county;

                let countryName = addressComponents.find(
                  (v: any) => v.types.indexOf("country") >= 0
                );
                if (countryName) {
                  country = countryName.long_name;
                  alpha2code = countryName.short_name?.toLowerCase();
                }

                let cityName = addressComponents.find(
                  (v: any) =>
                    v.types.indexOf("locality") >= 0 ||
                    v.types.indexOf("administrative_area_level_1") >= 0
                );
                if (cityName) city = cityName.long_name;

                let stateName: any;
                let statesList: any;

                if (country === "Portugal" || country === "Espanha") {
                  let data = this.getStates(country);

                  let auxIndex = -1;

                  stateName = addressComponents.find(
                    (v: any) =>
                      v.types.indexOf("administrative_area_level_1") >= 0
                  );
                  auxIndex = data.findIndex((el: any) => {
                    return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                      el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    );
                  });

                  countiesOptions =
                    data[auxIndex]?.counties.map((elem: any) => (
                      <Option key={elem._id} value={elem.name}>
                        {elem.name}
                      </Option>
                    )) || [];

                  county =
                    data[auxIndex]?.counties.find((elem: any) =>
                      city.includes(elem.name)
                    )?.name || null;

                  if (auxIndex === -1) {
                    stateName = addressComponents.find(
                      (v: any) =>
                        v.types.indexOf("administrative_area_level_2") >= 0
                    );
                    let newAuxIndex = -1;
                    newAuxIndex = data.findIndex((el: any) => {
                      countiesOptions =
                        el.counties.map((elem: any) => (
                          <Option key={elem._id} value={elem.name}>
                            {elem.name}
                          </Option>
                        )) || [];
                      return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                        el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      );
                    });

                    countiesOptions =
                      data[newAuxIndex]?.counties.map((elem: any) => (
                        <Option key={elem._id} value={elem.name}>
                          {elem.name}
                        </Option>
                      )) || [];

                    county =
                      data[newAuxIndex]?.counties.find((elem: any) =>
                        city.includes(elem.name)
                      )?.name || null;

                    if (newAuxIndex === -1) {
                      stateName = null;
                    }
                  }

                  if (stateName) {
                    statesList = this.getStates(country);
                    state = statesList.find((elem: any) =>
                      stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                        elem.name
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                      )
                    )?.name;
                  }
                }

                const location = {
                  city,
                  country,
                  alpha2code,
                  state,
                  county,
                  coordinates: {
                    type: "Point",
                    coordinates: [aux.lat(), aux.lng()],
                  },
                  default: true,
                };

                this.setState({
                  newItemLocation: { location },
                  countiesOptions,
                });
              }}
            />
            <Icon
              className="LocationMarker"
              name="placeholder"
              onClick={() => {
                const { newItemLocation } = this.state;
                const setState = (elem: any) => this.setState(elem);
                const getStates = (country: any) => this.getStates(country);
                navigator.geolocation.getCurrentPosition(function (position) {
                  Geocode.fromLatLng(
                    `${position.coords.latitude}`,
                    `${position.coords.longitude}`
                  ).then(
                    (response: any) => {
                      const addressComponents =
                        response.results[0].address_components;
                      let country, alpha2code, city, state, countiesOptions;

                      let countryName = addressComponents.find(
                        (v: any) => v.types.indexOf("country") >= 0
                      );
                      if (countryName) {
                        country = countryName.long_name;
                        alpha2code = countryName.short_name?.toLowerCase();
                      }

                      let cityName = addressComponents.find(
                        (v: any) =>
                          v.types.indexOf("locality") >= 0 ||
                          v.types.indexOf("administrative_area_level_1") >= 0
                      );
                      if (cityName) city = cityName.long_name;

                      let stateName: any;

                      if (country === "Portugal" || country === "Spain" || country === "Espanha") {
                        let data = getStates(country);

                        let auxIndex = -1;

                        stateName = addressComponents.find(
                          (v: any) =>
                            v.types.indexOf("administrative_area_level_1") >= 0
                        );
                        auxIndex = data.findIndex((el: any) => {
                          return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                            el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          )
                        });

                        countiesOptions =
                          data[auxIndex]?.counties.map((elem: any) => (
                            <Option key={elem._id} value={elem.name}>
                              {elem.name}
                            </Option>
                          )) || [];

                        if (auxIndex === -1) {
                          stateName = addressComponents.find(
                            (v: any) =>
                              v.types.indexOf("administrative_area_level_2") >=
                              0
                          );
                          let newAuxIndex = -1;
                          newAuxIndex = data.findIndex((el: any) => {
                            countiesOptions =
                              el.counties.map((elem: any) => (
                                <Option key={elem._id} value={elem.name}>
                                  {elem.name}
                                </Option>
                              )) || [];
                            return stateName?.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                              el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            );
                          });

                          countiesOptions =
                            data[newAuxIndex]?.counties.map((elem: any) => (
                              <Option key={elem._id} value={elem.name}>
                                {elem.name}
                              </Option>
                            )) || [];

                          if (newAuxIndex === -1) {
                            stateName = null;
                          }
                        }

                        if (stateName) {
                          state = stateName.long_name;
                        }
                      }

                      const location = {
                        city,
                        country,
                        alpha2code,
                        state,
                        coordinates: {
                          type: "Point",
                          coordinates: [
                            position.coords.latitude,
                            position.coords.longitude,
                          ],
                        },
                        default: true,
                      };

                      setState({
                        newItemLocation: { ...newItemLocation, location },
                        countiesOptions,
                      });
                    },
                    (error: any) => {
                      console.error(error);
                    }
                  );
                });
              }}
            />
          </div>
        </div>
        {(newItemLocation?.location?.country === "Portugal" ||
          newItemLocation?.location?.country === "Spain" ||
          newItemLocation?.location?.country === "Espanha") && (
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <label className="SingleLabel">{Strings.fields.state}</label>
              <Select
                key={`state_${newItemLocation?.location?.state}`}
                className="tagsSelector"
                style={{ width: "100%" }}
                placeholder={Strings.fields.state}
                showSearch
                defaultValue={newItemLocation?.location?.state || null}
                onChange={(elem: any) => {
                  const statesList =
                    newItemLocation?.location?.country === "Portugal"
                      ? this.state.portugalStatesCountiesList
                      : this.state.spainStatesCountiesList;

                  const state = statesList.find((s: any) => {
                    return s.name === elem;
                  });
                  const countiesOptions =
                    state?.counties.map((elem: any) => (
                      <Option key={elem._id} value={elem.name}>
                        {elem.name}
                      </Option>
                    )) || [];
                  this.setState({
                    newItemLocation: {
                      ...newItemLocation,
                      location: { ...newItemLocation.location, state: state.name },
                    },
                    countiesOptions,
                  });
                }}
              >
                {statesOptions}
              </Select>
            </Col>
            <Col xs={24} sm={12}>
              <label className="SingleLabel">{Strings.fields.county}</label>
              <Select
                key={`state_${newItemLocation?.location?.county}`}
                className="tagsSelector"
                style={{ width: "100%", marginBottom: 10 }}
                placeholder={Strings.fields.county}
                showSearch
                defaultValue={newItemLocation?.location?.county || null}
                onChange={(elem: any) => {
                  this.setState({
                    newItemLocation: {
                      ...newItemLocation,
                      location: { ...newItemLocation.location, county: elem },
                    },
                  });
                }}
              >
                {countiesOptions}
              </Select>
            </Col>
          </Row>
        )}
      </>
    );
  }

  renderLocationSidebar() {
    const { itemLocation, openLocationSidebar } = this.state;
    return (
      <EditSidebar
        title={Strings.fields.location}
        open={openLocationSidebar}
        onClose={() => this.setState({ openLocationSidebar: false })}
        onChange={this.onChangeLocationSidebar}
        onSubmit={() =>
          this.setState(
            {
              item: { ...this.state.item, location: itemLocation?.location },
            },
            () => this.onSubmitSidebar("openLocationSidebar")
          )
        }
        defaultValue={itemLocation}
        closable={false}
        hasFields={false}
        extraFields={() => this.renderLocationFields()}
      />
    );
  }

  renderAddLocationSidebar() {
    const { newItemLocation, openAddLocationSidebar } = this.state;
    return (
      <EditSidebar
        title={Strings.fields.location}
        open={openAddLocationSidebar}
        onClose={() => this.setState({ openAddLocationSidebar: false })}
        onChange={this.onChangeAddLocationSidebar}
        onSubmit={() =>
          this.setState(
            {
              item: { ...this.state.item, location: newItemLocation?.location },
            },
            () => this.onSubmitSidebar("openAddLocationSidebar")
          )
        }
        defaultValue={newItemLocation}
        closable={false}
        hasFields={true}
        fields={[
          {
            version: DateTime.local(),
          },
        ]}
        extraFields={() => this.renderNewLocationFields()}
      />
    );
  }

  renderCompleteProfileFields = () => {
    const { item, cultures, products, locations, isMobile } = this.state;

    const settings = {
      dots: isMobile ? true : false,
      arrows: isMobile ? false : true,
      infinite: cultures?.length >= 2,
      slidesToShow: isMobile ? 1 : 2,
      slidesToScroll: 1,
      speed: 500,
      className: "ProductsSlider CultureSlider Sidebar MarBot",
      prevArrow: (
        <button type="button" className="slick-arrow">
          <em className="moon-baixa" style={{ transform: "rotate(90deg)" }} />
        </button>
      ),
      nextArrow: (
        <button type="button" className="slick-arrow">
          <em className="moon-baixa" style={{ transform: "rotate(-90deg)" }} />
        </button>
      ),
    };
    return (
      <>
        {/* <span style={{ fontWeight: 'bold' }}>{Strings.fields.location}</span>
			<p className="SidebarSubtitle">{Strings.profile.selectYourLocation}</p> */}
        <div
          className="LocationButton"
          style={{ cursor: "pointer", width: "100%" }}
          onClick={() => {
            const location =
              locations.find(
                (elem: any) =>
                  elem.city === item?.location.city &&
                  elem.country === item?.location.country
              ) || item?.location;

            let countiesOptions;
            if (location) {
              let country = location.country;

              if (country === "Portugal" || country === "Spain" || country === "Espanha") {
                let data = this.getStates(country);

                let auxIndex = -1;
                auxIndex = data.findIndex((el: any) => {
                  return location.state === el.name;
                });

                countiesOptions =
                  data[auxIndex]?.counties.map((elem: any) => (
                    <Option key={elem._id} value={elem.name}>
                      {elem.name}
                    </Option>
                  )) || [];

                if (auxIndex === -1) {
                  let newAuxIndex = -1;
                  newAuxIndex = data.findIndex((el: any) => {
                    countiesOptions =
                      el.counties.map((elem: any) => (
                        <Option key={elem._id} value={elem.name}>
                          {elem.name}
                        </Option>
                      )) || [];
                  });

                  countiesOptions =
                    data[newAuxIndex]?.counties.map((elem: any) => (
                      <Option key={elem._id} value={elem.name}>
                        {elem.name}
                      </Option>
                    )) || [];
                }
              }
            }

            this.setState({
              openLocationSidebar: true,
              countiesOptions,
              itemLocation: { location },
            });
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: "12px", marginBottom: 0 }}>
            {Strings.fields.location}
          </p>
          {item?.location?.city && item?.location?.country ? (
            `${item.location.city}, ${item.location.country}`
          ) : (
            <p className="SidebarSubtitle">
              {Strings.profile.selectYourLocation}
            </p>
          )}
        </div>
        <span style={{ fontWeight: "bold" }}>{Strings.sidebar.cultures}</span>
        <p className="SidebarSubtitle">{Strings.profile.selectYourCultures}</p>
        <Slider {...settings}>
          {cultures?.map((culture: any) => {
            return (
              <CultureCard
                data={culture}
                sidebar
                selected={item?.cultures?.find(
                  (elem: any) => (elem._id || elem) === culture._id
                )}
                onClick={() => {
                  if (!item) return;
                  if (!item.cultures) {
                    item.cultures = [];
                  }

                  const index = item.cultures.findIndex(
                    (elem: any) => (elem._id || elem) === culture._id
                  );
                  if (index >= 0) {
                    item?.cultures.splice(index, 1);
                  } else {
                    item?.cultures.push(culture._id);
                  }
                  this.setState({ item });
                }}
              />
            );
          })}
        </Slider>
        <span style={{ fontWeight: "bold" }}>{Strings.sidebar.products}</span>
        <p className="SidebarSubtitle">{Strings.profile.selectYourProducts}</p>
        <Slider {...settings} slidesToShow={isMobile ? 2 : 3}>
          {products?.map((product: any) => {
            return (
              <div
                className={`SidebarProduct ${
                  item?.products?.find(
                    (elem: any) => (elem._id || elem) === product._id
                  ) && "__selected"
                }`}
              >
                <img alt="Product" src={product.image} />
                <Icon
                  name="check-mark"
                  onClick={() => {
                    if (!item) return;
                    if (!item.products) {
                      item.products = [];
                    }

                    const index = item.products.findIndex(
                      (elem: any) => (elem._id || elem) === product._id
                    );
                    if (index >= 0) {
                      item?.products.splice(index, 1);
                    } else {
                      item?.products.push(product._id);
                    }
                    this.setState({ item });
                  }}
                />
              </div>
            );
          })}
        </Slider>
      </>
    );
  };

  renderSidebar() {
    const { item, openSidebar } = this.state;

    const fields =
      openSidebar === "changePassword"
        ? [
            {
              field: "password",
              value: item?.password,
              type: "password",
              name: Strings.authentication.passwordPlaceholder,
              required: true,
            },
            {
              field: "newPassword",
              value: item?.newPassword,
              type: "password",
              name: Strings.authentication.newPassword,
              required: true,
            },
            {
              field: "confirmPassword",
              value: item?.confirmPassword,
              type: "password",
              name: Strings.authentication.confirmPassword,
              required: true,
            },
          ]
        : [];

    return (
      <>
        <EditSidebar
          title={
            openSidebar === "changePassword"
              ? Strings.profile.changePassword
              : Strings.profile.completeInfo
          }
          open={this.state.openSidebar}
          onClose={this.onCloseSidebar}
          onChange={this.onChangeSidebar}
          onSubmit={() => this.onSubmitSidebar("openSidebar")}
          defaultValue={this.state.item}
          closable={false}
          hasFields={true}
          fields={[
            ...fields,
            {
              version: DateTime.local(),
            },
          ]}
          extraFields={() =>
            openSidebar === "completeInfo" && this.renderCompleteProfileFields()
          }
        />
        {this.renderLocationSidebar()}
        {this.renderAddLocationSidebar()}
      </>
    );
  }

  render() {
    const {
      name,
      email,
      phone,
      photo,
      isMobile,
      isLaptop,
      generalChanged,
      savingProfile,
      acceptMarketing,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.generic.profile}</title>
          <meta name="description" content="Description of Profile" />
        </Helmet>
        <div className="ProfileContent MobileContainer">
          <div className="ProfileContentWrapper">
            <h2>{Strings.generic.profile}</h2>
            {isLaptop ? (
              <React.Fragment>
                <div className="MobilePictureContainer">
                  <Dropzone
                    accept="image/jpg, image/jpeg, image/png"
                    className="ProfileImageContainer"
                    onDrop={this.onDrop}
                  >
                    <img
                      src={
                        (photo?.preview ? photo.preview : photo) ||
                        ProfileImagePlaceholder
                      }
                      alt={Strings.placeholders.profilePic}
                    />
                  </Dropzone>
                  <div className="ProfilePictureActions">
                    <div
                      onClick={() =>
                        this.setState({ photo: "", generalChanged: true })
                      }
                      className="ProfilePictureAction"
                    >
                      <Icon name="trash" />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="ProfileImageContainer">
                <img
                  src={
                    (photo?.preview ? photo.preview : photo) ||
                    ProfileImagePlaceholder
                  }
                  alt={Strings.placeholders.profilePic}
                />
                <div className="ProfileImageOverlay">
                  <Dropzone
                    accept="image/jpg, image/jpeg, image/png"
                    className="ProfileImageAction"
                    onDrop={this.onDrop}
                  >
                    <Icon
                      name="pencil-outline"
                      className="ProfileImageActionIcon"
                    />
                  </Dropzone>
                  <div
                    onClick={() =>
                      this.setState({ photo: "", generalChanged: true })
                    }
                    className="ProfileImageAction"
                  >
                    <Icon name="trash" className="ProfileImageActionIcon" />
                  </div>
                </div>
              </div>
            )}
            <Row gutter={[40, 10]}>
              <Col xs={24} lg={10}>
                <div className="ProfileGeneralInfo">
                  {/* <div className="ProfileSectionTitle">
										<Icon name="user2" className="ProfileSectionIcon" />
										<span>{Strings.profile.generalInfo}</span>
									</div> */}
                  <form
                    onSubmit={(e: any) => {
                      e.preventDefault();

                      if (!savingProfile) {
                        this.saveProfile();
                      }
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {Strings.profile.personalData}
                    </span>
                    <TextField
                      label={Strings.authentication.name}
                      type="text"
                      value={name}
                      onChange={(e: any) =>
                        this.setState({
                          name: e.target.value,
                          generalChanged: true,
                        })
                      }
                      className="ProfileInputClass"
                      placeholder={Strings.placeholders.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                    <TextField
                      label={Strings.authentication.email}
                      type="email"
                      value={email}
                      onChange={(e: any) =>
                        this.setState({
                          email: e.target.value,
                          generalChanged: true,
                        })
                      }
                      className="ProfileInputClass"
                      placeholder={Strings.placeholders.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled
                    />
                    <TextField
                      label={Strings.profile.phone}
                      type="number"
                      value={phone}
                      placeholder="+351"
                      onChange={(e: any) =>
                        this.setState({
                          phone: e.target.value,
                          generalChanged: true,
                        })
                      }
                      className="ProfileInputClass __noMargin"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Checkbox
                      style={{ paddingTop: 10 }}
                      checked={acceptMarketing}
                      onChange={(e: any) =>
                        this.setState({
                          acceptMarketing: e.target.checked,
                          generalChanged: true,
                        })
                      }
                    >
                      {Strings.contact.acceptMarketing}
                    </Checkbox>
                    <div className="ProfileButtonContainer">
                      <button
                        type="submit"
                        disabled={!generalChanged}
                        className="LoginButtonClass"
                        style={{
                          width: isMobile ? "100%" : "200px",
                          fontWeight: "normal",
                          height: "40px",
                        }}
                        onClick={() => this.saveProfile()}
                      >
                        {savingProfile ? (
                          <div className="LoadingSpinner">
                            <RotateSpinner size={24} color="#FFFFFF" />
                          </div>
                        ) : (
                          Strings.generic.save
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
              <Col xs={24} lg={10}>
                <div className="ProfileGeneralInfo">
                  {/* <div className="ProfileSectionTitle">
										<Icon name="lock" className="ProfileSectionIcon" />
										<span>{Strings.authentication.changePassword}</span>
									</div> */}
                  {/* <form
										onSubmit={(e: any) => {
											e.preventDefault();

											if (!savingPassword) {
												this.changePassword();
											}
										}}
									>
										<span style={{ fontWeight: 'bold' }}>
											{Strings.profile.personalData}
										</span>
										<TextField
											label={Strings.authentication.password}
											type="password"
											value={password}
											onChange={(e: any) =>
												this.setState({ password: e.target.value, passwordChanged: true })
											}
											className="ProfileInputClass"
											placeholder={Strings.authentication.password}
											InputLabelProps={{
												shrink: true,
											}}
											required
										/>
										<TextField
											label={Strings.authentication.newPassword}
											type="password"
											value={newPassword}
											onChange={(e: any) =>
												this.setState({ newPassword: e.target.value, passwordChanged: true })
											}
											className="ProfileInputClass"
											placeholder={Strings.authentication.newPassword}
											InputLabelProps={{
												shrink: true,
											}}
											required
										/>
										<TextField
											label={Strings.authentication.repeatPassword}
											type="password"
											value={repeatNewPassword}
											onChange={(e: any) =>
												this.setState({ repeatNewPassword: e.target.value, passwordChanged: true })
											}
											className="ProfileInputClass __noMargin"
											placeholder={Strings.authentication.repeatPassword}
											InputLabelProps={{
												shrink: true,
											}}
											required
										/>
										<div className="ProfileButtonContainer">
											<button
												type="submit"
												disabled={!passwordChanged}
												className="LoginButtonClass"
												style={{
													width: '200px',
													fontWeight: 'normal',
													height: '40px',
												}}
												onClick={() => this.changePassword()}
											>
												{savingPassword ? (
													<div className="LoadingSpinner">
														<RotateSpinner size={24} color="#FFFFFF" />
													</div>
												) : (
													Strings.generic.save
												)}
											</button>
										</div>
									</form> */}
                  <span style={{ fontWeight: "bold" }}>
                    {Strings.profile.otherInfo}
                  </span>
                  <div
                    className="ProfileOptionButton"
                    style={{ padding: 10, marginTop: 20 }}
                    onClick={() =>
                      this.setState({
                        openSidebar: "changePassword",
                      })
                    }
                  >
                    <span>{Strings.profile.changePassword}</span>
                    <Icon name="back" style={{ transform: "rotate(180deg)" }} />
                  </div>
                  <div
                    className="ProfileOptionButton"
                    style={{ padding: 10 }}
                    onClick={() =>
                      this.setState({
                        openSidebar: "completeInfo",
                        item:
                          this.state.user &&
                          JSON.parse(JSON.stringify(this.state.user)),
                      })
                    }
                  >
                    <span>{Strings.profile.completeInfo}</span>
                    <Icon name="back" style={{ transform: "rotate(180deg)" }} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {this.renderSidebar()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  language: state.language || 'pt',
  openCompleteProfileSidebar:
    state.router?.location?.state?.openCompleteProfileSidebar,
});

export default connect(mapStateToProps)(Profile);
