import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  /* cSpell:enable */
  /* cSpell.language:pt,pt_PT */
  pt: {
    // Generic
    generic: {
      profile: "Perfil",
      save: "Guardar",
      code: "Codigo",
      submit: "Submeter",
      confirm: "Confirmar",
      cancel: "Cancelar",
      noContent: "Sem Conteúdo",
      back: "Voltar",
      selectData: "Selecionar Dados",
      changeData: "Modificar Dados",
      download1: "Ainda não tem a nossa app?",
      download2: "Instale-a e veja todo o conteúdo da web no seu smartphone!",
    },

    fields: {
      startDate: "Data de Início",
      endDate: "Data Final",
      clickToUpload: "Clique para carregar",
      filters: "Filtros",
      location: "Localização",
      newLocation: " Nova Localização",
      state: "Distrito",
      county: "Concelho",
      country: "País",
      chooseCountry: "Escolha o País",
      chooseState: "Escolha o Distrito",
      chooseCounty: "Escolha o Concelho",
      chooseLocation: "Escolha a Localização",
      results: "Resultados",
      files: "Ficheiros",
      search: "Pesquisar",
    },

    sidebar: {
      home: "Início",
      library: "Biblioteca",
      deficiencyIdentification: "Identificação de Carências",
      soils: "Solos",
      agronomicServices: "Serviços Agronómicos",
      aboutUs: "Quem Somos",
      products: "Produtos",
      adpClassic: "ADP Classic",
      contacts: "Contactos",
      cultures: "Culturas",
      promoters: "Promotores",
      meteorology: "Meteorologia",
      news: "Notícias",
      privacy: "Politica de Privacidade",
      terms: "Termos e Condições",
      contact: "Contacto",
    },

    language: {
      header: "Idioma",
      defaultLanguage: "Idioma Padrão",
      languageNotActive:
        "Guarde as atualizações antes de proceder à alteração do idioma padrão.",
      portuguese: "Português",
      english: "Inglês",
      german: "Alemão",
      french: "Francês",
      spanish: "Espanhol",
    },

    // Screens
    authentication: {
      welcome: "Bem-vindo",
      header: "Autenticação",
      register: "Registo",
      login: "Entrar",
      login_Register: "Registe-se",
      registerHere: "Registe-se aqui",
      logout: "Sair",
      signIn: "Autenticar",
      signOut: "Sair",
      confirmAccount: "Confirmar Conta",
      name: "Nome",
      namePlaceholder: "O seu nome",
      email: "Email",
      emailPlaceholder: "O seu email",
      password: "Senha",
      passwordPlaceholder: "A sua senha",
      newPassword: "Nova senha",
      newPasswordPlaceholder: "Inserir nova senha",
      confirmPassword: "Confirmar senha",
      confirmPasswordPlaceholder: "Repetir a senha",
      forgotPassword: "Esqueci-me da senha",
      loginFooter: "Não tem conta. Registe-se",
      registerFooter: "Já tem conta? Autentique-se aqui.",
      resendCode1: "Não recebeu o seu código de confirmação? ",
      resendCode2: "Reenviar código de ativação",
      sendEmail: "Enviar Email",
      recoverPassword: "Recuperar senha",
      changePassword: "Mudar senha",
      changeEmail: "Mudar Email",
      newEmail: "Novo Email",
      newEmailConfirm: "Confirmar Novo Email",
      repeatEmail: "Repetir Email",
      currentEmail: "Email atual",
      currentPassword: "Senha abouttual",
      emailSent: "E-mail enviado. Se não recebeu o e-mail é porque o endereço não existe",
      repeatPassword: "Repetir senha",
      rememberedPassword: "Lembrou-se da senha?",
      adminPassword: "Senha do Administrador",
      notLoggedIn:
        "Necessita de estar autenticado para aceder a esta página",
      noMatchPassword: "As senhas não coincidem",
      acceptSubtitle:
        "Por favor insira uma senha para confirmar a sua conta.",
      goBackToLogin: "Ir para o ecrã de início de sessão",
      newAccount: "Registe-se",
      registeredSuccessfully:
        "Conta registada com sucesso. Por favor verifique a sua caixa de email para confirmar a conta.",
      passwordChanged: "Senha alterada com sucesso.",
      accountConfirmed: "Conta confirmada com sucesso.",
      iHaveAnAccount: "Já tenho conta. Voltar ao Login",
      passwordDidntMatch: "As senhas não correspondem",
      missing: "Dados em falta",
      signInApple: 'Entrar Apple',
      yourLocation: 'A SUA LOCALIZAÇÃO',
      toGetAllAppBenefitsRegister: 'Para usufruir dos benefícios desta App, registe-se!',
    },

    safetyDatasheets: {
      header: "Fichas de Especificação e Segurança",
      description: "Conheça todas as características e propriedades dos produtos e siga as instruções detalhadas para um bom funcionamento dos mesmos.",
      seeDatasheets: "Ver Fichas",
    },

    notFound: {
      header: "Página Inexistente",
    },

    errors: {
      invalidArgs: "Argumentos inválidos ou em falta",
      invalidFields: "Campos inválidos ou em falta",
      emailIsInvalid: "O formato de email inserido não é válido",
      fillEmail: "Insira um email por favor",
      fillPassword: "Insira uma senha por favor",
      pleaseFillFormCorrectly: "Por favor preencha o formulário corretamente",
      fillField: "Este campo é de preenchimento obrigatório",
      notSupportedFile: "Tipo de ficheiro não suportado",
      nameIsEmpty: "O campo nome é de preenchimento obrigatório",
      passwordsDontMatch:
        "Os campos 'Nova senha' e 'Confirmar senha' não coincidem",
      emailIsEmpty: "O campo email é de preenchimento obrigatório",
      fileTooBigorInvalid:
        "O tamanho da imagem é superior a 8 MB ou tem um formato inválido",
      selectLocation:
        "Selecione uma localização",
      noExistAccount: "Conta não existente, por favor registe-se",
    },

    profile: {
      generalInfo: "Informação Geral",
      personalData: "Dados Pessoais",
      otherInfo: "Outras Informações",
      phone: "Número de Telefone",
      removePicture: "Remover Imagem",
      generalInfoSaved: "Informação geral guardada com sucesso.",
      changePassword: "Mudar senha",
      completeInfo: "Completar Informação",
      selectYourLocation: "Selecione a sua localização por defeito",
      selectYourCultures: "Selecione as suas culturas favoritas",
      selectYourProducts:
        "Selecione os seus produtos Fertiberia TECH® favoritos",
      country: "País",
    },

    news: {
      title: "Notícias",
      seeNews: "Ver Notícia",
    },

    products: {
      header: "Produto",
      adpProducts: "Produtos ADP Classic",
      title: "Produtos Favoritos",
      text: "Selecione os seus produtos Fertiberia TECH preferidos, para lhe darmos a conhecer a melhor forma de aplicá-los e assim obter o máximo aproveitamento do seu investimento.",
      viewAll: "Ver todos os produtos",
      viewDetails: "Ver detalhe",
      yourProducts: "Os seus produtos",
      noFavoriteProducts: "Ainda não tem produtos favoritos",
      addProducts: "Adicionar Produtos",
      formulas: "Fórmulas",
      documentation: "Documentação",
      technicalDesignation: "Designação Comercial",
      formulation: "Produto Recomendado",
      season: "Época",
      maxConcentration: "Concentração Máxima",
      dose: "Dose",
      observation: "Observações",
    },

    formulas: {
      header: "Fórmula",
      details: "Composição",
      culturesAndDoses: "Culturas e doses de utilização",
      formulaInformation: "Mais Informação",
    },

    deficiencies: {
      title: "Identificação de carências",
      text: "Ajudamo-lo a identificar as carências e toxicidades nutricionais das suas culturas. Clique no botão para saber mais.",
      buttonTxt: "Saber mais",
      cultureWithoutNutrients: 'Esta cultura não tem nutrientes'
    },

    cultures: {
      header: "Cultura",
      title: "Culturas Favoritas",
      text: "Selecione as suas culturas preferidas para aceder mais facilmente ao seu conteúdo. Poderá, por exemplo, encontrar as nossas recomendações de adubação, ajustadas à sua localização, para a otimização do seu rendimento.",
      viewAll: "Ver todas as culturas",
      yourCultures: "As suas culturas",
      noFavoriteCultures: "Ainda não tem culturas favoritos",
      addCulture: "Adicionar Culturas",
      deficienciesAndToxicities: "Carências e Toxicidades",
      nutrientsImportantRemovel: "Nutrientes Importantes",
      soilFertilization: "Fertilidade dos Solos",
      extractNutrients: "Extração de Nutrientes",
      fertilizing: "Adubação",
      deficiencies: "Carências",
      toxicities: "Toxicidades",
      mostImportantNutrients: "Nutrientes mais importantes",
      removelNutrients: "Extração de Nutrientes",
      hasNoNutrientDescription: "Este nutriente não tem descrição",
      correction: "Correção",
      corrections: "Correção e Prevenção",
      products: "Produtos Fertiberia TECH® usados nesta cultura",
      hasNoProducts: "Não existem produtos disponiveis para uso nesta cultura",
      buttonText: "Pesquise culturas",
      expectedProduction: "Produção esperada",
      chooseExpectedProduction: "Indique a produção esperada",
      usualFertilizing: "Adubação habitual",
      destination: "Destino da produção",
      chooseDestination: "Indique o destino da produção",
      fertilizingFilters: "Opções de adubação",
      soilNutrient: "Nutriente do Solo",
      lessFrequent: "Menos Frequente",
      frequent: "Frequente",
      veryFrequent: "Muito Frequente",
      nutrient: "Nutriente",
      fertilizingType: "Tipo de Adubação",
    },

    promoters: {
      buttonText: "Pesquise Promotores",
      choosePromoter: "Procurar Promotores",
      choosePlace: "Indique País, Distrito e Concelho",
      chooseCountry: "Indique o País",
      chooseState: "Indique o Distrito",
      chooseCounty: "Indique o Concelho",
    },

    deficiencyIdentification: {
      startDiagnosis: "Visualizar",
      restartDiagnosis: "Recomeçar Diagnóstico",
      filter: "Filtrar",
      byCulture: "Por Cultura",
      byNutrient: "Por Nutriente",
      byDichotomousKey: "Por Chave Dicotómica para diagnóstico",
      choose: "Escolher",
    },

    library: {
      training: "Formação",
      selectCategory: "Selecionar categoria",
    },

    agronomicServices: {
      analysisTypes: "Tipos de análise",
      documentation: "Documentação",
      header: "Serviço Agronómico",
      values: "Parâmetros",
      observation: "Observação",
      complete: "Completa",
      routine: "Rotina",
      special: "Especial",
    },

    sorterFilters: {
      byDate: "Por Data",
      byName: "Por Nome",
      byType: "Por Tipo",
    },

    dichotomousKey: {
      selectSymptom: "Chave Dicotómica: Selecione um sintoma",
    },

    ranges: {
      today: "Hoje",
      tomorrow: "Amanhã",
      thisMonth: "Este mês",
      lastMonth: "Último mês",
      thisYear: "Este ano",
      lastYear: "Último ano",
    },

    weekDays: {
      0: "Seg",
      1: "Ter",
      2: "Qua",
      3: "Qui",
      4: "Sex",
      5: "Sab",
      6: "Dom",
    },

    monthNames: {
      "1": "Janeiro",
      "2": "Fevereiro",
      "3": "Março",
      "4": "Abril",
      "5": "Maio",
      "6": "Junho",
      "7": "Julho",
      "8": "Agosto",
      "9": "Setembro",
      "10": "Outubro",
      "11": "Novembro",
      "12": "Dezembro",
    },

    weather: {
      forecast: "Previsão",
      history: "Histórico",
      changeLocation: "Altere localização",
      addLocation: "Adicione uma localização",
      cloudy: "Nublado",
    },

    placeholders: {
      name: "o seu nome",
      email: "o_seu@email.com",
      phoneNumber: "+351265000000",
      language: "Selecione um idioma",
      role: "Selecione um cargo",
      currentPassword: "senha actual",
      newPassword: "nova senha",
      confirmPassword: "confirme nova senha",
      newEmail: "novo@email.com",
      page: "pagina",
      titlePage: "Título da Página",
      link: "https://www.google.com/",
      profilePic: "Imagem de Perfil",
      password: "A sua senha",
    },

    footer: {
      social: "Social",
      about: "Sobre",
      terms: "Termos e Condições",
      privacy: "Política de Privacidade",
      copyright: "Copyright ",
      fertiberiaTech: "Fertiberia TECH®",
      sendUsEmail: "Contacte-nos",
      services: "Serviços",
    },

    contact: {
      name: "Nome",
      email: "Email",
      subject: "Assunto",
      message: "Mensagem",
      sendMessage: "Enviar Mensagem",
      send: "Enviar",
      success: "Mensagem enviada com sucesso",
      acceptTerms: "Tomei conhecimento da",
      acceptMarketing: "Aceito receber comunicações de marketing",
    },

    serverErrors: {
      title: "Erro de servidor",
      subTitle: "Não é você, somos nós!",
      refresh: "Voltar à página anterior",
      backToHome: "Ir para o Início",
      accountNotConfirmed: "Conta não confirmada.",
      notFound: "Conta não existente.",
      invalidCredentials: "Conta não existe ou credenciais inválidas.",
      somethingWentWrong: "Algo correu mais",
    },
  },

  /* cSpell.language:es */
  es: {
    // Generic
    generic: {
      profile: "Perfil",
      save: "Guardar",
      code: "Código",
      submit: "Enviar",
      confirm: "Confirmar",
      cancel: "Cancelar",
      noContent: "Sin Contenido",
      back: "Volver",
      selectData: "Selecionar Datos",
      changeData: "Modificar Datos",
      download1: "¿Todavía no tiene nuestra aplicación?",
      download2: "¡Instálela y vea todo el contenido de la web en su smartphone!",
    },

    fields: {
      startDate: "Fecha de Inicio",
      endDate: "Fecha Final",
      clickToUpload: "Click para subir",
      filters: "Filtros",
      location: "Localización",
      newLocation: "Nueva Localización",
      state: "Municipio",
      county: "Provincia",
      country: "País",
      chooseCountry: "Escoger País",
      chooseState: "Escoger Municipio",
      chooseCounty: "Escoger Provincia",
      chooseLocation: "Escoger Localización",
      results: "Resultados",
      files: "Archivos",
      search: "Buscar",
    },

    sidebar: {
      home: "Inicio",
      library: "Biblioteca",
      deficiencyIdentification: "Identificación de Carencias",
      soils: "Suelos",
      agronomicServices: "Servicios Agronómicos",
      aboutUs: "Sobre Nosotros",
      products: "Productos",
      adpClassic: "ADP Classic",
      contacts: "Contacte con",
      cultures: "Cultivos",
      promoters: "Delegados",
      meteorology: "Meteo",
      news: "Noticias",
      privacy: "Politica de Privacidad",
      terms: "Terminos y Condiciones",
      contact: "Contacto",
    },

    language: {
      header: "Idioma",
      defaultLanguage: "Idioma por defecto",
      languageNotActive:
        "Guarde sus cambios antes de seguir",
      portuguese: "Portugués",
      english: "Inglés",
      german: "Alemán",
      french: "Francés",
      spanish: "Español",
    },

    // Screens
    authentication: {
      welcome: "Bienvenido",
      header: "Autentificación",
      register: "Registro",
      login: "Conectar",
      login_Register: "Registrarse",
      registerHere: "Regístrese aquí",
      logout: "Salir",
      signIn: "Autentificar",
      signOut: "Salir",
      confirmAccount: "Confirmar Cuenta",
      name: "Nombre",
      namePlaceholder: "Su nombre",
      email: "Email",
      emailPlaceholder: "Su email",
      password: "Contraseña",
      passwordPlaceholder: "Su Contraseña",
      newPassword: "Nueva Contraseña",
      newPasswordPlaceholder: "Insertar Nueva Contraseña",
      confirmPassword: "Confirmar Contraseña",
      confirmPasswordPlaceholder: "Repetir Contraseña",
      forgotPassword: "Contraseña Olvidada",
      loginFooter: "¿No está registrado? Crear Cuenta¿",
      registerFooter: "¿Ya tiene una cuenca? Acceder.",
      resendCode1: "¿No ha recibido el código de confirmación?",
      resendCode2: "Reenviar Código de Confirmación",
      sendEmail: "Enviar Email",
      recoverPassword: "Recuperar Contraseña",
      changePassword: "Cambiar Contraseña",
      changeEmail: "Cambiar Email",
      newEmail: "Nuevo Email",
      newEmailConfirm: "Confirmar Nuevo Email",
      repeatEmail: "Repetir Email",
      currentEmail: "Email Actual",
      currentPassword: "Contraseña Actual",
      emailSent: "E-mail enviado. Si no ha recibido el e-mail, la dirección no existe",
      repeatPassword: "Repetir Contraseña",
      rememberedPassword: "¿Recuerda su contraseña?",
      adminPassword: "Contraseña de Admistrador",
      notLoggedIn:
        "Necesita estar autentificado para acceder a esta página",
      noMatchPassword: "Las contraseñas no coinciden",
      acceptSubtitle:
        "Por favor, escriba su contraseña para confirmar su cuenta.",
      goBackToLogin: "Ir a la página de inicio",
      newAccount: "Registrarse",
      registeredSuccessfully:
        "Cuenta registrada con éxito. Por favor, valide al cuenta en el email recibido.",
      passwordChanged: "Contraseña cambiada correctamente.",
      accountConfirmed: "Cuenta registrada con éxito.",
      iHaveAnAccount: "Ya tengo una cuenta. Volver al inicio",
      passwordDidntMatch: "Las contraseñas no coinciden",
      missing: "Faltan datos",
      signInApple: 'Conectar Apple',
      yourLocation: 'SU LOCALIZACIÓN',
      toGetAllAppBenefitsRegister: '¡Para disfrutar de los beneficios de esta aplicación, regístrese!',
    },

    safetyDatasheets: {
      header: "Ficha de especificación de producto y datos de seguridad",
      description: "Conozca todas las características y propiedades de los productos y siga las instrucciones detalladas para su correcto funcionamiento.",
      seeDatasheets: "Ver hojas informativas",
    },

    notFound: {
      header: "La página no existe",
    },

    errors: {
      invalidArgs: "Datos erroneos o inexistentes",
      invalidFields: "Campos erróneos o inexistentes",
      emailIsInvalid: "El formato del Email es incorrecto",
      fillEmail: "Por favor, inserte un Email",
      fillPassword: "Por favor, inserte una contraseña",
      pleaseFillFormCorrectly: "Por favor, rellene el formulario correctamente",
      fillField: "Este campo es obligatorio",
      notSupportedFile: "Tipo de archivo no soportado",
      nameIsEmpty: "El campo Nombre es obligatorio",
      passwordsDontMatch:
        "Los campos \"Nueva contraseña\" y \"Confirmar contraseña\" no coinciden",
      emailIsEmpty: "El campo \"Email\" es obligatorio",
      fileTooBigorInvalid:
        "El tamaño de la imagen es superior a 8 MB o es un archivo no soportado",
      selectLocation:
        "Elegir una localización",
      noExistAccount: "Cuenta inexistente, por favor regístrese",
    },

    profile: {
      generalInfo: "Información general",
      personalData: "Datos personales",
      otherInfo: "Otras informaciones",
      phone: "Número de teléfono",
      removePicture: "Borrar imagen",
      generalInfoSaved: "Información general guardada.",
      changePassword: "Cambiar contraseña",
      completeInfo: "Completar información",
      selectYourLocation: "Seleccionar su localización por defecto",
      selectYourCultures: "Seleccionar sus cultivos favoritos",
      selectYourProducts:
        "Selecione su productos Fertiberia TECH® favoritos",
      country: "País",
    },

    news: {
      title: "Noticias",
      seeNews: "Ver noticia",
    },

    products: {
      header: "Producto",
      adpProducts: "Productos ADP Classic",
      title: "Productos favoritos",
      text: "Seleccione sus productos preferidos de Fertiberia TECH, para que podamos mostrarle la mejor manera de aplicarlos para sacar el máximo partido a su inversión.",
      viewAll: "Ver todos los productos",
      viewDetails: "Ver detalles",
      yourProducts: "Sus productos",
      noFavoriteProducts: "Todavía no hay productos favoritos ",
      addProducts: "Añadir productos a favoritos",
      formulas: "Formulas",
      documentation: "Documentación",
      technicalDesignation: "Nombre Comercial",
      formulation: "Producto recomendado",
      season: "Época",
      maxConcentration: "Concentración máxima",
      dose: "Dosis",
      observation: "Observaciones",
    },

    formulas: {
      header: "Fórmula",
      details: "Composición",
      culturesAndDoses: "Cultivos y dosis de aplicación",
      formulaInformation: "Más información",
    },

    deficiencies: {
      title: "Identificación de las carencias",
      text: "Le ayudamos a identificar las deficiencias nutricionales y las toxicidades de sus cultivos. Haga clic en el botón para saber más.",
      buttonTxt: "Saber más",
      cultureWithoutNutrients: 'Este cultivo no tiene nutrientes'
    },

    cultures: {
      header: "Cultivo",
      title: "Cultivos preferidos",
      text: "Seleccione sus cultivos favoritos para acceder a su contenido más fácilmente. Puede, por ejemplo, encontrar nuestras recomendaciones de fertilización, ajustadas a su ubicación, para la optimización de su rendimiento.",
      viewAll: "Ver todos los cultivos",
      yourCultures: "Sus cultivos",
      noFavoriteCultures: "No tiene cultivos favoritos",
      addCulture: "Añadir cultivos",
      deficienciesAndToxicities: "Carencias y Toxicidades",
      nutrientsImportantRemovel: "Nutrientes más imporantes",
      soilFertilization: "Fertilidad de suelos",
      extractNutrients: "Extracción de nutrientes",
      fertilizing: "Fertilización",
      deficiencies: "Carencias",
      toxicities: "Toxicidades",
      mostImportantNutrients: "Nutrientes más importantes",
      removelNutrients: "Extración de nutrientes",
      hasNoNutrientDescription: "Este nutriente no tiene descripción",
      correction: "Corrección",
      corrections: "Correcciones y prevenciones",
      products: "Productos Fertiberia TECH® recomendados para este cutlivo",
      hasNoProducts: "No hay productos disponibles para este cultivo",
      buttonText: "Búsqueda de cultivos",
      expectedProduction: "Producción esperada",
      chooseExpectedProduction: "Indique la producción esperada",
      usualFertilizing: "Fertilización habitual",
      destination: "Destino de la producción",
      chooseDestination: "Indique el destino de la producción",
      fertilizingFilters: "Opciones de abonado",
      soilNutrient: "Nutrientes del suelo",
      lessFrequent: "Menos frecuente",
      frequent: "Frecuente",
      veryFrequent: "Muy Frecuente",
      nutrient: "Nutriente",
      fertilizingType: "Tipo de Fertilización",
    },

    promoters: {
      buttonText: "Búsqueda de Delegados Comerciales",
      choosePromoter: "Búsqueda de Delegados",
      choosePlace: "Indique el país, el distrito y la provincia",
      chooseCountry: "Indique el país",
      chooseState: "Indique el municipio",
      chooseCounty: "Indique la provincia",
    },

    deficiencyIdentification: {
      startDiagnosis: "Visualizar",
      restartDiagnosis: "Reiniciar diagnóstico",
      filter: "Filtrar",
      byCulture: "Por Cultivo",
      byNutrient: "Por Nutriente",
      byDichotomousKey: "Por Llave Dicotómica",
      choose: "Escoger",
    },

    library: {
      training: "Formación",
      selectCategory: "Seleccionar categoría",
    },

    agronomicServices: {
      analysisTypes: "Tipo de análisis",
      documentation: "Documentación",
      header: "Servicio Agromómico",
      values: "Parámetros",
      observation: "Observación",
      complete: "Completa",
      routine: "Rutina",
      special: "Especial",
    },

    sorterFilters: {
      byDate: "Por Fecha",
      byName: "Por Nombre",
      byType: "Por Tipo",
    },

    dichotomousKey: {
      selectSymptom: "Clave Dicotómica: Seleccione un Síntoma",
    },

    ranges: {
      today: "Hoy",
      tomorrow: "Mañana",
      thisMonth: "Este mes",
      lastMonth: "Mes anterior",
      thisYear: "Este año",
      lastYear: "Año anterior",
    },

    weekDays: {
      0: "Lun",
      1: "Mar",
      2: "Mie",
      3: "Jue",
      4: "Vie",
      5: "Sab",
      6: "Dom",
    },

    monthNames: {
      "1": "Enero",
      "2": "Febrero",
      "3": "Marzo",
      "4": "Abril",
      "5": "Mayo",
      "6": "Junio",
      "7": "Julio",
      "8": "Agosto",
      "9": "Septiembre",
      "10": "Octubre",
      "11": "Noviembre",
      "12": "Diciembre",
    },

    weather: {
      forecast: "Previsión",
      history: "Histórico",
      changeLocation: "Cambiar Localización",
      addLocation: "Añadir Localización",
      cloudy: "Nublado",
    },

    placeholders: {
      name: "Su nombre",
      email: "su@email.com",
      phoneNumber: "+341265000000",
      language: "Seleccionar un idioma",
      role: "Seleccionar un cargo",
      currentPassword: "Contraseña actual",
      newPassword: "Nueva contraseña",
      confirmPassword: "Comfirmar nueva contraseña",
      newEmail: "nuevo@email.com",
      page: "página",
      titlePage: "Título de la página",
      link: "https://www.google.com/",
      profilePic: "Imagen de perfil",
      password: "Su Contraseña",
    },

    footer: {
      social: "Social",
      about: "Sobre nosotros",
      terms: "Terminos y Condiciones",
      privacy: "Política de Privacidad",
      copyright: "Copyright ",
      fertiberiaTech: "Fertiberia TECH®",
      sendUsEmail: "Contactar",
      services: "Servicios",
    },

    contact: {
      name: "Nombre",
      email: "Email",
      subject: "Asunto",
      message: "Mensaje",
      sendMessage: "Enviar Mensaje",
      send: "Enviar",
      success: "Mensaje enviado con éxito",
      acceptTerms: "Me he enterado de la",
      acceptMarketing: "Acepto recibir comunicaciones de marketing",
    },

    serverErrors: {
      title: "Error del servidor",
      subTitle: "No es usted, ¡somos nosotros!",
      refresh: "Volver a la página anterior",
      backToHome: "Ir al inicio",
      accountNotConfirmed: "Cuenta no confirmada",
      notFound: "Cuenta no existente",
      invalidCredentials: "Cuenta inexistente o datos erróneos",
      somethingWentWrong: "Algo ha ido mal",
    },
  },

  /* cSpell.language:fr */
  fr: {
    // Generic
    generic: {
      profile: "Porfil",
      save: "Sauvegarder",
      code: "Code",
      submit: "Enregistrez",
      confirm: "Confirmer",
      cancel: "Annuler",
      noContent: "Aucun contenu",
      back: "Retourner",
      selectData: "sélectionner les données",
      changeData: "Modifier les données",
      download1: "Vous n'avez pas encore notre application?",
      download2: "Installez et voyez tout le contenu du web sur votre smartphone!",
    },

    fields: {
      startDate: "Date de début",
      endDate: "Date de fin",
      clickToUpload: "Cliquez pour charger",
      filters: "Filtres",
      location: "Localisation",
      newLocation: "Nouvelle localisation",
      state: "District",
      county: "Commune",
      country: "Pays",
      chooseCountry: "Choisir le Pays",
      chooseState: "Choisir le District",
      chooseCounty: "Choisir la Commune",
      chooseLocation: "Choisir la localisation",
      results: "Résultats",
      files: "Fichier",
      search: "Recherche",
    },

    sidebar: {
      home: "Accueil",
      library: "Bibliothèque",
      deficiencyIdentification: "Identification des carences",
      soils: "Sols",
      agronomicServices: "Services agronomiques",
      aboutUs: "À propos",
      products: "Produits",
      adpClassic: "ADP Classic",
      contacts: "Contactez",
      cultures: "Culture",
      promoters: "Promoteur",
      meteorology: "Météo",
      news: "Informations",
      privacy: "Politique de confidentialité",
      terms: "Termes et coditions",
      contact: "Contacter",
    },

    language: {
      header: "Langue",
      defaultLanguage: "Langue dar défault",
      languageNotActive:
        "Enregistrez les mises à jour avant de modifier la langue par défaut.",
      portuguese: "Portugais",
      english: "Englais",
      german: "Allemand",
      french: "Français",
      spanish: "Espagnol",
    },

    // Screens
    authentication: {
      welcome: "Bienvenue",
      header: "Authentication",
      register: "Enregistrez",
      login: "Connexion",
      login_Register: "Créer une compte",
      registerHere: "Inscrivez-vous ici",
      logout: "Déconnecter",
      signIn: "Connecter",
      signOut: "Déconnecter",
      confirmAccount: "Confirmer le compte",
      name: "Nom",
      namePlaceholder: "Votre nom",
      email: "Email",
      emailPlaceholder: "Votre email",
      password: "Mot de passe",
      passwordPlaceholder: "Votre mot de passe",
      newPassword: "Nouveau mot de passe",
      newPasswordPlaceholder: "Enregistrer un nouveau mot de passe",
      confirmPassword: "Confirmer le mot de passe",
      confirmPasswordPlaceholder: "Répéter le mot de passe",
      forgotPassword: "Mot de passe óblier",
      loginFooter: "Pas encore de compte? Enregistrez",
      registerFooter: "Vous avez déjà un compte? Connectez-vous ici.",
      resendCode1: "Vous n'avez pas reçu votre code de confirmation ? ",
      resendCode2: "Renvoyer le code d'activation",
      sendEmail: "Envoyer un email",
      recoverPassword: "Récupérer le mot de passe",
      changePassword: "Changer mot de passe",
      changeEmail: "Changer email",
      newEmail: "Nouvel email",
      newEmailConfirm: "Conformer le nouvel email",
      repeatEmail: "Répéter email",
      currentEmail: "Email actuel",
      currentPassword: "Mot de passe actuel",
      emailSent: "E-mail envoyé. Si vous n'avez pas reçu l'e-mail, l'adresse n'existe pas",
      repeatPassword: "Répéter le mot de passe",
      rememberedPassword: "Vous avez souvenez le mot de passe ?",
      adminPassword: "Mot de passe administrateur",
      notLoggedIn:
        "Vous devez être connecté pour accéder à cette page.",
      noMatchPassword: "Les mots de passe ne correspondent pas.",
      acceptSubtitle:
        "Veuillez entrer un mot de passe pour confirmer votre compte.",
      goBackToLogin: "Aller à l'écran de connexion",
      newAccount: "Enregistrez",
      registeredSuccessfully:
        "Compte enregistré avec succès. Veuillez vérifier votre email pour confirmer le compte.",
      passwordChanged: "Le mot de passe a été changé avec succès.",
      accountConfirmed: "Compte enregistré avec succès.",
      iHaveAnAccount: "J'ai déjà un compte. Retour à la page de connexion",
      passwordDidntMatch: "Les mots de passe ne correspondent pas",
      missing: "Données manquantes",
      signInApple: 'Connexion Apple',
      yourLocation: 'VOTRE LOCALISATION',
			toGetAllAppBenefitsRegister: 'Pour profiter des avantages de cette appli, inscrivez-vous !',
    },

    safetyDatasheets: {
      header: "Fiches de spécifications et de sécurité",
      description: "Connaître toutes les caractéristiques et propriétés des produits et suivre les instructions détaillées pour un bon fonctionnement des produits.",
      seeDatasheets: "Voir les fiches",
    },

    notFound: {
      header: "Page n'existe pas",
    },

    errors: {
      invalidArgs: "Arguments non valides ou manquants",
      invalidFields: "Champs non valides ou manquants",
      emailIsInvalid: "Le format de l' email saisi n'est pas valide",
      fillEmail: "Veuillez entrer un email",
      fillPassword: "Veuillez entrer un mot de passe",
      pleaseFillFormCorrectly: "Veuillez remplir le formulaire correctement",
      fillField: "Ce champ est obligatoire",
      notSupportedFile: "Type de fichier non supporté",
      nameIsEmpty: "Le champ Nom est obligatoire",
      passwordsDontMatch:
        "Les champs \"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas.",
      emailIsEmpty: "Le champ \"email\" est obligatoire",
      fileTooBigorInvalid:
        "La taille de l'image est supérieure à 8 MB ou son format n'est pas valide.",
      selectLocation:
        "Choisir une localisation",
      noExistAccount: "Compte inexistant, veuillez vous enregistrer",
    },

    profile: {
      generalInfo: "Informations générales",
      personalData: "Données personnelles",
      otherInfo: "Autres informations",
      phone: "Numéro de téléphone",
      removePicture: "Supprimer l'image",
      generalInfoSaved: "Les informations générales ont été sauvegardées avec succès.",
      changePassword: "Changer mot de passe",
      completeInfo: "Compleéter informations",
      selectYourLocation: "Sélectionnez votre localisation par défaut",
      selectYourCultures: "Sélectionnez vos cultures préférées",
      selectYourProducts:
        "Sélectionnez vos produits Fertiberia TECH® préférés",
      country: "Pays",
    },

    news: {
      title: "Informations",
      seeNews: "Voir informations",
    },

    products: {
      header: "Produit",
      adpProducts: "Produits ADP Classic",
      title: "Produits préférés",
      text: "Sélectionnez vos produits Fertiberia TECH préférés, afin que nous puissions vous montrer la meilleure façon de les appliquer pour tirer le meilleur parti de votre investissement.",
      viewAll: "Voir tous les produits",
      viewDetails: "Voir le détail",
      yourProducts: "Votre produits",
      noFavoriteProducts: "Vous n'avez pas des produits préférés",
      addProducts: "Ajouter des produits",
      formulas: "Formules",
      documentation: "Documentation",
      technicalDesignation: "Nom commerciale",
      formulation: "Produit recommandé",
      season: "Saison",
      maxConcentration: "Concentration maximale",
      dose: "Dose",
      observation: "Observations",
    },

    formulas: {
      header: "Formule",
      details: "Composition",
      culturesAndDoses: "Cultures et doses d'utilisation",
      formulaInformation: "Plus d'informations",
    },

    deficiencies: {
      title: "Identification des carences",
      text: "Nous vous aidons à identifier les carences nutritionnelles et les tóxicités de vou cultures. Cliquez sur le bouton pour savoir plus.",
      buttonTxt: "Savoir plus",
      cultureWithoutNutrients: 'Cette culture n\'a pas de nutriments'
    },

    cultures: {
      header: "Culture",
      title: "Cultures préférés",
      text: "Sélectionnez vos cultures préférées pour accéder plus facilement à leur contenu. Vous pouvez, par exemple, trouver nos recommandations de fertilisation, adaptées à votre localisation géographique, pour l'optimisation de votre rendement.",
      viewAll: "Voir tous les cultures",
      yourCultures: "Votre cultures",
      noFavoriteCultures: "Vous n'avez pas des cultures préférés",
      addCulture: "Ajouter des cultures",
      deficienciesAndToxicities: "Carences et Toxicités",
      nutrientsImportantRemovel: "Nutriments importants",
      soilFertilization: "Fertilité des sols",
      extractNutrients: "Extraction des nutriments",
      fertilizing: "Fertilisation",
      deficiencies: "Carences",
      toxicities: "Toxicités",
      mostImportantNutrients: "Nutriments plus important",
      removelNutrients: "Extraction des nutriments",
      hasNoNutrientDescription: "Ce nutriment n'a pas de description",
      correction: "Correction",
      corrections: "Corrections et préventions",
      products: "Produits Fertiberia TECH® recommandés pour cette culture ",
      hasNoProducts: "Il n'y a pas de produits disponibles pour cette culture.",
      buttonText: "Recherche de cultures",
      expectedProduction: "Production prévue",
      chooseExpectedProduction: "Indiquez la production prévue",
      usualFertilizing: "Fertilisation habituelle",
      destination: "Destination de la production",
      chooseDestination: "Indiquez la destination de la production",
      fertilizingFilters: "Options de fertilisation",
      soilNutrient: "Nutriments du sols",
      lessFrequent: "Moins fréquent",
      frequent: "Fréquent",
      veryFrequent: "Très fréquent",
      nutrient: "Nutriment",
      fertilizingType: "Type de Fertilisation",
    },

    promoters: {
      buttonText: "Recherche de promoteurs ",
      choosePromoter: "Recherche de promoteurs ",
      choosePlace: "Indiquez le Pays, le district et la Commune",
      chooseCountry: "Indiquez le Pays",
      chooseState: "Indiquezla Commune",
      chooseCounty: "Indiquez le district",
    },

    deficiencyIdentification: {
      startDiagnosis: "Afficher",
      restartDiagnosis: "Redémarrage des diagnostics",
      filter: "Filtrer",
      byCulture: "Par Culture",
      byNutrient: "Par Nutriment",
      byDichotomousKey: "Par Clé Dichotomique pour le diagnostic",
      choose: "Choisir",
    },

    library: {
      training: "Formation",
      selectCategory: "Sélectionnez la catégorie",
    },

    agronomicServices: {
      analysisTypes: "Types d'analyse",
      documentation: "Documentation",
      header: "Service agronomique",
      values: "Paramètres",
      observation: "Observation",
      complete: "Complète",
      routine: "Routine",
      special: "Spécial ",
    },

    sorterFilters: {
      byDate: "Par date",
      byName: "Par nom",
      byType: "Par type",
    },

    dichotomousKey: {
      selectSymptom: "Clé dichotomique : sélectionnez un symptôme",
    },

    ranges: {
      today: "Aujourd'hui",
      tomorrow: "Demain",
      thisMonth: "Ce mois",
      lastMonth: "Dernier mois",
      thisYear: "Cette année",
      lastYear: "Année dernière",
    },

    weekDays: {
      0: "Lun",
      1: "Mar",
      2: "Mer",
      3: "Jeu",
      4: "Ven",
      5: "Sam",
      6: "Dim",
    },

    monthNames: {
      "1": "Janvier",
      "2": "Février",
      "3": "Mars",
      "4": "Avril",
      "5": "Mai",
      "6": "Juin",
      "7": "Juillet",
      "8": "Août",
      "9": "Septembre",
      "10": "Octobre",
      "11": "Novembre",
      "12": "Décembre",
    },

    weather: {
      forecast: "Prévision",
      history: "Histoire",
      changeLocation: "Changer localisation",
      addLocation: "Ajouter une localisation",
      cloudy: "Nuageux",
    },

    placeholders: {
      name: "Votre nom",
      email: "votre@email.com",
      phoneNumber: "+351265000000",
      language: "Sélectionnez une langue",
      role: "Sélectionnez un poste",
      currentPassword: "Mot de passe actuel",
      newPassword: "Nouveau mot de passe",
      confirmPassword: "Confirmer le nouveau mot de passe",
      newEmail: "nouveau@email.com",
      page: "page",
      titlePage: "Titre de la page",
      link: "https://www.google.com/",
      profilePic: "Photo de profil",
      password: "Votre mot de passe",
    },

    footer: {
      social: "Social",
      about: "À propos",
      terms: "Termes et conditions",
      privacy: "Politique de confidentialité",
      copyright: "Copyright ",
      fertiberiaTech: "Fertiberia TECH®",
      sendUsEmail: "Contactez nous",
      services: "Services",
    },

    contact: {
      name: "Nom",
      email: "Email",
      subject: "Sujet",
      message: "Message",
      sendMessage: "Envoyer un message",
      send: "Envoyer",
      success: "Message envoyé avec succès",
      acceptTerms: "J'ai appris à connaître",
      acceptMarketing: "J'accepte de recevoir des communications marketing",
    },

    serverErrors: {
      title: "Erreur de serveur",
      subTitle: "Ce n'est pas vous, c'est nous !",
      refresh: "Retourner à la page précédente",
      backToHome: "Aller au début",
      accountNotConfirmed: "Compte non confirmé.",
      notFound: "Compte non existant",
      invalidCredentials: "Le compte n'existe pas ou les informations d'identification ne sont pas valides.",
      somethingWentWrong: "Il y a eu un problème",
    },
  },

  /* cSpell.language:en */
  en: {
    // Generic
    generic: {
      profile: "Profile",
      save: "Save",
      code: "Code",
      submit: "Submit",
      confirm: "Confirm",
      cancel: "Cancel",
      noContent: "No Content",
      back: "Back",
      selectData: "Select Data",
      changeData: "Change Data",
      download1: "Haven't downloaded our app yet?",
      download2: "Install it now and get all our Web content on your smartphone!",
    },

    fields: {
      startDate: "Start Date",
      endDate: "End Date",
      clickToUpload: "Click to upload",
      filters: "Filters",
      location: "Location",
      newLocation: " New Location",
      state: "District",
      county: "County",
      country: "Country",
      files: "Files",
      search: "Search",
      chooseLocation: "Choose Location",
      results: "Results",
    },

    sidebar: {
      home: "Home",
      library: "Library",
      deficiencyIdentification: "Deficiency Identification",
      soils: "Soils",
      agronomicServices: "Agronomic Services",
      aboutUs: "About Us",
      products: "Products",
      adpClassic: "ADP Classic",
      contacts: "Contacts",
      cultures: "Crops",
      promoters: "Commercials",
      meteorology: "Weather",
      news: "News",
      privacy: "Privacy Policy",
      terms: "Terms and Conditions",
      contact: "Contact",
    },

    language: {
      header: "Language",
      defaultLanguage: "Default Language",
      languageNotActive:
        "Save the changes before changing the default language.",
      portuguese: "Portuguese",
      english: "English",
      german: "German",
      french: "French",
      spanish: "Spanish",
    },

    // Screens
    authentication: {
      welcome: "Welcome",
      header: "Authentication",
      register: "Register",
      login: "Login",
      login_Register: "Register",
      registerHere: "Register here",
      logout: "Logout",
      signIn: "Sign in",
      signOut: "Sign out",
      confirmAccount: "Confirm Account",
      name: "Name",
      namePlaceholder: "Your name",
      email: "Email",
      emailPlaceholder: "Your email",
      password: "Password",
      passwordPlaceholder: "Your password",
      newPassword: "New password",
      newPasswordPlaceholder: "Enter new password",
      confirmPassword: "Confirm Password",
      confirmPasswordPlaceholder: "Repeat your password",
      forgotPassword: "Forgot password",
      loginFooter: "Not a member yet? Sign up",
      registerFooter: "Already a member? Login below",
      resendCode1: "Have you not received your confirmation code? ",
      resendCode2: "Resend activation code",
      sendEmail: "Send Email",
      recoverPassword: "Recover Password",
      changePassword: "Change Password",
      changeEmail: "Change Email",
      newEmail: "New Email",
      repeatEmail: "Repeat Email",
      newEmailConfirm: "Confirm New Email",
      currentEmail: "Current Email",
      currentPassword: "Current Password",
      emailSent: "E-mail sent. If you have not received the e-mail, the address does not exist",
      repeatPassword: "Repeat Password",
      rememberedPassword: "Did you remember your password?",
      adminPassword: "Admin Password",
      notLoggedIn: "You need to be registered to access this page",
      noMatchPassword: "Passwords don't match",
      acceptSubtitle: "Please input a password to confirm your account.",
      goBackToLogin: "Go to the login screen",
      newAccount: "Register",
      registeredSuccessfully:
        "Registration completed successfully. Please check your email for validation.",
      passwordChanged: "Password changed successfully.",
      accountConfirmed: "Account confirmed successfully.",
      iHaveAnAccount: "I Have an Account. Back to login",
      passwordDidntMatch: "Passwords doesn't match",
      missing: "Missing data ",
      signInApple: 'Apple Login',
      yourLocation: 'YOUR LOCATION',
			toGetAllAppBenefitsRegister: 'To enjoy the benefits of this App, register!'
    },

    safetyDatasheets: {
      header: "Product specification and Safety data sheets",
      description: "Know all the characteristics and properties of the products and follow the detailed instructions for proper operation of the products.",
      seeDatasheets: "see Datasheets",
    },

    notFound: {
      header: "Not Found",
    },

    errors: {
      invalidArgs: "Invalid or missing arguments",
      invalidFields: "Invalid or missing fields",
      emailIsInvalid: "Email format is not valid",
      fillEmail: "Please insert your email",
      fillPassword: "Please enter your password",
      pleaseFillFormCorrectly: "Please fill the form correctly",
      fillField: "Required field ",
      notSupportedFile: "File type not supported",
      nameIsEmpty: "Please fill Name field",
      passwordsDontMatch:
        "Fields 'New password' and 'Confirm password' do not match",
      emailIsEmpty: "Please fill email field",
      fileTooBigorInvalid: "File size is larger than 8 MB or file type is not supported",
      selectLocation: "Select a location",
      noExistAccount: "Non-existent account, please register",
    },

    profile: {
      generalInfo: "General Information",
      personalData: "Personal Information",
      otherInfo: "Other Information",
      phone: "Phone Number",
      removePicture: "Remove Picture",
      generalInfoSaved: "General information successfully saved.",
      changePassword: "Change Password",
      completeInfo: "Complete Information",
      selectYourLocation: "Select your default location",
      selectYourCultures: "Select your favorite crops",
      selectYourProducts: "Select your favorite Fertiberia TECH® products",
      country: "Country",
    },

    news: {
      title: "What's new",
      seeNews: "See News",
    },

    products: {
      header: "Product",
      adpProducts: "ADP Classic products",
      title: "Favorite products",
      text: "Select your favorite Fertiberia TECH products, so we can make you know how best to apply them and thus get the most out of your investment.",
      viewAll: "View all products",
      viewDetails: "View details",
      yourProducts: "Your products",
      noFavoriteProducts: "No favorite products yet",
      addProducts: "Add products",
      formulas: "Formulas",
      documentation: "Documentation",
      technicalDesignation: "Technical Designation",
      formulation: "Recommended Product",
      season: "Season",
      maxConcentration: "Max Concentration",
      dose: "Dose",
      observation: "Observations",
    },

    formulas: {
      header: "Formula",
      details: "Content",
      culturesAndDoses: "Crops and application doses",
      formulaInformation: "More Information",
    },

    deficiencies: {
      title: "Deficiency Identification",
      text: "We help you identify the nutritional deficiencies and toxicities of your crops. Please click the button to know more.",
      buttonTxt: "Learn more",
      cultureWithoutNutrients: 'This crop has no nutrients'
    },

    cultures: {
      header: "Crops",
      title: "Favorite Crops",
      text: "Select your favorite crops to more easily access your content. For example, you can find our fertilization recommendations, adjusted to your location, for the optimization of your investment.",
      viewAll: "View all crops",
      yourCultures: "Your crops",
      noFavoriteCultures: "No favorite crops yet",
      addCulture: "Add crops",
      deficienciesAndToxicities: "Deficiencies And Toxicities",
      nutrientsImportantRemovel: "Most important nutrients",
      soilFertilization: "Soil Fertility",
      extractNutrients: "Nutrients uptake",
      fertilizing: "Fertilizing",
      deficiencies: "Deficiencies",
      toxicities: "Toxicities",
      mostImportantNutrients: "Most important nutrients",
      removelNutrients: "Nutrient uptake",
      hasNoNutrientDescription: "This nutrient has no description",
      correction: "Correction",
      corrections: "Corrections and preventions",
      products: "Fertiberia TECH® recommendation for this crop",
      hasNoProducts: "No products available for use on this crop",
      buttonText: "Search crops",
      expectedProduction: "Expected production",
      chooseExpectedProduction: "Choose expected production",
      usualFertilizing: "Usual Fertilizing",
      destination: "Production Destiny",
      chooseDestination: "Choose the production destiny",
      fertilizingFilters: "Fertilizing Options",
      soilNutrient: "Soil Nutrient",
      lessFrequent: "Less Frequent",
      frequent: "Frequent",
      veryFrequent: "Very Frequent",
      nutrient: "Nutrient",
      fertilizingType: "Fertilizing Type",
    },

    deficiencyIdentification: {
      startDiagnosis: "Start diagnosis",
      restartDiagnosis: "Restart diagnosis",
      filter: "Filter",
      byCulture: "By Crop",
      byNutrient: "By Nutrient ",
      byDichotomousKey: "By Dichotomous Key For diagnosis",
      choose: "Choose",
    },

    library: {
      training: "Formation",
      selectCategory: "Select Category",
    },

    agronomicServices: {
      analysisTypes: "Analysis Types",
      documentation: "Documentation",
      header: "Agronomic Service",
      values: "Parameters",
      observation: "Observation",
      complete: "Complete",
      routine: "Routine",
      special: "Special",
    },

    sorterFilters: {
      byDate: "By Date",
      byName: "By Name",
      byType: "By Type",
    },

    dichotomousKey: {
      selectSymptom: "Dichotomous Key: Select a symptom",
    },

    ranges: {
      today: "Today",
      tomorrow: "Tomorrow",
      thisMonth: "This month",
      lastMonth: "Last month",
      thisYear: "This year",
      lastYear: "Last year",
    },

    weekDays: {
      0: "Mon",
      1: "Tue",
      2: "Wed",
      3: "Thu",
      4: "Fri",
      5: "Sat",
      6: "Sun",
    },

    monthNames: {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    },

    weather: {
      forecast: "Forecast",
      history: "History",
      changeLocation: "Change location",
      addLocation: "Add location",
      cloudy: "Cloudy",
    },

    placeholders: {
      name: "your name",
      email: "your@email.com",
      phoneNumber: "+351265000000",
      language: "Select a language option",
      role: "Select a role",
      currentPassword: "Actual password",
      newPassword: "New password",
      confirmPassword: "confirm new password",
      newEmail: "new@email.com",
      page: "page",
      titlePage: "Page title",
      link: "https://www.google.com/",
      profilePic: "Profile picture",
      password: "Your password",
    },

    footer: {
      social: "Social",
      about: "About Us",
      terms: "Terms and Conditions",
      privacy: "Privacy Policy",
      copyright: "Copyright ",
      fertiberiaTech: "Fertiberia TECH®",
      sendUsEmail: "Contact Us",
      services: "Services",
    },

    contact: {
      name: "Your Name",
      email: "Your Email",
      subject: "Subject",
      message: "Message",
      sendMessage: "Send Message",
      send: "Send",
      success: "Message sent successfully",
      acceptTerms: "I am aware of the",
      acceptMarketing: "I agree to receive marketing communications",
    },

    serverErrors: {
      title: "Internal server error",
      subTitle: "It's not you, it's us!",
      refresh: "Go back to previous page",
      backToHome: "Go to Home",
      accountNotConfirmed: "Account not confirmed.",
      notFound: "Account does not exist.",
      invalidCredentials: "Account does not exist or credentials are invalid.",
      somethingWentWrong: "Something Went Wrong",
    },
  },
});
