/*
 *
 * AgronomicService
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { Row } from "antd";
import { Icon, SelectableTabs } from "components";
import { toast, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import { setTitle, setLoader } from "store/actions";
import { push } from "connected-react-router";
import PDFIcon from "../../assets/images/PDF_icon.png";
import "./styles.scss";

export class AgronomicService extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedTab: "analysisTypes",
      isMobile: window.innerWidth <= 768,
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle("AgronomicService"));

    this.getData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      isMobile: window.innerWidth <= 768,
    });
  }

  getData = async () => {
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));
    try {
      const response = await API.get({
        url: Endpoints.uriAgronomicServices(match.params.id),
      });

      if (response.ok) {
        const data = response.data.results;

        this.setState({ agronomicService: data });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      dispatch(setLoader(false));
      console.log(err);
    }

    dispatch(setLoader(false));
  };

  renderAnalysisTypes() {
    const { agronomicService } = this.state;
    const { dispatch, match } = this.props;

    return (
      <div className="AgronomicServiceFormulas">
        {agronomicService?.analysisTypes?.map((type: any) => (
          <div
            key={type._id}
            className="AgronomicServiceFormula"
            onClick={() =>
              dispatch(
                push(`/agronomic-services/${match.params.id}/${type.type}`, {
                  type,
                  agronomicService,
                })
              )
            }
            style={{ textAlign: 'center' }}
          >
            {Object.entries(Strings.agronomicServices).find(elem => elem[0] === type.type)?.[1]}
          </div>
        ))}
      </div>
    );
  }

  renderDocumentation() {
    const { agronomicService } = this.state;

    return (
      <div className="AgronomicServiceFormulas" style={{ minHeight: 30 }}>
        {console.log("files: ", agronomicService.attachedFiles)}
        {agronomicService?.attachedFiles?.map((document: any) => {
          if (translate(document.name))
            return (
              <div
                className="AgronomicServiceFormula"
                style={{ justifyContent: "normal" }}
                onClick={() => window.open(translate(document.file))}
              >
                <img
                  className="PDFIcon"
                  alt={translate(document.name)}
                  src={PDFIcon}
                />
                {translate(document.name)}
              </div>
            );
        })}
        {!agronomicService?.attachedFiles?.find(
          (document: any) => translate(document.name)
        ) && <div style={{ margin: 10 }}>{Strings.generic.noContent}</div>}
      </div>
    );
  }

  render() {
    const { agronomicService, selectedTab, isMobile } = this.state;
    const { dispatch } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.agronomicServices.header}</title>
          <meta name="description" content="Description of Product" />
        </Helmet>
        <div className="PageHeader">
          <div
            className="PageHeaderTitle"
            onClick={() => dispatch(push("/agronomic-services"))}
            style={{ cursor: "pointer" }}
          >
            <Icon name="back" style={{ fontWeight: "normal" }} />
            <div>{translate(agronomicService?.name)}</div>
          </div>
        </div>
        <Row className="AgronomicServiceContent">
          <div className="AgronomicServiceImageContainer">
            <div
              className="AgronomicServiceImage"
              style={{
                backgroundImage: `url(${agronomicService?.image})`,
                backgroundSize: "cover",
              }}
            ></div>
            {/* <img
              alt={translate(agronomicService?.name)}
              src={agronomicService?.image}
              style={
                !isMobile
                  ? {
                      height: "400px",
                      width: "400px",
                      padding: "25px 0 0 25px",
                    }
                  : { height: "400px", width: "400px" }
              }
            /> */}
          </div>
          <div
            className="AgronomicServiceInfo"
            style={isMobile ? { padding: "25px 20px" } : {}}
          >
            <div className="AgronomicServiceTitle">
              {translate(agronomicService?.name)}
            </div>
            <div
              className="AgronomicServiceDescription"
              style={{ marginTop: "15px" }}
            >
              {translate(agronomicService?.description)}
            </div>
            <div
              className="AgronomicServiceDescription"
              style={{ marginTop: "15px" }}
            >
              {translate(agronomicService?.observations)}
            </div>
          </div>
          <div
            className="AgronomicServiceSelectables"
            style={isMobile ? { padding: "25px 20px" } : {}}
          >
            <SelectableTabs
              selectedTab={selectedTab}
              tabs={[
                {
                  key: "analysisTypes",
                  icon: "oil",
                  title: Strings.agronomicServices.analysisTypes,
                  disabled: !agronomicService?.analysisTypes?.length,
                  onClick: () =>
                    this.setState({ selectedTab: "analysisTypes" }),
                  renderScreen: () => this.renderAnalysisTypes(),
                },
                {
                  key: "documentation",
                  icon: "flask",
                  title: Strings.agronomicServices.documentation,
                  disabled: !agronomicService?.attachedFiles?.filter(
                    (elem: any) => translate(elem.file)
                  )?.length,
                  onClick: () =>
                    this.setState({ selectedTab: "documentation" }),
                  renderScreen: () => this.renderDocumentation(),
                },
              ]}
            />
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(AgronomicService);
