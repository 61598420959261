/**
 *
 * SelectableTabs
 *
 */

import React from 'react';
import './styles.scss';

// type TabType = {
//   key: string;
//   icon?: string,
//   title: string,
//   onClick: Function;
//   renderScreen: Function;
// };

export class SelectableTabs extends React.Component<any, any> {
  render() {
    const { selectedTab, tabs, allWidthTabs, activeStyle } = this.props;
    
    return (
      <div className="Selectable animated fadeIn">
        <div className="Selectable_tabs" style={allWidthTabs ? { width: '90vw' } : {}}>
          {tabs.map((tab: any) => (
            <div
              key={`tab_${tab.key}`}
              className={`Selectable_tab ${selectedTab === tab.key ? 'active' : ''} ${tab.disabled ? '__disabled' : ''}`}
              style={selectedTab === tab.key ? { ...activeStyle } : {}}
              onClick={() => !tab.disabled && tab.onClick()}>
              <i className={`moon ${tab.icon}`} style={{ paddingRight: '5px' }} />
              {tab.title}
            </div>
          ))}
        </div>
        <div className="Selectable_body body">
          {tabs.map((tab: any) => {
            if (selectedTab === tab.key) return tab.renderScreen();
            else return <></>;
          })}
        </div>
      </div>
    );
  }
}

export default SelectableTabs;
