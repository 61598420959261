/**
 *
 * PromoterCard
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Icon } from "components";
import { translate } from "utils/utils";
import "./styles.scss";
import userPlaceholder from "assets/images/placeholders/user.jpg";

export class PromoterCard extends React.Component<any, any> {
  render() {
    const { data, main, noDescription } = this.props;

    return (
      <div
        key={`${main}_${data}`}
        className={`PromoterCard ${main && "main"} ${
          (noDescription || !translate(data.shortInfo)) && "noDescription"
        }`}
      >
        <div className={`PromoterCardHeader`}>
          <img
            alt={translate(data.name)}
            src={data.photo || userPlaceholder}
            className="circleImage"
          />
          <h3 className="center">{data.name}</h3>
        </div>
        <div className="PromoterCardInfo">
          <div className="PromoterCardTitle" style={{ color: data.color }}>
            <h6 style={{ textAlign: "center", marginTop: "10px" }}>
              {data.email}
            </h6>
            <h6 style={{ textAlign: "center", marginTop: "10px" }}>
              {data.phone}
            </h6>
            <hr style={{ opacity: "0.2" }}></hr>
            <div>
              <span style={{ float: "left" }} className="phoneCall">
                <a
                  style={{
                    fontSize: "20px",
                  }}
                  href={`tel:${data.phone}`}
                >
                  <Icon name="phone-call" />
                </a>
              </span>
              <span style={{ float: "right" }} className="message">
                <a
                  style={{
                    fontSize: "20px",
                  }}
                  href={`mailTo:${data.email}`}
                >
                  <Icon name="letter" />
                </a>
              </span>
              <hr className="vl"></hr>
            </div>
          </div>
          <div
            className={`PromoterCardDescription ${main && "main"}`}
          >
            {noDescription || !translate(data.shortInfo)
              ? ""
              : translate(data.shortInfo)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(PromoterCard);
