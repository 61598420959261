// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/backgrounds/login.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".RecoverPasswordContent{display:flex;align-items:center;justify-content:center;width:100%;min-height:calc(100vh - 312px);padding:25px}@media screen and (max-width: 480px){.RecoverPasswordContent{padding:0}}.RecoverPasswordContent .RecoverPasswordContentWrapper{width:100%}@media screen and (min-width: 1600px){.RecoverPasswordContent .RecoverPasswordContentWrapper{width:1600px}}.RecoverPasswordBox{box-shadow:-1px -1px 55px 0 #33333320,1px 1px 55px 0 #33333320}@media screen and (max-width: 480px){.RecoverPasswordBox{height:750px;box-shadow:none}}.RecoverPasswordWrapper{display:flex;flex-direction:column;justify-content:center;padding:20px 40px;height:500px;background-color:#fff}.RecoverPasswordWrapper form{width:100%}.RecoverPasswordWrapper h2{letter-spacing:5px;margin-bottom:30px}@media only screen and (max-width: 600px){.RecoverPasswordWrapper{padding:20px 25px}}.RecoverPasswordAuthenticationWrapper{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:20px;height:500px}.RecoverPasswordWelcome{padding:20px;height:500px;background-color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-size:cover;background-repeat:no-repeat}.RecoverPasswordInputClass{height:50px;border:1px solid #959fa3;width:100%;padding:8px 11px;margin:10px 0}.RecoverPasswordOptions{display:flex;width:100%;padding:10px 5px 0;justify-content:space-between}.RecoverPasswordOptionLink{color:#000;cursor:pointer;font-weight:bold}.RecoverPasswordOptionLink:hover{color:#7d878c}.RecoverPasswordButtonClass{height:50px;width:100%;text-align:center;border:1px solid #000;margin-top:50px;background-color:#000;cursor:pointer;font-weight:bold;transition:.2s;color:#fff}.RecoverPasswordButtonClass:hover{background-color:#2b556b}.RecoverPasswordButtonClass:active{background:#0b2538;border-color:#0b2538;box-shadow:none}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
