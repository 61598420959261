import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Sidebar, Header, ErrorBoundary, Footer } from "screens";
import { Content, RouteContent, Loader } from "components";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Routes from "./routes";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";

// import ReactDOM from 'react-dom';

import 'styles/styles.scss';
import 'styles/theme.less';
import 'react-toastify/dist/ReactToastify.css';

let theme: any;

export class App extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			sidebarOpen: false,
			sidebarHidden: false,
		};

		theme = createMuiTheme({
			palette: {
				primary: {
					main: '#000',
				},
			},
			// typography: { 
			// 	useNextVariants: true,
			// },
		});

		this.handleResize = this.handleResize.bind(this);
		window.addEventListener('resize', this.handleResize);
	}

	async componentDidMount() {
		const response = await API.get({
			url: Endpoints.uriPages(),
		});

		if (response.ok) {
			const { pages } = response.data.results;

			const auIndex = pages?.findIndex((elem: any) => elem.type === 'au')
			const aboutUs = pages?.[auIndex];

			this.setState({ pages, aboutUs });
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

    componentDidUpdate() {
		const elem = document.getElementById("app_content");
	
		if (elem) {
		  elem.scrollTop = 0;
		}
	}

	handleResize() {
		const isMobile = window.innerWidth < 992;
		this.setState({
			isMobile,
			sidebarOpen: !isMobile ? false : this.state.sidebarOpen
		});
	}

	openSidebar() {
		this.setState({ sidebarOpen: true });
	}

	closeSidebar() {
		if (document.body.clientWidth < 992) {
			this.setState({ sidebarHidden: true, sidebarOpen: false });
		} else {
			this.setState({ sidebarOpen: false });
		}
	}

	render() {
		const { pages, aboutUs } = this.state;

		return (
			<div className="App">
				<div id="app_banner" />
				<MuiThemeProvider theme={theme}>
					<ToastContainer
						toastClassName="BBToast"
						bodyClassName="BBToastBody"
						hideProgressBar={false}
						closeButton={<></>}
						position={toast.POSITION.BOTTOM_RIGHT}
						autoClose={5000}
					/>
					<Sidebar
						open={this.state.sidebarOpen}
						onMobile={(status: any) =>
							status && this.closeSidebar()
						}
						openSidebar={() => this.openSidebar()}
						closeSidebar={() => this.closeSidebar()}
					/>
					<Content>
						<RouteContent>
							<ErrorBoundary>
								<Header
									sidebarOpen={this.state.sidebarOpen}
									onToggleSidebar={() => {
										this.setState((state: any) => ({
											sidebarHidden: !state.sidebarHidden,
											sidebarOpen: !state.sidebarOpen,
										}));
									}}
									aboutUs={aboutUs}
									/>
								<Routes />
								<Footer pages={pages} />
							</ErrorBoundary>
						</RouteContent>
					</Content>
					<Loader />
				</MuiThemeProvider>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	loader: state.loader,
});

export default connect(mapStateToProps)(App);
