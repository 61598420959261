// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logos/fertiberia-tech-gray.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".PromoterCard{border:1px solid #e1e4e6;margin-right:10px;background-color:#fff;overflow:hidden;position:relative;height:380px;display:flex !important;justify-content:space-between}.PromoterCard.main{height:650px}.PromoterCard.main .PromoterCardImage{height:50%;width:100%}.PromoterCard.main img{overflow:hidden;width:180px;height:180px;border-radius:50%;object-fit:cover}.PromoterCard.main.noDescription{height:400px}.PromoterCard.main.noDescription .PromoterCardImage{height:65%}.PromoterCard.main.noDescription .PromoterCardInfo{height:150px}.PromoterCard .PromoterCardHeader{display:flex;align-items:center;position:relative;flex-direction:column;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:90px;background-position:right top;background-repeat:no-repeat}.PromoterCard .PromoterCardHeader .center{text-align:center;width:100%;margin:20px 0 10px 0;font-size:18px;font-weight:bold}.PromoterCard .PromoterCardImage{height:100%;width:45%;background-position:center;filter:blur(8px);-webkit-filter:blur(8px);transform:scale(1.3);position:absolute;top:0}.PromoterCard .PromoterCardInfo{background-color:#fff}.PromoterCard .PromoterCardInfo .PromoterCardTitle{font-size:18px;text-transform:uppercase;width:100%}.PromoterCard .PromoterCardInfo .PromoterCardDescription{font-size:14px;overflow:hidden;text-overflow:ellipsis;color:#959fa3;line-height:122%;height:65%}.PromoterCard .PromoterCardInfo .PromoterCardDescription.main{font-size:18px;margin-bottom:25px;height:55%}.vl{border:none;border-left:1px solid #73848c;height:60px;width:1px;opacity:.2}.phoneCall{margin-left:25%;transform:scale(1.5);margin-top:18px}.message{margin-right:25%;transform:scale(1.5);margin-top:18px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
