// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductCard{border:1px solid #e1e4e6;margin-right:10px;background-color:#fff;overflow:hidden;position:relative;height:410px;display:flex !important;align-content:flex-end;align-items:flex-end}@media only screen and (max-width: 600px){.ProductCard{margin-right:0}}.ProductCard.main{height:650px}.ProductCard.main .ProductCardImage{height:50%;width:100%}.ProductCard.main img{height:50%;width:100%}.ProductCard.main .ProductCardInfo{display:flex;align-items:center;height:50%;width:100%;top:auto}.ProductCard.main.noDescription{height:450px}.ProductCard.main.noDescription .ProductCardImage{height:65%}.ProductCard.main.noDescription img{height:65%}.ProductCard.main.noDescription .ProductCardInfo{height:35%}.ProductCard .ProductCardImage{height:100%;width:45%;background-position:center;filter:blur(8px);-webkit-filter:blur(8px);transform:scale(1.3);position:absolute;top:0}.ProductCard img{height:100%;width:45%;object-fit:contain;position:absolute;z-index:999;top:1px;left:0px}.ProductCard .ProductCardInfo{display:flex;flex-direction:column;height:100%;width:55%;padding:20px;z-index:100;position:absolute;top:0;right:0;background-color:#fff}@media only screen and (max-width: 320px){.ProductCard .ProductCardInfo{padding:20px 10px}}.ProductCard .ProductCardInfo .ProductCardTitle{font-size:18px;text-transform:uppercase;width:100%;margin-bottom:20px}.ProductCard .ProductCardInfo .ProductCardDescription{font-size:14px;overflow:hidden;text-overflow:ellipsis;color:#959fa3;line-height:122%;height:65%}.ProductCard .ProductCardInfo .ProductCardDescription.main{font-size:18px;margin-bottom:25px;height:55%}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
