/*
 *
 * Cultures
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { CultureCard, Icon } from "components";
import { toast } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { Button } from "antd";
import "./styles.scss";

export class Cultures extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			screen: "",
		};
		this.handleResize = this.handleResize.bind(this);

		window.addEventListener("resize", this.handleResize);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.sidebar.cultures));

		this.handleResize();
		this.getData();
	}

	handleResize() {
		if (window.innerWidth <= 768) {
			this.setState({
				screen: "Mobile",
			});
		} else if (window.innerWidth <= 1440) {
			this.setState({
				screen: "Laptop",
			});
		} else {
			this.setState({
				screen: "Default",
			});
		}
	}

	getData = async () => {
		const { dispatch } = this.props;
		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriCultures(),
		});

		if (response.ok) {
			this.setState({ news: response.data.results.news });
			this.setState({ cultures: response.data.results });
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	auxMethod(containers: number) {
		let auxArr: any[] = [];
		for (let i = 0; i < containers; i++) {
			auxArr.push(
				<div className="no-content">
					<div className="no-content-container" />
				</div>
			);
		}
		return auxArr;
	}

	renderSwitch(screen: any) {
		switch (screen) {
			case "Mobile":
				return this.auxMethod(2);
			case "Laptop":
				return this.auxMethod(6);
			default:
				return this.auxMethod(8);
		}
	}

	render() {
		const { cultures, screen } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.cultures}</title>
					<meta name="description" content="Description of Cultures" />
				</Helmet>
				<div className="PageHeader">
					<div className="PageHeaderTitle">
						<Icon name="plant-1" />
						<span>{Strings.sidebar.cultures}</span>
					</div>
				</div>
				<div
					className="CulturesContent"
					style={!cultures ? { justifyContent: "space-between" } : {}}
				>
					{cultures ? (
						<>
							{cultures.map((elem: any) => (
								<div className="CultureCardContainer">
									<CultureCard data={elem} main />
								</div>
							))}
							{screen === "Mobile" ? (
								<>{this.auxMethod(0)}</>
							) : screen === "Laptop" && cultures.length % 3 === 1 ? (
								<>{this.auxMethod(2)}</>
							) : cultures.length % 3 === 2 ? (
								<>{this.auxMethod(1)}</>
							) : screen === "Default" && cultures.length % 4 === 1 ? (
								<>{this.auxMethod(3)}</>
							) : cultures.length % 4 === 2 ? (
								<>{this.auxMethod(2)}</>
							) : cultures.length % 4 === 3 ? (
								<>{this.auxMethod(1)}</>
							) : (
								<></>
							)}

							{/* {screen === "Laptop" && cultures.length % 3 === 1 ? (
                <>{this.auxMethod(2)}</>
              ) : cultures.length % 3 === 2 ? (
                <>{this.auxMethod(1)}</>
              ) : (
                <></>
              )}

              {screen === "Default" && cultures.length % 4 === 1 ? (
                <>{this.auxMethod(3)}</>
              ) : cultures.length % 4 === 2 ? (
                <>{this.auxMethod(2)}</>
              ) : cultures.length % 4 === 3 ? (
                <>
                  {this.auxMethod(1)}
                </>
              ) : (
                <></>
              )} */}
						</>
					) : (
						<>{this.renderSwitch(screen)}</>
					)}
				</div>
				{!cultures ? (
					<div className="con align">
						{Strings.cultures.buttonText?.toUpperCase()}
						<br></br>
						<Button
							style={{
								backgroundColor: "#7D878C",
								color: "white",
								fontWeight: "bold",
								width: "350px",
								height: "50px",
							}}
						>
							<span>{Strings.cultures.buttonText?.toUpperCase()}</span>
						</Button>
					</div>
				) : (
					<></>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(Cultures);
