/*
 *
 * AgronomicServices
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { Button } from "antd";
import { Icon } from "components";
import { API, Endpoints } from "utils/api";
import { toast, translate } from "utils/utils";
import Strings from "utils/strings";
import "./styles.scss";

export class AgronomicServices extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      agronomicServices: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle(Strings.sidebar.agronomicServices));

    this.getData();
  }

  getData = async () => {
    const { dispatch } = this.props;

    dispatch(setLoader(true));
    try {
      const response = await API.get({
        url: Endpoints.uriAgronomicServices(),
      });

      if (response.ok) {
        const data = response.data.results;

        this.setState({ agronomicServices: data });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      dispatch(setLoader(false));
      console.log(err);
    }

    dispatch(setLoader(false));
  };

  render() {
    const { agronomicServices } = this.state;
    const { dispatch } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.agronomicServices}</title>
          <meta name="description" content="Description of AgronomicServices" />
        </Helmet>
        <div className="PageHeader">
          <div className="PageHeaderTitle">
            <Icon name="agriculture" style={{ fontSize: 30 }} />
            <span>{Strings.sidebar.agronomicServices}</span>
          </div>
          <div
						className={`PageHeaderFiltersButton`}
						onClick={() => dispatch(push("/contact")) }
						style={{ padding: '5px 25px' }}
						>
						{Strings.sidebar.contact}
						<Icon className="LocationMarker" name="email" style={{ fontSize: 20 }} />
					</div>
        </div>
        <div
          className={`AgronomicServicesContent ${
            agronomicServices.length === 3
              ? "FullScreenThree"
              : agronomicServices.length === 2
              ? "FullScreenTwo"
              : agronomicServices.length === 1
              ? "FullScreenOne"
              : ""
          } `}
        >
          {agronomicServices.map((elem: any) => (
            <div className="ServiceCard">
              <img
                className="ServiceCardImage"
                alt={translate(elem.name)}
                src={elem.image}
              />
              <div className="ServiceCardOverflow">
                <div className="ServiceName">{translate(elem.name)}</div>
                <Button
                  type="primary"
                  className="button"
                  onClick={() =>
                    dispatch(push(`/agronomic-services/${elem._id}`))
                  }
                >
                  <span>{Strings.deficiencyIdentification.startDiagnosis}</span>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language || 'pt',
});

export default connect(mapStateToProps)(AgronomicServices);
