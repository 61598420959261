/*
 *
 * DeficienciesIdentifying
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Icon } from "components";
import { Row, Col, Button, Image } from "antd";
import Modal from "react-modal";
import { toast, formulaFormat, translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import { push } from "connected-react-router";
import Strings from "utils/strings";
import "./styles.scss";

import culturesBackground from "../../assets/images/backgrounds/culturas_fundo_1.jpg";
import culturesBackground2 from "../../assets/images/backgrounds/culturas_fundo_2.jpg";
import nutrientsBackground from "../../assets/images/backgrounds/nutrientes.jpg";
import dichotomousKeyBackground from "../../assets/images/backgrounds/chave_dicotomica.jpg";

export class DeficienciesIdentifying extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = { isMobile: window.innerWidth <= 920 };

		this.handleResize = this.handleResize.bind(this);
		window.addEventListener("resize", this.handleResize);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.sidebar.deficiencyIdentification));

		this.getData();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		this.setState({
			isMobile: window.innerWidth <= 920,
		});
	}

	getData = async () => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const [culturesResponse, nutrientsResponse] = await Promise.all([
			API.get({
				url: Endpoints.uriCultures(),
			}),
			API.get({
				url: Endpoints.uriNutrients(),
			}),
		]);

		if (culturesResponse.ok) {
			const cultures = culturesResponse.data.results;

			this.setState({
				cultures,
			});
		} else {
			dispatch(setLoader(false));
			return toast.error(culturesResponse.data?.message || Strings.serverErrors.title);
		}

		if (nutrientsResponse.ok) {
			const nutrients = nutrientsResponse.data.results;

			this.setState({
				nutrients,
			});
		} else {
			dispatch(setLoader(false));
			return toast.error(nutrientsResponse.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	getDichotomousKey = async (id = "") => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriDichotomousKey(id || ""),
		});

		if (response.ok) {
			const dichotomousKey = response.data.results;
			if (dichotomousKey.nutrient) {
				const cultures = await this.getSymptoms({
					nutrient: dichotomousKey.nutrient,
				});

				this.setState({
					filters: {
						...this.state.filters,
						nutrient:
							cultures[0]?.nutrientsDeficiencyToxicity?.find(
								(elem: any) => elem.nutrient._id === dichotomousKey.nutrient
							).nutrient || null,
					},
					nutrientCultures: cultures,
					openModal: "cultures",
				});
			} else {
				this.setState({ dichotomousKey });
			}
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	getSymptoms = async (body: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const response = await API.post({
			url: Endpoints.uriCultures("nutrient-details"),
			data: body,
		});

		if (response.ok) {
			const cultures = response.data.results.culture;

			if (cultures.length) {
				for (let culture of cultures) {
					if (!culture.nutrients) continue;

					const auxCorrections = culture.nutrients.corrections
						? JSON.parse(JSON.stringify(culture.nutrients.corrections))
						: [];
					let i = 0;
					for (let correction of auxCorrections) {
						correction.formulas =
							correction.product?.formulas?.filter((elem: any) =>
								correction.formulas.includes(elem._id)
							) || [];
						auxCorrections[i] = correction;
						i++;
					}
					culture.nutrients.corrections = auxCorrections;
				}
			}

			dispatch(setLoader(false));
			return cultures;
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	};

	getModalBackAction = async () => {
		const { openModal, moduleType, dichotomousKey } = this.state;
		if (moduleType === "byCulture") {
			if (openModal === "cultures") {
				this.setState({ openModal: null, moduleType: null });
			} else if (openModal === "nutrients") {
				return this.setState({ openModal: "cultures" });
			} else if (openModal === "nutrientDetail") {
				return this.setState({ openModal: "nutrients" });
			}
		} else if (moduleType === "byNutrient") {
			if (openModal === "nutrients") {
				this.setState({ openModal: null, moduleType: null });
			} else if (openModal === "cultures") {
				return this.setState({ openModal: "nutrients" });
			} else if (openModal === "nutrientDetail") {
				return this.setState({ openModal: "cultures" });
			}
		} else if (moduleType === "byDichotomousKey") {
			if (openModal === "nutrientDetail") {
				return this.setState({ openModal: "cultures" });
			} else if (!dichotomousKey._id) {
				this.setState({ openModal: null, moduleType: null });
			} else if (openModal === "cultures") {
				this.setState({ openModal: "dichotomousKey" });
			} else {
				this.getDichotomousKey(dichotomousKey.parent);
				this.setState({ openModal: "dichotomousKey" });
			}
		}
	};

	getModalSecondaryHeader = () => {
		const { openModal, moduleType, filters } = this.state;

		if (openModal === "nutrientDetail") {
			return `${translate(filters?.culture?.name)} / ${translate(filters?.nutrient?.name)}`;
		} else if (moduleType === "byCulture") {
			if (openModal === "cultures") {
				return `${Strings.deficiencyIdentification.filter} ${Strings.deficiencyIdentification.byCulture}`;
			} else if (openModal === "nutrients") {
				return `${Strings.cultures.header}: ${translate(filters?.culture?.name)}`;
			}
		} else if (moduleType === "byNutrient") {
			if (openModal === "nutrients") {
				return `${Strings.deficiencyIdentification.filter} ${Strings.deficiencyIdentification.byNutrient}`;
			} else if (openModal === "cultures") {
				return `${Strings.cultures.nutrient}: ${translate(filters?.nutrient?.name)}`;
			}
		} else if (moduleType === "byDichotomousKey") {
			if (openModal === "cultures") {
				return `${Strings.cultures.nutrient}: ${translate(filters?.nutrient?.name)}`;
			}
			return Strings.dichotomousKey.selectSymptom;
		}
	};

	setModalValue = async (value: any) => {
		const { openModal, moduleType, filters } = this.state;

		if (moduleType === "byCulture") {
			if (openModal === "cultures") {
				if (filters?.culture?._id === value._id) {
					this.setState({ openModal: "nutrients" });
					return;
				}
				const culture = await this.getSymptoms({ culture: value._id });

				if (!culture?.nutrientsDeficiencyToxicity?.length)
					return toast.error(Strings.deficiencies.cultureWithoutNutrients);

				const nutrients: any = [];
				for (let deficiencyToxicity of culture?.nutrientsDeficiencyToxicity) {
					// if (
					//   !nutrients.find(
					//     (elem: any) => elem._id === deficiencyToxicity.nutrient._id
					//   )
					// ) {
					for (let symptom of deficiencyToxicity.symptoms) {
						nutrients.push({ ...symptom, ...deficiencyToxicity.nutrient });
					}
					// }
				}

				this.setState({
					filters: { ...filters, culture },
					cultureNutrients: nutrients,
					openModal: "nutrients",
				});
			} else if (openModal === "nutrients") {
				const culture = await this.getSymptoms({
					culture: filters.culture._id,
					nutrient: value._id,
				});
				const deficiency = culture.find(
					(elem: any) => elem?.nutrients.type === "deficiency"
				)?.nutrients;
				const toxicity = culture.find(
					(elem: any) => elem?.nutrients.type === "toxicity"
				)?.nutrients;
				this.setState({
					filters: { ...filters, nutrient: value },
					nutrientDetails: {
						deficiency,
						toxicity,
					},
					openModal: "nutrientDetail",
				});
			}
		} else if (moduleType === "byNutrient") {
			if (openModal === "nutrients") {
				if (filters?.nutrient?._id === value._id) {
					this.setState({ openModal: "cultures" });
					return;
				}
				const cultures = await this.getSymptoms({ nutrient: value._id });
				this.setState({
					filters: { ...filters, nutrient: value },
					nutrientCultures: cultures,
					openModal: "cultures",
				});
			} else if (openModal === "cultures") {
				const culture = await this.getSymptoms({
					culture: value._id,
					nutrient: filters.nutrient._id,
				});

				const deficiency = culture.find(
					(elem: any) => elem?.nutrients.type === "deficiency"
				)?.nutrients;
				const toxicity = culture.find(
					(elem: any) => elem?.nutrients.type === "toxicity"
				)?.nutrients;
				this.setState({
					filters: { ...filters, culture: value },
					nutrientDetails: {
						deficiency,
						toxicity,
					},
					openModal: "nutrientDetail",
				});
			}
		} else if (moduleType === "byDichotomousKey") {
			if (openModal === "cultures") {
				const culture = await this.getSymptoms({
					culture: value._id,
					nutrient: filters.nutrient._id,
				});
				const deficiency = culture.find(
					(elem: any) => elem.nutrients.type === "deficiency"
				)?.nutrients;
				const toxicity = culture.find(
					(elem: any) => elem.nutrients.type === "toxicity"
				)?.nutrients;
				this.setState({
					filters: { ...filters, culture: value },
					nutrientDetails: {
						deficiency,
						toxicity,
					},
					openModal: "nutrientDetail",
				});
			} else {
				this.getDichotomousKey(value._id);
			}
		}
	};

	restartDiagnosis = () => {
		const { moduleType, dichotomousKey } = this.state;
		let openModal = null;

		if (moduleType === "byCulture") {
			openModal = "cultures";
		} else if (moduleType === "byNutrient") {
			openModal = "nutrients";
		} else if (moduleType === "byDichotomousKey") {
			openModal = "dichotomousKey";
			if (dichotomousKey._id) this.getDichotomousKey();
		}

		this.setState({ openModal });
	};

	renderCultureModal() {
		const { openModal, moduleType, isMobile } = this.state;

		let auxCultures = this.state.cultures;
		if (moduleType === "byNutrient" || moduleType === "byDichotomousKey") {
			auxCultures = this.state.nutrientCultures;
		}

		return (
			<Modal
				isOpen={openModal === "cultures"}
				onRequestClose={() => {
					this.setState({ openModal: null, moduleType: null });
				}}
				style={
					!isMobile
						? {
								content: {
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									padding: 0,
									background: "transparent",
									border: "none",
									backgroundColor: "white",
									borderRadius: 0,
									width: "80%",
									height: "70%",
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
						: {
								content: {
									position: "relative",
									padding: 0,
									width: "100vw",
									height: "100vh",
									inset: 0,
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
				}
			>
				<div className="ModalHeader">
					<div className="ModalHeaderTitle">
						<Icon name="back" onClick={() => this.getModalBackAction()} />
						<span>{Strings.sidebar.deficiencyIdentification}</span>
					</div>
					{!isMobile ? (
						<Button
							type="primary"
							className="button"
							style={{ margin: "0 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					) : (
						<></>
					)}
				</div>
				<div className="ModalSecondaryHeader">{this.getModalSecondaryHeader()}</div>
				<div
					className="CulturesContent"
					style={
						!isMobile
							? { padding: 0, height: "56vh", minHeight: 0 }
							: { padding: 0, minHeight: 0, marginBottom: "50px" }
					}
				>
					{auxCultures?.map((elem: any) => (
						<div className="CultureCardContainer">
							<div
								className="CultureCard"
								style={{
									margin: 1,
									height: "27.7vh",
									backgroundImage: `url(${elem.image})`,
								}}
							>
								<div className="CultureCardOverlay">
									<div className="CultureCardName">{translate(elem.name)}</div>
									<Button
										className="SeeDetailsButton"
										onClick={() => this.setModalValue(elem)}
									>
										<span>
											{Strings.deficiencyIdentification.choose.toUpperCase()}
										</span>
									</Button>
								</div>
								<div className="CultureCardOpacity" />
							</div>
						</div>
					))}
				</div>
				{isMobile ? (
					<div className="ModalFooter">
						<Button
							type="primary"
							className="button"
							style={{ margin: "5px 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					</div>
				) : (
					<></>
				)}
			</Modal>
		);
	}

	renderNutrientDetailModal() {
		const { openModal, filters, nutrientDetails, isMobile } = this.state;
		const { dispatch } = this.props;

		if (openModal === "nutrientDetail" && !filters?.culture)
			return toast.error(Strings.serverErrors.somethingWentWrong);

		const deficiency = nutrientDetails?.deficiency;
		const toxicity = nutrientDetails?.toxicity;
		return (
			<Modal
				isOpen={openModal === "nutrientDetail"}
				onRequestClose={() => {
					this.setState({ openModal: null, moduleType: null });
				}}
				style={
					!isMobile
						? {
								content: {
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									padding: 0,
									background: "transparent",
									border: "none",
									backgroundColor: "white",
									borderRadius: 0,
									width: "80%",
									height: "70%",
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
						: {
								content: {
									position: "relative",
									padding: 0,
									width: "100vw",
									height: "100vh",
									inset: 0,
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
				}
			>
				<div className="ModalHeader">
					<div className="ModalHeaderTitle">
						<Icon name="back" onClick={() => this.getModalBackAction()} />
						<span>{Strings.sidebar.deficiencyIdentification}</span>
					</div>
					{!isMobile ? (
						<Button
							type="primary"
							className="button"
							style={{ margin: "0 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					) : (
						<></>
					)}
				</div>
				<div className="ModalSecondaryHeader">{this.getModalSecondaryHeader()}</div>
				{filters?.culture?.image ? (
					<div
						className="CultureBackgroundImage"
						style={{
							height: 90,
							backgroundImage: `url(${filters?.culture?.image})`,
						}}
					/>
				) : (
					<></>
				)}
				<div style={{ padding: "25px" }}>
					{deficiency ? (
						<>
							<h2 className="Subtitle" style={{ marginBottom: 5 }}>
								{Strings.cultures.deficiencies}
							</h2>
							<Row gutter={24}>
								<Col xs={24} md={12} className="NutrientsDeficiency" style={{ padding: '5px 12px 30px 12px' }}>
									<h3 className="NutrientsName">
										{translate(deficiency?.nutrient.name)?.toUpperCase()}
										{formulaFormat(deficiency?.nutrient.formula, deficiency?.nutrient.isFormula)}
									</h3>

									{deficiency?.symptoms.map((nutrient: any) => (
										<div className="Symptom">
											{nutrient.image && (
												<Image
													alt={translate(deficiency?.nutrient.name)}
													className="SymptomImage"
													height={130}
													width={130}
													preview
													src={nutrient.image}
												/>
											)}
											<p style={!nutrient.image ? { marginLeft: 0 } : {}}>{translate(nutrient.description)}</p>
										</div>
									))}
									{deficiency?.corrections.filter(
										(elem: any) => Object.keys(elem).length && elem.product
									).length ? (
										<div className="NutrientsCorrectionContainer">
											<div
												style={{
													color: "black",
													fontWeight: "bold",
												}}
											>
												{Strings.cultures.corrections}
											</div>

											{deficiency?.corrections
												.filter(
													(elem: any) =>
														Object.keys(elem).length && elem.product
												)
												.map((el: any) => (
													<Row>
														{/* <Col md={24} xs={24} sm={24}>
															{el.product.logo ? (
																<div>
																	<img
																		alt={`${translate(
																			el.product.name
																		)}`}
																		style={{
																			maxWidth: "300px",
																			height: "50px",
																			cursor: "pointer",
																		}}
																		src={el.product.logo}
																		onClick={() =>
																			dispatch(
																				push(
																					`/products/${el.product._id}`,
																					{
																						backPath:
																							"/deficiencies-identifying",
																					}
																				)
																			)
																		}
																	/>
																</div>
															) : (
																<div
																	style={{
																		maxWidth: "300px",
																		height: "50px",
																		fontWeight: "bold",
																		fontSize: "34px",
																		color: el.product.color,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		dispatch(
																			push(
																				`/products/${el.product._id}`,
																				{
																					backPath:
																						"/deficiencies-identifying",
																				}
																			)
																		)
																	}
																>
																	{translate(
																		el.product.name
																	)?.toUpperCase()}
																</div>
															)}
														</Col> */}

														{el.formulas.map((form: any) => (
															<Col
																md={7}
																xs={11}
																sm={11}
																style={{
																	marginRight: "10px",
																	cursor: "pointer",
																}}
															>
																<div
																	className="CorrectionBox"
																	style={{ backgroundColor: el.product?.color }}
																	onClick={() =>
																		dispatch(
																			push(
																				`/formula/${form._id}`,
																				{
																					product:
																						el.product
																							._id,
																					backPath:
																						"/deficiencies-identifying",
																				}
																			)
																		)
																	}
																>
																	{translate(form?.name)}
																</div>
															</Col>
														))}
													</Row>
												))}
										</div>
									) : (
										<></>
									)}
								</Col>
							</Row>
						</>
					) : (
						<></>
					)}
					{toxicity ? (
						<>
							<h2 className="Subtitle" style={{ marginBottom: 5 }}>
								{Strings.cultures.toxicities}
							</h2>
							<Row gutter={24}>
								<Col xs={24} md={12} className="NutrientsDeficiency" style={{ padding: '5px 12px 30px 12px' }}>
									<h3 className="NutrientsName">
										{translate(toxicity?.nutrient.name)?.toUpperCase()}
										{formulaFormat(toxicity?.nutrient.formula, toxicity?.nutrient.isFormula)}
									</h3>

									{toxicity?.symptoms.map((nutrient: any) => (
										<div className="Symptom">
											{nutrient.image && (
												<Image
													alt={translate(toxicity?.nutrient.name)}
													className="SymptomImage"
													height={130}
													width={130}
													preview
													src={nutrient.image}
												/>
											)}
											<p style={!nutrient.image ? { marginLeft: 0 } : {}}>{translate(nutrient.description)}</p>
										</div>
									))}
									{toxicity?.corrections.filter(
										(elem: any) => Object.keys(elem).length && elem.product
									).length ? (
										<div className="NutrientsCorrectionContainer">
											<div
												style={{
													color: "black",
													fontWeight: "bold",
												}}
											>
												{Strings.cultures.corrections}
											</div>
											{toxicity?.corrections
												.filter(
													(elem: any) =>
														Object.keys(elem).length && elem.product
												)
												.map((el: any) => (
													<Row>
														{/* <Col md={24} xs={24} sm={24}>
															{el.product.logo ? (
																<div>
																	<img
																		alt={`${translate(
																			el.product.name
																		)}`}
																		style={{
																			maxWidth: "300px",
																			height: "50px",
																			cursor: "pointer",
																		}}
																		src={el.product.logo}
																		onClick={() =>
																			dispatch(
																				push(
																					`/products/${el.product._id}`,
																					{
																						backPath:
																							"/deficiencies-identifying",
																					}
																				)
																			)
																		}
																	/>
																</div>
															) : (
																<div
																	style={{
																		maxWidth: "300px",
																		height: "50px",
																		fontWeight: "bold",
																		fontSize: "34px",
																		color: el.product.color,
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		dispatch(
																			push(
																				`/products/${el.product._id}`,
																				{
																					backPath:
																						"/deficiencies-identifying",
																				}
																			)
																		)
																	}
																>
																	{translate(
																		el.product.name
																	)?.toUpperCase()}
																</div>
															)}
														</Col> */}

														{el.formulas.map((form: any) => (
															<Col
																md={7}
																xs={11}
																sm={11}
																style={{
																	marginRight: "10px",
																	cursor: "pointer",
																}}
															>
																<div
																	className="CorrectionBox"
																	style={{ backgroundColor: el.product?.color }}
																	onClick={() =>
																		dispatch(
																			push(
																				`/formula/${form._id}`,
																				{
																					product:
																						el.product
																							._id,
																					backPath:
																						"/deficiencies-identifying",
																				}
																			)
																		)
																	}
																>
																	{translate(form?.name)}
																</div>
															</Col>
														))}
													</Row>
												))}
										</div>
									) : (
										<></>
									)}
								</Col>
							</Row>
						</>
					) : (
						<></>
					)}
				</div>
				{isMobile ? (
					<div className="ModalFooter">
						<Button
							type="primary"
							className="button"
							style={{ margin: "5px 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					</div>
				) : (
					<></>
				)}
			</Modal>
		);
	}

	renderNutrientModal() {
		const { openModal, moduleType, isMobile } = this.state;

		let nutrients = this.state.nutrients;
		if (moduleType === "byCulture") {
			nutrients = this.state.cultureNutrients;
		}

		let hasImages = false;
		for (const elem of nutrients || []) {
			if (elem.image) {
				hasImages = true;
				break;
			}
		}

		return (
			<Modal
				isOpen={openModal === "nutrients"}
				onRequestClose={() => {
					this.setState({ openModal: null, moduleType: null });
				}}
				style={
					!isMobile
						? {
								content: {
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									padding: 0,
									background: "transparent",
									border: "none",
									backgroundColor: "white",
									borderRadius: 0,
									width: "80%",
									height: "70%",
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
						: {
								content: {
									position: "relative",
									padding: 0,
									width: "100vw",
									height: "100vh",
									inset: 0,
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
				}
			>
				<div className="ModalHeader">
					<div className="ModalHeaderTitle">
						<Icon name="back" onClick={() => this.getModalBackAction()} />
						<span>{Strings.sidebar.deficiencyIdentification}</span>
					</div>
					{!isMobile ? (
						<Button
							type="primary"
							className="button"
							style={{ margin: "0 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					) : (
						<></>
					)}
				</div>
				<div className="ModalSecondaryHeader">{this.getModalSecondaryHeader()}</div>
				{hasImages ? (
					<div
						className="CulturesContent"
						style={
							!isMobile
								? { padding: 0, height: "56vh", minHeight: 0 }
								: { padding: 0, minHeight: 0, marginBottom: "50px" }
						}
					>
						{nutrients?.map((elem: any) => (
							<div className="CultureCardContainer">
								<div
									className="CultureCard"
									style={{
										margin: 1,
										height: "27.7vh",
										backgroundImage: `url(${elem.image})`,
										cursor: "pointer",
									}}
									onClick={() => this.setModalValue(elem)}
								>
									<div className="CultureCardOverlay" style={{ height: 50 }}>
										<div className="CultureCardName">
											{translate(elem.name)} {formulaFormat(elem.formula, elem.isFormula)}
										</div>
									</div>
									<div className="CultureCardOpacity" />
								</div>
							</div>
						))}
					</div>
				) : (
					<Row className="NutrientsContainer">
						{nutrients?.map((elem: any) => (
							<Col
								xs={20}
								md={10}
								className="NutrientsOption"
								style={{ minWidth: 350 }}
								onClick={() => this.setModalValue(elem)}
							>
								<span>
									{translate(elem.name)} {formulaFormat(elem.formula, elem.isFormula)}
								</span>
								<Icon name="back" className="backButton" />
							</Col>
						))}
					</Row>
				)}
				{isMobile ? (
					<div className="ModalFooter">
						<Button
							type="primary"
							className="button"
							style={{ margin: "5px 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					</div>
				) : (
					<></>
				)}
			</Modal>
		);
	}

	renderDichotomousKeyModal() {
		const { openModal, dichotomousKey, isMobile } = this.state;

		return (
			<Modal
				isOpen={openModal === "dichotomousKey"}
				onRequestClose={() => {
					this.setState({ openModal: null, moduleType: null });
				}}
				style={
					!isMobile
						? {
								content: {
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									padding: 0,
									background: "transparent",
									border: "none",
									backgroundColor: "white",
									borderRadius: 0,
									width: "80%",
									height: "70%",
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
						: {
								content: {
									position: "relative",
									padding: 0,
									width: "100vw",
									height: "100vh",
									inset: 0,
								},
								overlay: {
									zIndex: 999,
									backgroundColor: "rgba(0,0,0,0.5)",
								},
						  }
				}
			>
				<div className="ModalHeader">
					<div className="ModalHeaderTitle">
						<Icon name="back" onClick={() => this.getModalBackAction()} />
						<span>{Strings.sidebar.deficiencyIdentification}</span>
					</div>
					{!isMobile ? (
						<Button
							type="primary"
							className="button"
							style={{ margin: "0 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					) : (
						<></>
					)}
				</div>
				<div className="ModalSecondaryHeader">{this.getModalSecondaryHeader()}</div>
				<Row className="NutrientsContainer">
					{dichotomousKey?.children?.map((elem: any) => (
						<Col
							xs={20}
							md={10}
							className="NutrientsOption"
							style={{ height: "auto", minWidth: 350 }}
							onClick={() => this.setModalValue(elem)}
						>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
								<div style={{ color: "black" }}>{translate(elem.name)}</div>
								<div>{translate(elem.description)}</div>
							</div>
							{/* <Icon
								name="back"
								className="backButton"
								style={{ position: "initial" }}
							/> */}
						</Col>
					))}
				</Row>
				{isMobile ? (
					<div className="ModalFooter">
						<Button
							type="primary"
							className="button"
							style={{ margin: "5px 15px" }}
							onClick={() => this.restartDiagnosis()}
						>
							<span>{Strings.deficiencyIdentification.restartDiagnosis}</span>
						</Button>
					</div>
				) : (
					<></>
				)}
			</Modal>
		);
	}

	render() {
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.deficiencyIdentification}</title>
					<meta name="description" content="Description of DeficienciesIdentifying" />
				</Helmet>
				<div className="PageHeader">
					<div
						className="PageHeaderTitle"
						onClick={() => dispatch(push("/"))}
						style={{ cursor: "pointer" }}
					>
						<Icon name="back" style={{ fontWeight: "normal" }} />
						<div>{Strings.sidebar.deficiencyIdentification}</div>
					</div>
					<div
						className={`PageHeaderFiltersButton`}
						onClick={() => dispatch(push("/contact")) }
						style={{ padding: '5px 25px' }}
						>
						{Strings.sidebar.contact}
						<Icon className="LocationMarker" name="email" style={{ fontSize: 20 }} />
					</div>
				</div>
				<Row className="MainOptionsContainer">
					<Col xs={24} md={8} className="MainOption">
						<img
							alt={Strings.deficiencyIdentification.byCulture}
							src={culturesBackground}
							style={{ width: "50%" }}
						/>
						<img
							alt={Strings.deficiencyIdentification.byCulture}
							src={culturesBackground2}
							style={{ width: "50%", left: "50%" }}
						/>
						<div className="MainOptionContent">
							<div className="MainOptionHeader">
								<Icon name="plant-1" />
								<span style={{ margin: "45px 0px" }}>
									{Strings.deficiencyIdentification.byCulture.toUpperCase()}
								</span>
							</div>
							<Button
								type="primary"
								className="button"
								onClick={() =>
									this.setState({
										openModal: "cultures",
										moduleType: "byCulture",
									})
								}
							>
								<span>{Strings.deficiencyIdentification.startDiagnosis}</span>
							</Button>
						</div>
					</Col>
					<Col xs={24} md={8} className="MainOption">
						<img
							alt={Strings.deficiencyIdentification.byCulture}
							src={nutrientsBackground}
						/>
						<div className="MainOptionContent">
							<div className="MainOptionHeader">
								<Icon name="molecule" />
								<span style={{ margin: "45px 0px" }}>
									{Strings.deficiencyIdentification.byNutrient.toUpperCase()}
								</span>
							</div>
							<Button
								type="primary"
								className="button"
								onClick={() =>
									this.setState({
										openModal: "nutrients",
										moduleType: "byNutrient",
									})
								}
							>
								<span>{Strings.deficiencyIdentification.startDiagnosis}</span>
							</Button>
						</div>
					</Col>
					<Col xs={24} md={8} className="MainOption">
						<img
							alt={Strings.deficiencyIdentification.byCulture}
							src={dichotomousKeyBackground}
						/>
						<div className="MainOptionContent">
							<div className="MainOptionHeader">
								<Icon name="plant-1" />
								<span>
									{Strings.deficiencyIdentification.byDichotomousKey.toUpperCase()}
								</span>
							</div>
							<Button
								type="primary"
								className="button"
								onClick={async () => {
									this.getDichotomousKey();
									this.setState({
										openModal: "dichotomousKey",
										moduleType: "byDichotomousKey",
									});
								}}
							>
								<span>{Strings.deficiencyIdentification.startDiagnosis}</span>
							</Button>
						</div>
					</Col>
				</Row>
				{this.renderCultureModal()}
				{this.renderNutrientDetailModal()}
				{this.renderNutrientModal()}
				{this.renderDichotomousKeyModal()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language || "pt",
});

export default connect(mapStateToProps)(DeficienciesIdentifying);
