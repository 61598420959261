// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AnalysisTypeContent{min-height:calc(100vh - 312px);padding:25px}.ImageContainer{height:350px;width:350px;margin-top:25px;margin-left:25px}@media screen and (max-width: 968px){.ImageContainer{width:100%;height:300px;margin-left:0;margin-top:0}}@media screen and (max-width: 600px){.ImageContainer{height:100%;margin-left:0;margin-top:0}}@media screen and (max-width: 768px){.SubtitleMarginTop{margin-top:5px}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#7d878c",
	"lightPrimary": "#c7cacc",
	"secondaryColor": "#959fa3",
	"lightSecondary": "#eee",
	"tertiaryColor": "#039941",
	"lightTertiary": "#74bd92"
};
module.exports = exports;
